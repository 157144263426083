import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  TextField,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import DatePicker from "react-datepicker";
import useCommonBackground from "../hook/Commonhook.jsx";
import * as XLSX from "xlsx";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { getAllData } from "../redux/Userdetail";
import { useSelector, useDispatch } from "react-redux";
import Commontransition from "../commoncomponents/Commontransition";

const commonstyle = {
  container: {
    display: "flex",
    flexDirection: "column",
  },

  Datepickercontainer: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
    padding: "10px",
    justifyContent: "center",
    gap: 30,
  },

  Searchbutton: {
    marginLeft: "10px",
    height: "56px",
  },
  Exportbutton: {
    marginLeft: "10px",
    height: "56px",
  },
  Searchcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "60px",
  },
  Searchfield: {
    width: "70%",
    height: "45px",
    borderRadius: "15px",
    borderColor: "lightGray",
    paddingLeft: "20px",
  },
  DialogcontentText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: 30,
  },
  Excelbutton: {
    padding: "10px 40px",
    borderRadius: "10px",
  },
  Pdfbutton: {
    padding: "10px 50px",
    borderRadius: "10px",
  },
  Gridcontainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "150px",
    padding: "40px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
  },
};

const columns = [
  { id: "sno", label: "S.NO", minWidth: 50 },
  { id: "MemberId", label: "MemberId", minWidth: 150 },
  { id: "beneficiaryaccountnumber", label: "Beneficiary Acc No", minWidth: 150 },
  { id: "beneficiaryName", label: "Beneficiary Name", minWidth: 150 },
  { id: "beneficiaryIFSC", label: "Beneficiary IFSC", minWidth: 150 },
  { id: "remark", label: "Remark", minWidth: 150 },
  { id: "paymentDescription", label: "Description", minWidth: 200 },
  { id: "transactionType", label: "Mode", minWidth: 150 },
  { id: "transactionReferenceNo", label: "RRN No", minWidth: 150 },
  { id: "amount", label: "Amount", minWidth: 150 },
  { id: "charge", label: "SurCharge", minWidth: 150 },
  { id: "status", label: "Status", minWidth: 150 },
  { id: "created_at", label: "Date", minWidth: 200 },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Payoutreport() {
  useCommonBackground();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [originalRows, setOriginalRows] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");

  //new lind added
  const { users, loading, error } = useSelector((state) => state.Userdata);

  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
    rows: [],
  });

  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
  });

  const MemberId = localStorage.getItem("UserId") || "";
  const token = localStorage.getItem("authToken");

  // Utility function to format date as YYYY-MM-DD
  const formatDate = (date) => {
    if (!date) return null;
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  const fetchData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/wallet/transactions`;

    const params = new URLSearchParams({
      page: 1,
      perPage: 10,
      ...(filters.fromDate && { start_date: formatDate(filters.fromDate) }),
      ...(filters.toDate && { end_date: formatDate(filters.toDate) }),
    });

    try {
      const response = await fetch(`${url}?${params.toString()}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result = await response.json();

      if (result.data) {
        const filteredData = result.data
          .filter((item) => item.type === "payout") // Filter 'payout' type
          .map((item, index) => ({
            sno: index + 1,
            MemberId,
            beneficiaryaccountnumber: item.creditAccountNumber || "-",
            beneficiaryName: item.beneficiaryName || "-",
            beneficiaryIFSC: item.beneficiaryIFSC || "-",
            remark: item.paymentDescription || "-",
            paymentDescription:
              (item.status === "REFUNDED" || item.status === "SUCCESS" || item.status === "FAILED")
                ? (() => {
                  if (item.status === "FAILED") {
                    return `${item.errorMessage} / ${item.id}`; // For FAILED, show errorMessage and id
                  } else if (item.status === "REFUNDED") {
                    return `${item.paymentDescription} / ${item.transactionID}`; // For REFUNDED, show paymentDescription and transactionID
                  } else if (item.status === "SUCCESS") {
                    return `${item.errorMessage} / ${item.id}`; // For SUCCESS, show errorMessage and id
                  }
                  return "-"; // Default case if none of the conditions match
                })()
                : item.errorMessage || "-", // Otherwise, show errorMessage if available


            // paymentDescription:
            //   item.status === "REFUNDED"
            //     ? item.paymentDescription || "-" // Show errorMessage if status is REFUNDED
            //     : item.errorMessage || "-", // Otherwise, show paymentDescription

            transactionType: item.transactionType || "-",
            transactionReferenceNo: item.transactionReferenceNo || "-",
            amount: item.amount || "-",
            charge: item.surchargeAmount || "-",
            status: item.status || "-",
            created_at: new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true, // 24-hour format
            }).format(new Date(item.created_at)),
          }));

        setOriginalRows(filteredData);
        setState((prevState) => ({
          ...prevState,
          rows: filteredData,
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data initially (default data)
  }, []);

  const handleSearch = () => {
    fetchData(); // Fetch data with filters applied
  };

  const handleChangePage = (event, newPage) => {
    setState((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setState((prevState) => ({
      ...prevState,
      rowsPerPage: +event.target.value,
      page: 0,
    }));
  };

  const userData = {
    Name: localStorage.getItem("Name"),
    EmailId: localStorage.getItem("email"),
    Phonenumber: localStorage.getItem("Phone"),
    virtual_account: localStorage.getItem("virtual_account"),
  };

  console.log(state.rows, "rowsdata");

  //  Export to Excel

  const exportToExcel = () => {
    //   // Format the table data and start adding from the next row after headers
    const formattedRows = state.rows.map((row, index) => ({
      sno: index + 1,
      MemberId,
      beneficiaryaccountnumber: row?.beneficiaryaccountnumber || "-",
      beneficiaryName: row.beneficiaryName || "-",
      beneficiaryIFSC: row.beneficiaryIFSC || "-",
      remark: row.remark || "-",
      paymentDescription: row.paymentDescription || "-",
      transactionType: row.transactionType || "-",
      transactionReferenceNo: row.transactionReferenceNo || "-",
      amount: row.amount || "-",
      charge: row.charge || "-",
      status: row.status || "-",
      created_at: row.created_at || "-",
    }));

    const worksheet = XLSX.utils.json_to_sheet([], { skipHeader: true });

    const userDetails = [
      [`Name: ${userData.Name}`],
      [`Email: ${userData.EmailId}`],
      [`Phone: ${userData.Phonenumber}`],
      [`Virtual Account: ${userData.virtual_account}`],
      [`Status: ${users?.status || "null"}`],
      [`Bank Name: ${"IDBI Bank Ltd."}`],
      [`IFSC: ${"IBKL0002031"}`],
      [`Account Type: ${"Current Account"}`],
      [`Address: ${users?.address || "null"}`],
      [`AadharNumber: ${users?.aadharNumber || "null"}`],
      [`PanNumber: ${users?.panNumber || "null"}`],
      [`State: ${users?.state || "null"}`],
      [`ShopName: ${users?.shopName || "null"}`],
      [`ShopAddress: ${users?.shopAddress || "null"}`],
      [`GstNumber: ${users?.gstNumber || "null"}`],
      [`BusinessPanNo: ${users?.businessPanNo || "null"}`],
      [`LandlineNo: ${users?.landlineNumber || "null"}`],
      [`LandlineStdCode: ${users?.landlineSTDCode || "null"}`],
    ];

    XLSX.utils.sheet_add_aoa(worksheet, userDetails, { origin: "A3" });

    const headerRowIndex = userDetails.length + 5;

    const headers = [
      [
        "S.NO",
        "Member ID",
        "Ben Acc No",
        "Ben Name",
        "Ben IFSC",
        "Remark",
        "Description",
        "Mode",
        "RRN No",
        "Amount",
        "SurCharge",
        "Status",
        "Date",
      ],
    ];
    XLSX.utils.sheet_add_aoa(worksheet, headers, {
      origin: `A${headerRowIndex}`,
    });

    XLSX.utils.sheet_add_json(worksheet, formattedRows, {
      origin: `A${headerRowIndex + 1}`,
      skipHeader: true,
    });

    const headerRow = headerRowIndex - 1;
    headers[0].forEach((_, colIndex) => {
      const cellRef = XLSX.utils.encode_cell({ r: headerRow, c: colIndex });
      if (!worksheet[cellRef]) return;
      worksheet[cellRef].s = {
        alignment: { horizontal: "center", vertical: "center" },
        font: { bold: true },
      };
    });
    //   // Set column widths
    worksheet["!cols"] = [
      { wch: 5 }, // S.No  //1
      { wch: 15 }, // Member ID  //2
      { wch: 40 }, // VirtualAccount // 3
      { wch: 40 }, // TxnId //4
      { wch: 30 }, // Description //5
      { wch: 50 }, // Date //6
      { wch: 70 }, // Date //7
      { wch: 30 },
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

    XLSX.writeFile(workbook, "Payout Report.xlsx");
    handleClose();
  };

  console.log(state.rows, "rowsdata");

  // Export to pdf
  const generatePDF = () => {
    const MyDocument = (
      <Document>
        <Page size={[950, 950]}>
          <View style={styles.container}>
            <Text style={styles.header}>Payout Report</Text>
            <Text>Name: {userData.Name}</Text>
            <Text>Email: {userData.EmailId}</Text>
            <Text>Phone: {userData.Phonenumber}</Text>
            <Text>Virtual Account: {userData.virtual_account}</Text>
            <Text>Status: {users?.status || "null"}</Text>
            <Text>Bank Name: {"IDBI Bank Ltd."}</Text>
            <Text>IFSC: {"IBKL0002031"}</Text>
            <Text>Account Type: {"Current Account"}</Text>
            <Text>Address: {users?.address || "null"}</Text>
            <Text>AadharNumber: {users?.aadharNumber || "null"}</Text>
            <Text>PanNumber: {users?.panNumber || "null"}</Text>
            <Text>State: {users?.state || "null"}</Text>
            <Text>ShopName: {users?.shopName || "null"}</Text>
            <Text>ShopAddress: {users?.shopAddress || "null"}</Text>
            <Text>GstNumber: {users?.gstNumber || "null"}</Text>
            <Text>BusinessPanNo: {users?.businessPanNo || "null"}</Text>
            <Text>LandlineNo: {users?.landlineNumber || "null"}</Text>
            <Text>LandlineStdCode: {users?.landlineSTDCode || "null"}</Text>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>S.No</Text>
                <Text style={styles.tableCell}>Member ID</Text>
                <Text style={styles.tableCell}>Ben Acc No</Text>
                <Text style={styles.tableCell}>Ben Name</Text>
                <Text style={styles.tableCell}>Ben IFSC</Text>
                <Text style={styles.tableCell}>Remark</Text>
                <Text style={styles.tableCell}>Description</Text>
                <Text style={styles.tableCell}>Mode</Text>
                <Text style={styles.tableCell}>RRN No</Text>
                <Text style={styles.tableCell}>Amount</Text>
                <Text style={styles.tableCell}>SurCharge</Text>
                <Text style={styles.tableCell}>Status</Text>
                <Text style={styles.tableCell}>Date</Text>
              </View>
              {state.rows.map((row, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.narrationCell}>{row.sno}</Text>
                  <Text style={styles.narrationCell}>{row.MemberId}</Text>
                  <Text style={styles.narrationCell}>
                    {row.beneficiaryaccountnumber}
                  </Text>
                  <Text style={styles.narrationCell}>
                    {row.beneficiaryName}
                  </Text>
                  <Text style={styles.narrationCell}>
                    {row.beneficiaryIFSC}
                  </Text>
                  <Text style={styles.narrationCell}>
                    {row.remark}
                  </Text>
                  <Text style={styles.narrationCell}>
                    {row.paymentDescription}
                  </Text>
                  <Text style={styles.narrationCell}>
                    {row.transactionType}
                  </Text>
                  <Text style={styles.narrationCell}>
                    {row.transactionReferenceNo}
                  </Text>
                  <Text style={styles.narrationCell}>{row.amount}</Text>
                  <Text style={styles.narrationCell}>{row.charge}</Text>
                  <Text style={styles.narrationCell}>{row.status}</Text>
                  <Text style={styles.narrationCell}>{row.created_at}</Text>
                </View>
              ))}
            </View>
          </View>
        </Page>
      </Document>
    );

    pdf(MyDocument)
      .toBlob()
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "TransactionsReport.pdf";
        link.click();
      });
    handleClose();
  };

  // Global function to export both Excel and PDF

  const handleExportClick = () => {
    setOpen(true);
  };

  console.log(originalRows, "originalrowsData");
  useEffect(() => {
    let filteredData = [...originalRows];

    if (searchQuery) {
      filteredData = filteredData
        .filter(
          (row) =>
            row.beneficiaryaccountnumber
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            row.beneficiaryName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            row.beneficiaryIFSC
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            row.paymentDescription
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            row.transactionType
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            row.transactionReferenceNo
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            row.status.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          // Prioritize matching rows
          if (
            a.beneficiaryaccountnumber
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            a.beneficiaryName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            a.beneficiaryIFSC
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            a.paymentDescription
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            a.transactionType
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            a.transactionReferenceNo
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            a.status.toLowerCase().includes(searchQuery.toLowerCase())
          )
            return -1;
          if (
            b.beneficiaryaccountnumber
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            b.beneficiaryName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            b.beneficiaryIFSC
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            b.paymentDescription
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            b.transactionType
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            b.transactionReferenceNo
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            b.status.toLowerCase().includes(searchQuery.toLowerCase())
          )
            return 1;
          return 0; // Maintain original order for non-matching rows
        });
    }

    console.log(filteredData, "filteredDataresponsefromserver");

    // Reverse the filtered data
    filteredData = filteredData.reverse();

    console.log(filteredData, "filtererdatatreverse");
    // Update the state with the filtered data
    setState((prevstate) => ({
      ...prevstate,
      rows: filteredData.reverse(),
    }));
  }, [searchQuery, originalRows]); // Run whenever searchQuery or originalRows changes

  return (
    <div style={commonstyle.container}>
      <Commontransition text={"Payout Report"} transition={"P/R"} />
      <div style={commonstyle.Datepickercontainer}>
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          selected={filters.fromDate}
          onChange={(date) =>
            setFilters((prev) => ({ ...prev, fromDate: date }))
          }
          placeholderText="From date"
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          selected={filters.toDate}
          onChange={(date) => setFilters((prev) => ({ ...prev, toDate: date }))}
          placeholderText="To date"
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          onClick={handleSearch}
          variant="contained"
          color="primary"
          style={commonstyle.Searchbutton}
        >
          Search
        </Button>

        {/*   Export button to donload table  data in excel sheet  */}
        <Button
          onClick={handleExportClick}
          variant="contained"
          color="primary"
          style={commonstyle.Exportbutton}
        >
          EXPORT
        </Button>
      </div>

      <div style={commonstyle.Searchcontainer}>
        <input
          type="text"
          style={commonstyle.Searchfield}
          placeholder="Search"
          value={searchQuery} // Bind the search query to the input value
          onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
        />
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"How to Download Payout History?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={commonstyle.DialogcontentText}
          >
            <Button
              style={commonstyle.Excelbutton}
              onClick={() => exportToExcel()}
              variant="contained"
            >
              EXCEL
            </Button>
            <Button
              style={commonstyle.Pdfbutton}
              onClick={() => generatePDF()}
              variant="contained"
            >
              PDF
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Grid container sx={commonstyle.Gridcontainer}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="left"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {state.rows
                  .slice(
                    state.page * state.rowsPerPage,
                    state.page * state.rowsPerPage + state.rowsPerPage
                  )
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="left">
                          {row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={state.rows.length}
            rowsPerPage={state.rowsPerPage}
            page={state.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  table: {
    marginTop: 20,
  },
  tableRow: {
    flexDirection: "row",
    marginBottom: 5,
  },
  tableCell: {
    marginRight: 10,
    fontSize: 10,
    border: "1px solid black",
    padding: 8, // Increased padding to give more space
    flex: 1,
    maxWidth: 100, // Set max width to control overflow
    overflow: "hidden",
    textOverflow: "ellipsis", // Show ellipsis if text overflows
    wordBreak: "break-word", // Break words to the next line
  },
  narrationCell: {
    marginRight: 3,
    fontSize: 10,
    border: "1px solid black",
    padding: 10, // Increased padding for more space
    flex: 4, // Adjust this if you want to allocate more space to these cells
    maxWidth: 120, // Set max width to control overflow
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word", // Break long text onto the next line
    flexWrap: "wrap", // Allow wrapping to the next line if necessary
  },
});

export default Payoutreport;
