import React, { useState, useEffect } from 'react';
import Commontransition from "../../commoncomponents/Commontransition.jsx";
import useCommonBackground from "../../hook/Commonhook.jsx";
import DatePicker from "react-datepicker";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, TextField } from "@mui/material";



const commonstyle = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  Datepickercontainer: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
    padding: "10px",
    justifyContent: "center",
    gap: 30,
  },
  Searchbutton: {
    marginLeft: "10px",
    height: "56px",
  },
  Exportbutton: {
    marginLeft: "10px",
    height: "56px",
  },
  Searchcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "60px",
  },
  Searchfield: {
    width: "70%",
    height: "45px",
    borderRadius: "15px",
    borderColor: "lightGray",
    paddingLeft: "20px",
  },
  DialogcontentText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: 30,
  },
  Excelbutton: {
    padding: "10px 40px",
    borderRadius: "10px",
  },
  Pdfbutton: {
    padding: "10px 50px",
    borderRadius: "10px",
  },
  Gridcontainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "210px",
    padding: "40px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
  },
};

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 50 },
  { id: 'memberId', label: 'MemberId', minWidth: 100 },
  { id: 'virtualAccountNo', label: 'Virtual Account No', minWidth: 170 },
  { id: 'txnId', label: 'Txn Id', minWidth: 170 },
  { id: 'description', label: 'Description', minWidth: 170 },
  { id: 'amount', label: 'Amount', minWidth: 100, align: 'right' },
  { id: 'date', label: 'Date', minWidth: 170, align: 'right' },
];

function Virtualaccountreport() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [originalRows, setOriginalRows] = useState([]);

  const [searchQuery, setSearchQuery] = React.useState("");

  useCommonBackground();

  const fetchData = async (startDate, endDate, page, rowsPerPage) => {
    try {
      const token = localStorage.getItem("adminAuthToken");
      let url = `https://zevopay.online/api/v1/admin/transaction?page=${page + 1}&perPage=${rowsPerPage}`;

      // Append date range parameters if they exist
      if (startDate && endDate) {
        url += `&start_date=${startDate}&end_date=${endDate}`;
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      // Filter the data to only include transactions with specific modes
      const filteredData = data.data.filter(item =>
        ['CASH CREDIT', 'CASH DEBIT', 'IMPS', 'NEFT', 'RTGS'].includes(item.mode)
      );

      const totalFilteredPages = filteredData.length;

      // Map the filtered data to rows
      const serverRows = filteredData.map((item, index) => ({
        sno: page * rowsPerPage + index + 1,
        memberId: item.user.userId,
        virtualAccountNo: item.van,
        txnId: item.utr,
        description: item.Sender_receiver_info,
        amount: item.tranAmt,
        date: new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // 24-hour format
        }).format(new Date(item.created_at))
      }));
      setOriginalRows(serverRows);
      setRows(serverRows); // Store only the filtered rows
      setTotalPages(totalFilteredPages); // Update total count from the filtered data
      console.log(filteredData.length, "shyam jha");
      console.log(totalPages, "totalpages")
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchData(startDate, endDate, page, rowsPerPage);
  }, [startDate, endDate, page, rowsPerPage]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleSearch = () => {
    setPage(0); // Reset to the first page
    fetchData(startDate, endDate, 0, rowsPerPage);
  };


  useEffect(() => {
    let filteredData = [...originalRows];

    if (searchQuery) {
      filteredData = filteredData
        .filter(
          (row) =>
            row.memberId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.txnId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.description.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          // Prioritize matching rows
          if (
            a.memberId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            a.txnId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            a.description.toLowerCase().includes(searchQuery.toLowerCase())
          )
            return -1;
          if (
            
            b.memberId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            b.txnId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            b.description.toLowerCase().includes(searchQuery.toLowerCase())
          )
            return 1;
          return 0; // Maintain original order for non-matching rows
        });
    }

    // Reverse the filtered data
    filteredData = filteredData.reverse();

    setRows(filteredData.reverse());

    // Update the state with the filtered data
    // setState((prevstate) => ({
    //   ...prevstate,
    //   rows: filteredData,
    // }));
  }, [searchQuery, originalRows]); // Run whenever searchQuery or originalRows changes

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Commontransition text={"Virtual Account Report"} transition={"V/C"} onClick={() => window.location.reload()} />
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          alignItems: "center",
          padding: "10px",
          justifyContent: "center",
          gap: 30,
        }}
      >
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="From date"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="To date"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: "10px",
            height: "56px",
            backgroundColor: "red",
            borderRadius: "10px",
          }}
          onClick={handleSearch}
        >
          Search
        </Button>
      </div>

      <div style={commonstyle.Searchcontainer}>
        <input
          type="text"
          style={commonstyle.Searchfield}
          placeholder="Search"
          value={searchQuery} // Bind the search query to the input value
          onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
        />
      </div>

      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "200px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>

            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 1000, 5000, 10000]}
            component="div"
            count={totalPages} // Total records from API
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        </Paper>
      </Grid>
    </div>
  );
}

export default Virtualaccountreport;
