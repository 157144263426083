import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import axios from "axios";
import Imageone from "../assets/imagecarouselunique.png";
import Imagetwo from "../assets/imagecaouseltwo.png";
import Imagethree from "../assets/imagecarouselthree.png";
import "swiper/css";
import "swiper/css/navigation";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

function Resetmpin() {
  const [token, setToken] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get("token");
    setToken(tokenParam);
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    if (!token) {
      alert("Token is missing! Please use a valid reset link.");
      setSubmitting(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/reset-mpin?role=${values.role}`,
        {
          token: token,
          mpin: values.mpin,
        }
      );

      alert(response.data.message || "Mpin has been reset successfully!");
    } catch (error) {
      alert(
        error.response?.data?.message ||
          "An error occurred. Please try again later."
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={4}
          md={7}
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Swiper
            spaceBetween={10}
            centeredSlides
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            navigation={false}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                style={{ width: "60%", height: "auto" }}
                src={Imageone}
                alt="ImageOne"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                style={{ width: "60%", height: "auto" }}
                src={Imagetwo}
                alt="ImageTwo"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                style={{ width: "60%", height: "auto" }}
                src={Imagethree}
                alt="ImageThree"
              />
            </SwiperSlide>
          </Swiper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: "#f0f2f5",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              backgroundColor: "white",
              padding: "4rem",
              maxWidth: "400px",
              width: "100%",
            }}
            sx={{
              my: 4,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Mpin
            </Typography>

            <Formik
              initialValues={{ mpin: "", role: "user" }}
              validationSchema={Yup.object({
                mpin: Yup.string()
                  .min(4, "Mpin must be at least 4 characters")
                  .required("New Mpin is required"),
                role: Yup.string().required("Role is required"),
              })}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form noValidate sx={{ mt: 1 }}>
                  <Field
                    as={TextField}
                    margin="normal"
                    required
                    fullWidth
                    id="mpin"
                    label="New Mpin"
                    name="mpin"
                    type="mpin"
                    autoComplete="new-mpin"
                    autoFocus
                    error={touched.mpin && Boolean(errors.mpin)}
                    helperText={touched.mpin && errors.mpin}
                  />
                  <Field
                    as={TextField}
                    select
                    margin="normal"
                    required
                    fullWidth
                    id="role"
                    name="role"
                    label="Select Role"
                    error={touched.role && Boolean(errors.role)}
                    helperText={touched.role && errors.role}
                  >
                    <MenuItem value="user">User</MenuItem>
                    <MenuItem value="subadmin">SA</MenuItem>
                  </Field>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ padding: "10px" }}
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Reset MPIN"}
                  </Button>
                </Form>
              )}
            </Formik>

            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Resetmpin;
