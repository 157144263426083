import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import Button from "@mui/material/Button";
import Commontransition from "../commoncomponents/Commontransition";

function Changemobile() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#F8F8FB";

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Revert back to default background color
    };
  }, []);

  const validateMobileNumber = (number) => {
    const mobileRegex = /^[0-9]{10}$/; // Adjust this regex for your specific validation needs
    if (!mobileRegex.test(number)) {
      setError("Please enter a valid 10-digit mobile number");
      return false;
    }
    setError("");
    return true;
  };

  const handleGetOTP = () => {
    if (validateMobileNumber(mobileNumber)) {
      // Perform OTP action if the mobile number is valid
      console.log("Mobile number is valid. Proceed to get OTP.");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Commontransition
        text={"Update Mobile Number Panel"}
        transition={"Update Mobile"}
        onClick={() => window.location.reload()}
      />

      <Grid
        container
        sx={{
          marginTop: "100px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
        }}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
        //   style={{ paddingLeft: "100px", paddingRight: "100px" }}
        >
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "15px", color: "black", fontSize: "22px" }}>
              Current Mobile Number
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              name="mobileNumber"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              style={{
                padding: "30px",
                width: "100%",
                height: "47px",
                paddingRight:"70px",
                borderColor: error ? "red" : "lightgray",
                // paddingLeft: "20px",
              }}
            />
            {error && <span style={{ color: "red" }}>{error}</span>}
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            marginTop: "30px",
            gap: "20px",
            paddingLeft: "110px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleGetOTP}
            style={{
              backgroundColor: "#088405",
              color: "#fff",
              width:"100px",
              padding: "15px",
              fontWeight:"bold",
              borderRadius: "30px",
            }}
          >
            GET OTP
          </Button>
          <Button
            variant="contained"
            onClick={() => setMobileNumber("")} // Reset mobile number
            style={{
              backgroundColor: "#DE2A2A",
              color: "#fff",
              fontWeight:"bold",
              width:"100px",
              padding: "15px",
              borderRadius: "30px",
            }}
          >
            RESET
          </Button>
        </div>
      </Grid>
    </div>
  );
}

export default Changemobile;
