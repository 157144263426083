// import React from "react";

// function Commonbutton({buttontext, onClick}) {
//   return (
//     <>
//       <button onClick={()=> onClick()} style={{backgroundColor:"#F7CAC9", padding:"10px",borderColor:"transparent", color:"lightslategrey", fontFamily:'sans-serif', fontWeight:"bold"}}>{ buttontext ?  buttontext :   "Add Money"}</button>
//     </>
//   );
// }

// export default Commonbutton;



import React from "react";

function Commonbutton({ buttontext, onClick }) {
  return (
    <>
      <button
        onClick={() => onClick()}
        style={{
          backgroundColor: "#4CAF50", // Soft green for a fresh look
          padding: "16px 25px", // More padding for a better look
          border: "none", // No border
          borderRadius: "30px", // Rounded corners for a smooth, modern feel
          color: "#fff", // White text for better contrast
          fontFamily: "Arial, sans-serif", // Clean, modern font
          fontWeight: "600", // Slightly bold font
          fontSize: "16px", // Medium font size for better readability
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow to add depth
          cursor: "pointer", // Pointer cursor on hover
          transition: "all 0.3s ease-in-out", // Smooth transition for hover effects
        }}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#45a049"; // Darker green on hover
          e.target.style.transform = "scale(1.05)"; // Slightly enlarge the button on hover
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "#4CAF50"; // Revert to original color
          e.target.style.transform = "scale(1)"; // Reset size after hover
        }}
      >
        {buttontext ? buttontext : "Add Money"}
      </button>
    </>
  );
}

export default Commonbutton;
