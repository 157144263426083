import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"; // Import the AccountBalanceWalletIcon
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Logo from "../admindashboard/adminassets/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Adminmember from "../adminpages/Adminmember";
import Adminhome from "../adminpages/Adminhome";
import Adminwebsite from "../adminpages/Adminwebsite";
import Adminwallet from "../adminpages/Adminwallet";
import Ewallet from "../adminsidemenupages/Ewallet";
import Creditfund from "../adminsidemenupages/Creditfund";
import Debitfund from "../adminsidemenupages/Debitfund";
import Memberlist from "../adminsidemenupages/Memberlist";
import Newregistration from "../adminsidemenupages/Newregistration";
import Reportscreen from "../adminsidemenupages/Reportscreen";
import UserQrupload from "../adminsidemenupages/UserQrupload";
import Profilephotouserupload from "../adminsidemenupages/Profilephotouserupload";
import Payoutsurcharge from "../adminsidemenupages/Payoutsurcharge";
import Surpluscollection from "../adminsidemenupages/Surpluscollection";
import SettingsIcon from '@mui/icons-material/Settings';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// style of Logo  background   contain er

const styles = {
  circle: {
    width: "70px", // Set the width of the circle
    height: "70px", // Set the height of the circle
    backgroundColor: "#007BB5", // Dark sky color
    borderRadius: "28%", // Make the div circular
    display: "flex", // Center content horizontally
    justifyContent: "center",
    alignItems: "center", // Center content vertically
    color: "white", // Set the text color inside the circle (if needed)
    fontSize: "18px", // Adjust the text size (if needed)
  },
};
function AdminDashboard() {
  const [expanded, setExpanded] = useState(false);
  const [selectedtab, setSelectedtab] = useState(0);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  // const [openwallet, setOpenwallet] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenwallet, setDropdownOpenwallet] = useState(false);
  const [dropdownsetting, setDropdownsetting] = useState(false);

  const navigate = useNavigate();
  const openicon = Boolean(anchorEl);


  useEffect(() => {
    // Disable right-click context menu
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    // Disable specific keyboard shortcuts
    const disableShortcuts = (e) => {
      if (
        e.key === "F12" || // F12 - Developer tools
        (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I - Developer tools
        (e.ctrlKey && e.shiftKey && e.key === "J") || // Ctrl+Shift+J - Console
        (e.ctrlKey && e.key === "U") // Ctrl+U - View Source
      ) {
        e.preventDefault();
      }
    };

    // Add event listeners
    document.addEventListener("contextmenu", disableRightClick); // Disable right-click
    document.addEventListener("keydown", disableShortcuts); // Disable specific shortcuts

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableShortcuts);
    };
  }, []);

  // call baalnce api

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // menu user icon
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //logout button

  const handleClose = () => {
    // navigate("/");
    setAnchorEl(null);
  };

  // menu button

  // logout button
  const handleLogout = () => {
    localStorage.removeItem("adminAuthToken"); // Clear the token from local storage
    navigate("/"); // Redirect to login page
    setAnchorEl(null); // Close the menu
  };

  const ExpandIcon = styled(ExpandMoreIcon)(({ theme, expanded }) => ({
    transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
    transition: "transform 0.3s",
  }));

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const toggleDropdownwallet = () => {
    setDropdownOpenwallet((prev) => !prev);
  };
  
  const toggleDropdownsetting = () => {
    setDropdownsetting((prev) => !prev);
  };


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="fixed" open={open} style={{ backgroundColor: "#fff" }}>
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              // marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon style={{ color: "black" }} />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "9px",
              textAlign: "center",
              marginLeft: "auto",
            }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
              </IconButton>
            </Tooltip>
          </Box>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openicon}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem onClick={handleClose}>
              <Avatar /> Profile
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Avatar /> My account
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Add another account
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70px",
            }}
          >
            <img src={Logo} style={{ width: "70%" }} alt="company logo" />
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        {open && (
          <>
            <List style={{ backgroundColor: "white" }}>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 5,
                  }}
                >
                  <div style={styles.circle}>
                    <img
                      src={Logo}
                      style={{
                        width: "57px",
                        height: "57px",
                        borderRadius: "70%",
                        backgroundColor: "#fff",
                        //   objectFit: "cover",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "4px",
                      }}
                    >
                      <span style={{ fontSize: "12px", color: "gray" }}>
                        ZEVOSOFT SERVICES
                      </span>
                      <span style={{ fontSize: "12px", color: "gray" }}>
                        PRIVATE LIMITED
                      </span>
                    </div>
                  </div>
                </div>
              </ListItem>
            </List>
            <Divider />
          </>
        )}

        <List style={{ backgroundColor: "white", height: "100vh" }}>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "lightgoldenrodyellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              onClick={() => setSelectedtab(0)}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <HomeIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Home"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "lightgoldenrodyellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownOpen}
              onClick={() => {
                // setSelectedtab(1);
                toggleDropdown();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PersonIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Members"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownOpen && (
              <ul
                className="dropdown-menu show"
                style={{ display: "block", width: "100%" }}
              >
                <li>
                  <span
                    onClick={() => setSelectedtab(7)}
                    style={{ fontSize: "15px", color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Members List
                  </span>
                </li>
                <li>
                  <span
                    style={{ fontSize: "15px" ,  color:"black"}}
                    className="dropdown-item"
                    href="#"
                  >
                    AEPS KYC
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => setSelectedtab(8)}
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    New Registration
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => setSelectedtab(10)}
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    UserQrupload
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => setSelectedtab(11)}
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Userprofileupload
                  </span>
                </li>
                <li>
                  <span
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Front Reg List
                  </span>
                </li>
                <li>
                  <span
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Upgrade Members
                  </span>
                </li>
                <li>
                  <span
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Move Member
                  </span>
                </li>
                <li>
                  <span
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    SendFlahMessage
                  </span>
                </li>
                <li>
                  <span
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Contact Reg Request
                  </span>
                </li>
              </ul>
            )}
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "lightgoldenrodyellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              onClick={() => setSelectedtab(2)}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LanguageIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Website"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "lightgoldenrodyellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              onClick={() => {
                // setSelectedtab(1);
                toggleDropdownwallet();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AccountBalanceWalletIcon
                  sx={{ fontSize: 25, color: "#AAADB7" }}
                />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"wallet"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownOpenwallet && (
              <ul
                className="dropdown-menu show"
                style={{ display: "block", width: "100%" }}
              >
                <li>
                  <span
                    onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    E Wallet
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => setSelectedtab(5)}
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Credit Fund
                  </span>
                </li>
                <li>
                  <span
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    AEPS Wallet
                  </span>
                </li>
                <li>
                  <span
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Fund Request
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => setSelectedtab(6)}
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Debit Fund
                  </span>
                </li>
                <li>
                  <span
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Credit Report
                  </span>
                </li>
                <li>
                  <span
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Wallet Transfer Report
                  </span>
                </li>
              </ul>
            )}
          </ListItem>

          <ListItem
            onClick={() => setSelectedtab(9)}
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "lightgoldenrodyellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              // onClick={() => setSelectedtab(3)}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AssessmentOutlinedIcon
                  sx={{ fontSize: 25, color: "#AAADB7" }}
                />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Reports"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "lightgoldenrodyellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              onClick={() => {
                // setSelectedtab(1);
                toggleDropdownsetting();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <SettingsIcon
                  sx={{ fontSize: 25, color: "#AAADB7" }}
                />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Setting"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownsetting && (
              <ul
                className="dropdown-menu show"
                style={{ display: "block", width: "100%" }}
              >
                <li>
                  <span
                    onClick={() => setSelectedtab(12)}
                    style={{ fontSize: "15px",  color:"black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Surplus payout
                  </span>
                </li>
                
                <li>
                <span
                  onClick={() => setSelectedtab(13)}
                  style={{ fontSize: "15px",  color:"black" }}
                  className="dropdown-item"
                  href="#"
                >
                  Surplus Collection
                </span>
              </li>
              </ul>
            )}
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {selectedtab === 0 && <Adminhome />}
        {selectedtab === 1 && <Adminmember />}
        {selectedtab === 2 && <Adminwebsite />}
        {selectedtab === 3 && <Adminwallet />}
        {selectedtab === 4 && <Ewallet />}
        {selectedtab === 5 && <Creditfund />}
        {selectedtab === 6 && <Debitfund />}
        {selectedtab === 7 && <Memberlist />}
        {selectedtab === 8 && <Newregistration />}
        {selectedtab === 9 && <Reportscreen />}
        {selectedtab === 10 && <UserQrupload />}
        {selectedtab === 11 && <Profilephotouserupload />}
        {selectedtab === 12 && <Payoutsurcharge />}
        {selectedtab === 13 && <Surpluscollection />}
        {/* Add more tab content components as needed */}
      </Box>
    </Box>
  );
}

export default AdminDashboard;
