import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";

const ResponsiveCards = () => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <Grid container spacing={4}>
      {/* First Card */}
      <Grid item xs={12} sm={6}>
        <Card>
          <Typography
            variant="h5"
            component="div"
            style={{
              color: "#495057",
              fontWeight: "500",
              paddingLeft: "15px",
              fontWeight: "bold",
            }}
          >
            AEPS Transaction List
          </Typography>
       
            <CardContent
              style={{
                display: "flex",
                borderTopColor: "gray",
                marginTop: "20px",
                marginLeft: "20px",
                marginRightL: "20px",
                flexDirection: "column",
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
                width: "85%",
                padding:"0px, 30px",
                height: "80px", 
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.7)",
              }}
            >
              <h5 style={{ color: "#2A3036", fontWeight: "600" }}>
                Registration
              </h5>
              <h5 style={{ color: "#6D7277" }}>18/05/2023 20:11:02</h5>
            </CardContent>
          
         
        </Card>
      </Grid>

      {/* Second Card */}
      <Grid item xs={12} sm={6}>
        <Card>
          <Typography
            variant="h5"
            component="div"
            style={{
              color: "#495057",
              fontWeight: "500",
              paddingLeft: "15px",
              fontWeight: "bold",
            }}
          >
            E-Wallet Transaction List
          </Typography>
         
          <CardContent
            style={{
              display: "flex",
              borderTopColor: "gray",
              marginTop: "20px",
              marginLeft: "20px",
            //   marginRight: "20px",
              flexDirection: "column",
              borderBottomRightRadius: "5px",
              borderTopRightRadius: "5px",
              width: "85%",
              padding:'0px, 10px',
            //   height: "78px", 
            //   height: "85px",
              padding:"0px, 30px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.7)",
            }}
          >
            <h5 style={{ color: "#2A3036", fontWeight: "600" }}>
              Xpress DMT Topup Txn:- 20240918120427622
            </h5>
            <h5 style={{ color: "#6D7277" }}>18/09/2024 17:34:27</h5>
          </CardContent>
    
        
        
         
          <CardContent
            style={{
                display: "flex",
                borderTopColor: "gray",
                marginTop: "20px",
                marginLeft: "20px",
                marginRightL: "20px",
                flexDirection: "column",
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
                width: "85%",
                // height: "80px", 
              //   height: "85px",
                padding:"0px, 30px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.7)",
            }}
          >
            <h5 style={{ color: "#2A3036", fontWeight: "600" }}>
              Xpress DMT Topup Txn:- 20240918073736975
            </h5>
            <h5 style={{ color: "#6D7277" }}>18/09/2024 13:07:37</h5>
          </CardContent>
         
      
         
          <CardContent
            style={{
                display: "flex",
                borderTopColor: "gray",
                marginTop: "20px",
                marginLeft: "20px",
                marginRightL: "20px",
                flexDirection: "column",
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
                width: "85%",
                // height: "80px", 
              //   height: "85px",
                padding:"0px, 30px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.7)",
            }}
          >
            <h5 style={{ color: "#2A3036", fontWeight: "600" }}>
              Xpress DMT Topup Txn:- 20240918072827778
            </h5>
            <h5 style={{ color: "#6D7277" }}>18/09/2024 12:58:27</h5>
          </CardContent>
        
         
          <CardContent
            style={{
                display: "flex",
                borderTopColor: "gray",
                marginTop: "20px",
                marginLeft: "20px",
                marginRightL: "20px",
                flexDirection: "column",
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
                width: "85%",
                // height: "80px", 
              //   height: "85px",
                padding:"0px, 30px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.7)",
            }}
          >
            <h5 style={{ color: "#2A3036", fontWeight: "600" }}>
              Xpress DMT Topup Txn:- 20240918072251340
            </h5>
            <h5 style={{ color: "#6D7277" }}>18/09/2024 12:52:51</h5>
          </CardContent>
          
         
          <h1>{"    "}</h1>
          <h1>{"    "}</h1>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ResponsiveCards;
