import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import "./Navbar.css"; // Import the CSS file
import Menubar from "./Menubar";

function Navbar() {
  return (
    <div style={{ display: "flex", flexDirection: "column" ,}}>
      <nav className="navbar">
        <div className="container-fluid" style={{ display:'flex',justifyContent:"space-around"}}>
          <div className="contact-info" style={{display:"flex", gap:"20px"}}>
            <div style={{display:'flex', justifyContent:"space-between"}}>
              <FontAwesomeIcon
                icon={faPhoneVolume}
                style={{ color: "white",  }}
              />
              <span style={{ color: "white", fontWeight: "bold"}}>
                + (91) 8619981901
              </span>
            </div>
            <div style={{display:'flex', justifyContent:"space-between"}}>
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ color: "white",  }}
              />
              <span style={{ color: "white", fontWeight: "bold" }}>
                zevosoft@gmail.com
              </span>
            </div>
            <div style={{display:'flex', justifyContent:"space-between"}}>
              <FontAwesomeIcon
                icon={faLocationDot}
                style={{ color: "white", }}
              />
              <span style={{ color: "white", fontWeight: "bold" }}>Jodhpur</span>
            </div>
          </div>
          <div className="social-icons" style={{display:'flex', gap:"20px"}}>
            <div className="social-icon-container">
              <FontAwesomeIcon
                icon={faFacebookF}
                style={{ color: "#334141" }}
              />
            </div>
            <div className="social-icon-container">
              <FontAwesomeIcon
                icon={faInstagram}  
                style={{ color: "#334141" }}
              />
            </div>
            <div className="social-icon-container">
              <FontAwesomeIcon
                icon={faLinkedinIn}
                style={{ color: "#334141" }}
              />
            </div>
            <div className="social-icon-container">
              <FontAwesomeIcon icon={faWhatsapp} style={{ color: "#334141" }} />
            </div>
          </div>
        </div>
      </nav>
      <div style={{marginTop:"10px"}}>
      <Menubar />
      </div>
    </div>
  );
}

export default Navbar;
