import React, { useState } from 'react';
import useCommonBackground from '../../hook/Commonhook';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';

const style = {
    container: {
        display: "flex",
        flexDirection: "column"
    },
    childrenContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        width: "100%",
        padding: "25px",
        marginTop: "20px"
    },
    Imagecontainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    Buttoncontainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "20vh"
    },
    Fieldcontainer: {
        display: 'flex',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: "40vh"
    }
};

function Qrnewotpvalidate() {
    const token = localStorage.getItem("adminAuthToken");
    useCommonBackground();

    const [otp, setOtp] = useState(""); // State for OTP input
    const [isLoading, setIsLoading] = useState(false); // Loading state for button disable
    const [message, setMessage] = useState(""); // State to show success or failure message

    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    };

    const handleVerifyOtp = async () => {
        if (otp.trim() === "") {
            alert("Please enter the OTP.");
            return;
        }

        setIsLoading(true); // Disable the button while loading

        const payload = {
            twoFactorAuthenticationCode: otp
        };

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Add Content-Type header
            }
        };

        const timeout = new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Timeout')), 2000) // Timeout after 2 seconds
        );

        const apiCall = axios.post('https://zevopay.online/api/v1/auth/2fa/authenticate', payload, config);

        // Race between API call and timeout
        try {
            const response = await Promise.race([apiCall, timeout]);

            if (response.data.email) {
                setMessage("OTP verified successfully!");
                alert("Successful OTP verification.");
                setOtp(""); // Clear OTP after success
            } else {
                // setMessage("OTP verification failed.");
                // alert("Failed to verify OTP.");
                setOtp(""); // Clear OTP on failure
            }
        } catch (error) {
            if (error.message === 'Timeout') {
                alert("Failed to verify OTP: Request timed out.");
                setOtp(""); // Clear OTP after success
            } else {
                console.error("Error verifying OTP:", error);
                // setMessage("OTP verification failed.");
                // alert("Failed to verify OTP.");
            }
        } finally {
            setIsLoading(false); // Enable the button after response
        }
    };

    return (
        <div>
            <div style={style.childrenContainer}>
                <h3 style={{ color: "black" }}>{"Qr Otp validate"}</h3>
            </div>
            <div style={style.Fieldcontainer}>
                <TextField
                    id="otp-input"
                    label="Otp validate"
                    value={otp}
                    onChange={handleOtpChange}
                    multiline
                    maxRows={4}
                    sx={{
                        width: '400px', // Set the desired width
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'blue', // Set border color to light blue
                            },
                            '&:hover fieldset': {
                                borderColor: 'blue', // Set border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'blue', // Set border color when focused
                            },
                        },
                    }}
                />
                <Button
                    sx={{ padding: "10px 50px", borderRadius: "30px" }}
                    variant="contained"
                    disableElevation
                    onClick={handleVerifyOtp}
                    disabled={isLoading} // Disable button when loading
                >
                    {isLoading ? "Verifying..." : "Verify otp"}
                </Button>
         
            </div>
        </div>
    );
}

export default Qrnewotpvalidate;
