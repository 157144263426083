

import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Commontransition from "../../commoncomponents/Commontransition.jsx";
import { Button, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import useCommonBackground from "../../hook/Commonhook.jsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const commonstyle = {
  container: {
    display: "flex",
    flexDirection: "column",
  },

  Datepickercontainer: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
    padding: "10px",
    justifyContent: "center",
    gap: 30,
  },

  Searchbutton: {
    marginLeft: "10px",
    height: "56px",
  },
  Exportbutton: {
    marginLeft: "10px",
    height: "56px",
  },
  Searchcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "60px",
  },
  Searchfield: {
    width: "70%",
    height: "45px",
    borderRadius: "15px",
    borderColor: "lightGray",
    paddingLeft: "20px",
  },
  DialogcontentText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: 30,
  },
  Excelbutton: {
    padding: "10px 40px",
    borderRadius: "10px",
  },
  Pdfbutton: {
    padding: "10px 50px",
    borderRadius: "10px",
  },
  Gridcontainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "150px",
    padding: "40px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
  },
};

const columns = [
  { id: "sno", label: "S.No", minWidth: 50 },
  { id: "memberId", label: "MemberId", minWidth: 100 },
  { id: "beneficiaryaccountnumber", label: "Beneficiary Acc No", minWidth: 150 },
  { id: "benName", label: "Beneficiary Name", minWidth: 170 },
  { id: "benIFSC", label: "Beneficiary IFSC", minWidth: 170 },
  { id: "remark", label: "Remark", minWidth: 150 },
  { id: "description", label: "Description", minWidth: 170 },
  { id: "mode", label: "Mode", minWidth: 170 },
  { id: "rrnNo", label: "RRN No", minWidth: 170 },
  { id: "amount", label: "Amount", minWidth: 100, align: "right" },
  { id: "surCharge", label: "SurCharge", minWidth: 100, align: "right" },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "date", label: "Date", minWidth: 170, align: "right" },
];

function Payoutreport() {
  useCommonBackground();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [originalRows, setOriginalRows] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, startDate, endDate]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("adminAuthToken");
      let url = `https://zevopay.online/api/v1/admin/transaction?page=${
        page + 1
      }&perPage=${rowsPerPage}`;
      if (startDate && endDate) {
        url += `&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (data.data) {
        const filteredData = data.data
          .filter((item) => item.type === "payout")
          .map((item, index) => ({
            sno: index + 1,
            memberId: item.userId || "-",
            beneficiaryaccountnumber: item.creditAccountNumber || "-",
            benName: item.beneficiaryName || "-",
            benIFSC: item.beneficiaryIFSC || "-",
            remark: item.paymentDescription || "-",
            description:
              item.status === "REFUNDED" ||
              item.status === "SUCCESS" ||
              item.status === "FAILED"
                ? (() => {
                    if (item.status === "FAILED") {
                      return `${item.errorMessage} / ${item.id}`; // For FAILED, show errorMessage and id
                    } else if (item.status === "REFUNDED") {
                      return `${item.paymentDescription} / ${item.transactionID}`; // For REFUNDED, show paymentDescription and transactionID
                    } else if (item.status === "SUCCESS") {
                      return `${item.errorMessage} / ${item.id}`; // For SUCCESS, show errorMessage and id
                    }
                    return "-"; // Default case if none of the conditions match
                  })()
                : item.errorMessage || "-", // Otherwise, show errorMessage if available
            // description:
            //   item.status === "REFUNDED"
            //     ? item.paymentDescription || "-"
            //     : item.errorMessage || "-",
            mode: item.type || "-",
            rrnNo: item.transactionReferenceNo || "-",
            amount: item.amount || "-",
            surCharge: item.surchargeAmount || "-",
            status: item.status || "-",
            date: new Intl.DateTimeFormat("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }).format(new Date(item.created_at)),
          }));
        // const totalFilteredPages = filteredData.length;
        // setPage(totalFilteredPages);
        setOriginalRows(filteredData);
        setRows(filteredData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  console.log(originalRows, "DATASHYAM");

  useEffect(() => {
    let filteredData = [...originalRows];

    if (searchQuery) {
      filteredData = filteredData
        .filter(
          (row) =>
            row.memberId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.beneficiaryaccountnumber
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            row.benName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.benIFSC.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.mode.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.rrnNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.status.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          // Prioritize matching rows
          if (
            a.memberId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            a.beneficiaryaccountnumber
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            a.benName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            a.benIFSC.toLowerCase().includes(searchQuery.toLowerCase()) ||
            a.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            a.mode.toLowerCase().includes(searchQuery.toLowerCase()) ||
            a.rrnNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
            a.status.toLowerCase().includes(searchQuery.toLowerCase())
          )
            return -1;
          if (
            b.memberId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            b.beneficiaryaccountnumber
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            b.benName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            b.benIFSC.toLowerCase().includes(searchQuery.toLowerCase()) ||
            b.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            b.mode.toLowerCase().includes(searchQuery.toLowerCase()) ||
            b.rrnNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
            b.status.toLowerCase().includes(searchQuery.toLowerCase())
          )
            return 1;
          return 0; // Maintain original order for non-matching rows
        });
    }

    console.log(filteredData, "filteredDataresponsefromserver");

    // Reverse the filtered data
    filteredData = filteredData.reverse();

    setRows(filteredData.reverse());

    console.log(filteredData, "filtererdatatreverse");
    // Update the state with the filtered data
  }, [searchQuery, originalRows]); // Run whenever searchQuery or originalRows changes

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Commontransition
        text={"Payout Report"}
        transition={"P/R"}
        onClick={() => window.location.reload()}
      />
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          alignItems: "center",
          padding: "10px",
          justifyContent: "center",
          gap: 30,
        }}
      >
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          isClearable
          placeholderText="From date"
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          isClearable
          placeholderText="To date"
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: "10px",
            height: "56px",
            backgroundColor: "red",
            borderRadius: "10px",
          }}
          onClick={fetchData}
        >
          Search
        </Button>
      </div>
      <div style={commonstyle.Searchcontainer}>
        <input
          type="text"
          style={commonstyle.Searchfield}
          placeholder="Search"
          value={searchQuery} // Bind the search query to the input value
          onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
        />
      </div>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "200px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          {typeof row[column.id] === "number"
                            ? row[column.id].toLocaleString("en-US")
                            : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 1000, 5000, 10000]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </div>
  );
}

export default Payoutreport;
