import React, { useEffect, useState } from "react";
import Commontransition from "../commoncomponents/Commontransition";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  TextField,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import DatePicker from "react-datepicker";
import useCommonBackground from "../hook/Commonhook.jsx";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { getAllData } from "../redux/Userdetail";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx";

const commonstyle = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  Datepickercontainer: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
    padding: "10px",
    justifyContent: "center",
    gap: 30,
  },
  Searchbutton: {
    marginLeft: "10px",
    height: "56px",
  },
  Exportbutton: {
    marginLeft: "10px",
    height: "56px",
  },
  Searchcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "60px",
  },
  Searchfield: {
    width: "70%",
    height: "45px",
    borderRadius: "15px",
    borderColor: "lightGray",
    paddingLeft: "20px",
  },
  DialogcontentText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: 30,
  },
  Excelbutton: {
    padding: "10px 40px",
    borderRadius: "10px",
  },
  Pdfbutton: {
    padding: "10px 50px",
    borderRadius: "10px",
  },
  Gridcontainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "210px",
    padding: "40px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
  },
};

const columns = [
  { id: "sno", label: "S.NO", minWidth: 50 },
  { id: "MemberId", label: "MemberId", minWidth: 150 },
  { id: "virtualAccount", label: "Virtual Account", minWidth: 150 },
  { id: "txnId", label: "Txn Id", minWidth: 150 },
  { id: "description", label: "Description", minWidth: 200 }, // New Description column
  { id: "tranAmt", label: "Amount", minWidth: 150 },
  { id: "trandate", label: "Date", minWidth: 200 },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Virtualaccountreport() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [originalRows, setOriginalRows] = useState([]);

  const [searchQuery, setSearchQuery] = React.useState("");
  //new lind added
  const { users, loading, error } = useSelector((state) => state.Userdata);

  console.log(users, "userdataget");

  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  useCommonBackground();

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
    rows: [],
    memberdata: [],
  });

  console.log(state.rows, "state.rows");

  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
  });

  const MemberId = localStorage.getItem("UserId").toUpperCase();

  const token = localStorage.getItem("authToken");

  const datafetch = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL + "/user"}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responsedata = await response.json();

      setState((prevstate) => ({
        ...prevstate,
        memberdata: responsedata,
      }));
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    datafetch();
  }, []);

  const handleSearch = () => {
    fetchData(filters.fromDate, filters.toDate);
  };

  const fetchData = async (fromDate, toDate) => {
    const url = `${process.env.REACT_APP_API_URL}/wallet/transactions`;
    try {
      const params = new URLSearchParams();
      if (fromDate) params.append("start_date", fromDate.toISOString());
      if (toDate) params.append("end_date", toDate.toISOString());

      const response = await fetch(`${url}?${params.toString()}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result = await response.json();
      if (result.data) {
        const allowedModes = [
          "IMPS",
          "RTGS",
          "NEFT",
          "CASH CREDIT",
          "CASH DEBIT",
        ];
        const filteredData = result.data
          .filter((item) => allowedModes.includes(item.mode)) // Filter based on allowed modes
          .map((item) => ({
            ...item,
            MemberId,
            virtualAccount: item.van,
            txnId: item.utr || "-",
            description: item.Sender_receiver_info || "-",
          }));


        setOriginalRows(filteredData); // Save original data
        setState((prevstate) => ({
          ...prevstate,
          rows: filteredData, // Reverse the array to show latest transactions at the top
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setState((prevstate) => ({
      ...prevstate,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setState((prevstate) => ({
      ...prevstate,
      rowsPerPage: +event.target.value,
      page: 0,
    }));
  };

  const Name = localStorage.getItem("Name");
  console.log(Name, "Name");
  const Phonenumber = localStorage.getItem("Phone");
  console.log(Phonenumber, "Phonenumber");
  const EmailId = localStorage.getItem("email");
  console.log(EmailId, "EmailIfD");
  const virtual_account = localStorage.getItem("virtual_account");

  //  Export to Excel

  const exportToExcel = () => {
    //   // Format the table data and start adding from the next row after headers
    const formattedRows = state.rows.map((row, index) => ({
      "S.NO": index + 1,
      MemberId: row.MemberId,
      VirtualAccount: virtual_account,
      TxnId: row.utr || "-",
      Description: row.Sender_receiver_info || "-",
      Amount: row.tranAmt,
      // UPId: row.UPId,
      // "BANKRRN/UTR": row.utr,
      Date: new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true, // 24-hour format
      }).format(new Date(row.trandate)),
    }));

    const worksheet = XLSX.utils.json_to_sheet([], { skipHeader: true });

    const userDetails = [
      [`Name: ${Name}`],
      [`Email: ${EmailId}`],
      [`Phone: ${Phonenumber}`],
      [`Virtual Account: ${virtual_account}`],
      [`Status: ${users?.status || "null"}`],
      [`Bank Name: ${"IDBI Bank Ltd."}`],
      [`IFSC: ${"IBKL0002031"}`],
      [`Account Type: ${"Current Account"}`],
      [`Address: ${users?.address || "null"}`],
      [`AadharNumber: ${users?.aadharNumber || "null"}`],
      [`PanNumber: ${users?.panNumber || "null"}`],
      [`State: ${users?.state || "null"}`],
      [`ShopName: ${users?.shopName || "null"}`],
      [`ShopAddress: ${users?.shopAddress || "null"}`],
      [`GstNumber: ${users?.gstNumber || "null"}`],
      [`BusinessPanNo: ${users?.businessPanNo || "null"}`],
      [`LandlineNo: ${users?.landlineNumber || "null"}`],
      [`LandlineStdCode: ${users?.landlineSTDCode || "null"}`],
    ];

    XLSX.utils.sheet_add_aoa(worksheet, userDetails, { origin: "A3" });

    const headerRowIndex = userDetails.length + 5;

    const headers = [
      [
        "S.NO",
        "Member ID",
        "VirtualAccount",
        "TxnId",
        "Description",
        "Amount",
        "Date",
      ],
    ];
    XLSX.utils.sheet_add_aoa(worksheet, headers, {
      origin: `A${headerRowIndex}`,
    });

    XLSX.utils.sheet_add_json(worksheet, formattedRows, {
      origin: `A${headerRowIndex + 1}`,
      skipHeader: true,
    });

    const headerRow = headerRowIndex - 1;
    headers[0].forEach((_, colIndex) => {
      const cellRef = XLSX.utils.encode_cell({ r: headerRow, c: colIndex });
      if (!worksheet[cellRef]) return;
      worksheet[cellRef].s = {
        alignment: { horizontal: "center", vertical: "center" },
        font: { bold: true },
      };
    });

    worksheet["!cols"] = [
      { wch: 5 }, //0
      { wch: 20 }, // 1
      { wch: 25 }, // 2
      { wch: 70 }, // 3
      { wch: 30 },
      { wch: 10 },
      { wch: 10 },
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

    XLSX.writeFile(workbook, "Virtual Account Report.xlsx");
    handleClose();
  };

  // Export to pdf
  const generatePDF = () => {
    const MyDocument = (
      <Document>
        <Page size={[800, 900]}>
          <View style={styles.container}>
            <Text style={styles.header}>Virtual Account Report</Text>

            <Text>Name: {Name}</Text>
            <Text>Email: {EmailId}</Text>
            <Text>Phone: {Phonenumber}</Text>
            <Text>Virtual Account: {virtual_account}</Text>
            <Text>Status: {users?.status || "null"}</Text>
            <Text>Bank Name: {"IDBI Bank Ltd."}</Text>
            <Text>IFSC: {"IBKL0002031"}</Text>
            <Text>Account Type: {"Current Account"}</Text>
            <Text>Address: {users?.address || "null"}</Text>
            <Text>AadharNumber: {users?.aadharNumber || "null"}</Text>
            <Text>PanNumber: {users?.panNumber || "null"}</Text>
            <Text>State: {users?.state || "null"}</Text>
            <Text>ShopName: {users?.shopName || "null"}</Text>
            <Text>ShopAddress: {users?.shopAddress || "null"}</Text>
            <Text>GstNumber: {users?.gstNumber || "null"}</Text>
            <Text>BusinessPanNo: {users?.businessPanNo || "null"}</Text>
            <Text>LandlineNo: {users?.landlineNumber || "null"}</Text>
            <Text>LandlineStdCode: {users?.landlineSTDCode || "null"}</Text>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>S.No</Text>
                <Text style={styles.tableCell}>Member ID</Text>
                <Text style={styles.tableCell}>VirtualAccount</Text>
                <Text style={styles.tableCell}>TxnId</Text>
                <Text style={styles.tableCell}>Description</Text>
                <Text style={styles.tableCell}>Amount</Text>
                <Text style={styles.tableCell}>Date</Text>
              </View>
              {state.rows.map((row, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.narrationCell}>{index + 1}</Text>
                  <Text style={styles.narrationCell}>{row.user.userId}</Text>
                  <Text style={styles.narrationCell}>{row.virtualAccount}</Text>
                  <Text style={styles.narrationCell}>{row.txnId}</Text>
                  <Text style={styles.narrationCell}>
                    {row.Sender_receiver_info}
                  </Text>
                  <Text style={styles.narrationCell}>{row.tranAmt}</Text>
                  {/* <Text style={styles.tableCell}>{row.date}</Text> */}
                  <Text style={styles.narrationCell}>
                    {new Intl.DateTimeFormat("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    }).format(new Date(row.trandate))}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </Page>
      </Document>
    );

    pdf(MyDocument)
      .toBlob()
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "TransactionsReport.pdf";
        link.click();
      });
    handleClose();
  };

  // Global function to export both Excel and PDF

  const handleExportClick = () => {
    setOpen(true);
  };


  console.log(state.rows, "rowsdataofuser")

  // Added this useeffect for searching narration of users 

  useEffect(() => {
    let filteredData = [...originalRows];

    if (searchQuery) {
      filteredData = filteredData
        .filter(
          (row) =>
            row.txnId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.description.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          // Prioritize matching rows
          if (
            a.txnId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            a.description.toLowerCase().includes(searchQuery.toLowerCase())
          )
            return -1;
          if (
            b.txnId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            b.description.toLowerCase().includes(searchQuery.toLowerCase())
          )
            return 1;
          return 0; // Maintain original order for non-matching rows
        });
    }

    // Reverse the filtered data
    filteredData = filteredData.reverse();

    // Update the state with the filtered data
    setState((prevstate) => ({
      ...prevstate,
      rows: filteredData,
    }));
  }, [searchQuery, originalRows]); // Run whenever searchQuery or originalRows changes


  return (
    <div style={commonstyle.container}>
      <Commontransition
        text={"Virtual Account Report"}
        transition={"V/C"}
        onClick={() => window.location.reload()}
      />
      <div style={commonstyle.Datepickercontainer}>
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          selected={filters.fromDate}
          onChange={(date) =>
            setFilters((prev) => ({ ...prev, fromDate: date }))
          }
          placeholderText="From date"
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          selected={filters.toDate}
          onChange={(date) => setFilters((prev) => ({ ...prev, toDate: date }))}
          placeholderText="To date"
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          onClick={handleSearch}
          variant="contained"
          color="primary"
          style={commonstyle.Searchbutton}
        >
          Search
        </Button>
        {/*   Export button to donload table  data in excel sheet  */}
        <Button
          onClick={handleExportClick}
          variant="contained"
          color="primary"
          style={commonstyle.Exportbutton}
        >
          EXPORT
        </Button>
      </div>
      <div style={commonstyle.Searchcontainer}>
        <input
          type="text"
          style={commonstyle.Searchfield}
          placeholder="Search"
          value={searchQuery} // Bind the search query to the input value
          onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
        />
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"How to Download Virtualaccount History?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={commonstyle.DialogcontentText}
          >
            <Button
              style={commonstyle.Excelbutton}
              onClick={() => exportToExcel()}
              variant="contained"
            >
              EXCEL
            </Button>
            <Button
              style={commonstyle.Pdfbutton}
              onClick={() => generatePDF()}
              variant="contained"
            >
              PDF
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Grid container sx={commonstyle.Gridcontainer}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {state.rows
                  .slice(
                    state.page * state.rowsPerPage,
                    state.page * state.rowsPerPage + state.rowsPerPage
                  )
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          // const value =
                          //   column.id === "sno"
                          //     ? state.page * state.rowsPerPage + index + 1
                          //     : row[column.id];

                          const value =
                            column.id === "sno"
                              ? state.page * state.rowsPerPage + index + 1
                              : column.id === "trandate"
                                ? new Intl.DateTimeFormat("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true, // 24-hour format
                                }).format(new Date(row.trandate))
                                : row[column.id];

                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={state.rows.length}
            rowsPerPage={state.rowsPerPage}
            page={state.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  table: {
    marginTop: 20,
  },
  tableRow: {
    flexDirection: "row",
    marginBottom: 5,
  },
  tableCell: {
    marginRight: 10,
    fontSize: 10,
    border: "1px solid black",
    padding: 8, // Increased padding to give more space
    flex: 1,
    maxWidth: 100, // Set max width to control overflow
    overflow: "hidden",
    textOverflow: "ellipsis", // Show ellipsis if text overflows
    wordBreak: "break-word", // Break words to the next line
  },
  narrationCell: {
    marginRight: 3,
    fontSize: 10,
    border: "1px solid black",
    padding: 10, // Increased padding for more space
    flex: 4, // Adjust this if you want to allocate more space to these cells
    maxWidth: 120, // Set max width to control overflow
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word", // Break long text onto the next line
    flexWrap: "wrap", // Allow wrapping to the next line if necessary
  },
});

export default Virtualaccountreport;




