import axios from "axios";

// Base URL configuration
const token = localStorage.getItem("authToken");
const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

/**
 * Function to initiate a payout transaction
 * @param {Object} payload - The transaction details
 * @returns {Promise} - Axios promise with the response
 */
export const initiatePayout = async (payload) => {
  try {
    const response = await apiClient.post("/webhook/payout", payload);

    // Check if the transaction was successful
    const metaData = response.data?.decryptedBankResponse?.initiateAuthGenericFundTransferAPIResp?.metaData;
    if (metaData?.status === "SUCCESS") {
      return {
        success: true,
        message: "Transaction Successful!",
        transactionId: response.data.decryptedBankResponse.initiateAuthGenericFundTransferAPIResp.resourceData.transactionID,
      };
    } else {
      return {
        success: false,
        message: "Transaction Failed!",
      };
    }
  } catch (error) {
    console.error("Payout API Error:", error);
    return {
      success: false,
      message: "Transaction Failed! Please try again.",
    };
  }
};

export default apiClient;
