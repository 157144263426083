import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../footer/Footer";

function Company() {
  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#fff";

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Revert back to default background color
    };
  }, []);
  return (
    <>
      <Navbar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // marginTop:"70px",
          // minHeight:"70vh"
          justifyContent:'center',
          alignItems:'center',
          // paddingLeft: "20px",
          // paddingRight: "20px",
        }}
      >

      <div style={{display:"flex",  justifyContent:"center" , alignItems:'center' , flexDirection:'column', marginTop:"120px", paddingLeft:"20px", paddingRight:"20px"}}>
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "500",
              color: "inherit",

              lineHeight: "1.1",
            }}
          >
            About Us
          </h1>
        
        <div
          style={{
            // marginTop: "-180px",
            paddingLeft: "60px",
            paddingRight: "60px",
            // gap:"20px"
          }}
        >
          <span style={{ fontSize: "20px", color: "#333333" }}>
            ZEVOSOFT SERVICES PRIVATE LIMITED of service excellence in the
            digital business of micro payments and aadhaar pay of services and
            remittances in a ‘real time’ environment is India's Largest Payments
            Solutions Provider. Its business involves service aggregation and
            distribution, utilizing mobile, PoS and web for online payments
            processing. It is built to service the
            nation's Telecom/DTH Operators, multiple Services Providers and
            Banks, pan India.
          </span>
        </div>
        <div
          style={{
            marginTop: "20px",
            paddingLeft: "60px",
            paddingRight: "60px",
          }}
        >
          <span style={{ fontSize: "20px", color: "#333333" }}>
            ZEVOSOFT SERVICES PRIVATE LIMITED India's largest mobile payments
            and commerce platform. It started with online mobile recharge and
            bill payments and has an online marketplace today. In a short span
            of time, we have scaled to over 10000 registered users.pay indiathe
            consumer brand of India's leading Website Development company. We
            strive to maintain an open culture where everyone is a hands-on
            contributor and feels comfortable sharing ideas and opinions. Our
            team spends hours designing each new feature and obsesses about the
            smallest of detail.
          </span>
        </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Company;
