import React, { useEffect } from "react";
import Commontransition from "../commoncomponents/Commontransition";

import Dummy from "../assets/dummy.png";
import { useSelector, useDispatch } from "react-redux";
import { getAllData } from "../redux/Userdetail";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

function Profile() {
  const { users, loading, error } = useSelector((state) => state.Userdata);
  console.log(users.imageUrl, "usersdata");
  const dispatch = useDispatch();

  const [age, setAge] = React.useState("India");
  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#F8F8FB";

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Revert back to default background color
    };
  }, []);

  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const Name = localStorage.getItem("Name");
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Commontransition
        text={"Manage Member Master"}
        transition={"Manage Member Master"}
        onClick={() => window.location.reload()}
      />

      {/* Responsive Grid with Cards */}
      <Grid
        container
        spacing={6}
        sx={{
          marginTop: "20px",
          padding: "20px",
        }}
      >
        {/* Left Card */}
        <Grid item xs={12} sm={6}>
          <Card
            sx={{
              backgroundColor: "#fff",
              boxShadow: "2px 2px 5px rgba(0,0,0,0.2)",
              height: "300px",
            }}
          >
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <div
                style={{
                  padding: "80px",
                  background:
                    "linear-gradient(90deg, #ff8300 0%, #FF6000 100%)",
                  boxSizing: "border-box", // Include padding in the total width
                }}
              >
                {/* Content can go here */}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    position: "absolute", // Position absolute to overlap the gradient
                    top: "140px",
                    overflow: "hidden", // Ensure the image does not overflow the circle,
                    width: "150px", // Set the width of the circle
                    height: "150px", // Set the height of the circle
                    backgroundColor: "white", // Circle background color
                    borderRadius: "50%", // Make the div circular
                    display: "flex", // Center the image within the circle
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)", // Optional shadow
                  }}
                >
                  <img
                    src={users.imageUrl ? users.imageUrl : Dummy} // Replace with your image URL
                    alt="Circular"
                    style={{
                      width: "100%", // Make image take the full width
                      height: "100%", // Make image take the full height
                      objectFit: "cover", // Maintain aspect ratio while covering the div
                      borderRadius: "50%", // Make the image circular
                    }}
                  />
                </div>
                <h3 style={{ marginLeft: "190px", marginTop: "40px" }}>
                  {Name}
                </h3>
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* Right Card */}
        <Grid item xs={12} sm={6}>
          <Card
            sx={{
              backgroundColor: "#fff",
              boxShadow: "2px 2px 5px rgba(0,0,0,0.2)",
            }}
          >
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#F3F2F3",
                  padding: "30px",
                }}
              >
                <span
                  style={{
                    color: "#353535",
                    fontSize: "25px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Member Type * Retailer{" "}
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                    marginTop: "10px",
                  }}
                >
                  <span
                    style={{
                      color: "#353535",
                      fontSize: "25px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Package
                  </span>
                  <span>*</span>
                  <FormControl style={{ width: "30%" }} variant="outlined">
                    <InputLabel id="special-package-label">
                      SPECIAL PACKAGE
                    </InputLabel>
                    <Select
                      labelId="special-package-label"
                      id="special-package-select"
                      label="SPECIAL PACKAGE"
                      defaultValue=""
                    >
                      {/* No options inside the dropdown */}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Personal Info Grid 1 */}
      <Grid
        container
        sx={{
          marginLeft: "20px",
          display: "flex",
          flexDirection: "column",
          width: "98%",
          marginTop: "20px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h3 style={{ fontSize: "35px" }}>Personal Info</h3>
        <Grid container spacing={7} style={{ marginTop: "1px" }}>
          {/* Row 1 */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-1"
              label="First Name"
              defaultValue="Shyam"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-2"
              label="Last Name"
              defaultValue="Jha"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-3"
              label="Aadhar"
              variant="standard"
              fullWidth
            />
          </Grid>

          {/* Row 2 */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-4"
              label="Pan"
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-5"
              label="Business PAN Number"
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-6"
              label="Shop Name"
              variant="standard"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={10} style={{ marginTop: "20px" }}>
          {/* Row 1 */}
          <Grid item xs={12} sm={6}>
            <TextField
              id="additional-info-input-1"
              label="Shop Address"
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="additional-info-input-2"
              label="GST Number"
              variant="standard"
              fullWidth
            />
          </Grid>

          {/* Row 2 */}
          <Grid item xs={12} sm={6}>
            <TextField
              id="additional-info-input-3"
              defaultValue="JODHPUR"
              variant="standard"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 500 }}>
              <InputLabel id="demo-simple-select-standard-label">
                India
              </InputLabel>
              <Select
                // fullWidth
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={age}
                onChange={handleChange}
                label="India"
              >
                <MenuItem value={10}>India</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Row 3 */}
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 500 }}>
              <InputLabel id="demo-simple-select-standard-label">
                JODHPUR
              </InputLabel>
              <Select
                // fullWidth
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={age}
                onChange={handleChange}
                label="JODHPUR"
              >
                <MenuItem value={10}>JODHPUR</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={{
                backgroundColor: "lightgray",
                padding: "5px",
                width: "300px",
              }}
            >
              <h3>{localStorage.getItem("email")}</h3>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={7} style={{ marginTop: "1px" }}>
          {/* Row 1 */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-1"
              label=""
              defaultValue="6204132195"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-2"
              label="Landline STDCode"
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-3"
              label="Landline Number"
              variant="standard"
              fullWidth
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          style={{
            padding: "20px",
            width: "140px",
            marginTop: "30px",
            backgroundColor: "#083090",
          }}
        >
          Submit
        </Button>
      </Grid>
    </div>
  );
}

export default Profile;
