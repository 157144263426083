import React from "react";
import "./Dasboardcard.css";

function Dashboardcard() {
  return (
    <div className="box box1">
      <div className="text">
        <h2 className="topic-heading">6.5k</h2>
        <h2 className="topic">Article Views</h2>
      </div>
    </div>
  );
}

export default Dashboardcard;
