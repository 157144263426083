// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsive-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    flex-direction: column; /* Default: Column for mobile view */
  }
  
  .responsive-container img {
    width: 100%; /* Adjust the width for mobile view */
    max-width: 700px; /* Optional max width */
  }

  

  @media (min-width: 768px) {
    .responsive-image {
      width: 45%; /* Adjust the width for desktop view */
      height: auto;
    }
  }
  .responsive-text {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    /* margin: 0; */
    /* background-color: red; */
    /* gap: 50px; */
  }
  
  /* Media Query for Desktop View */
  @media (min-width: 768px) {
    .responsive-container {
      flex-direction: row; /* Row for desktop view */
    }
    
    .responsive-text {
        /* background-color: red; */
      margin-left: 16px; /* Optional: Add some space on desktop */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Homemiddle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,sBAAsB,EAAE,oCAAoC;EAC9D;;EAEA;IACE,WAAW,EAAE,qCAAqC;IAClD,gBAAgB,EAAE,uBAAuB;EAC3C;;;;EAIA;IACE;MACE,UAAU,EAAE,sCAAsC;MAClD,YAAY;IACd;EACF;EACA;IACE,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,2BAA2B;IAC3B,eAAe;EACjB;;EAEA,iCAAiC;EACjC;IACE;MACE,mBAAmB,EAAE,yBAAyB;IAChD;;IAEA;QACI,2BAA2B;MAC7B,iBAAiB,EAAE,wCAAwC;IAC7D;EACF","sourcesContent":[".responsive-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-top: 40px;\r\n    flex-direction: column; /* Default: Column for mobile view */\r\n  }\r\n  \r\n  .responsive-container img {\r\n    width: 100%; /* Adjust the width for mobile view */\r\n    max-width: 700px; /* Optional max width */\r\n  }\r\n\r\n  \r\n\r\n  @media (min-width: 768px) {\r\n    .responsive-image {\r\n      width: 45%; /* Adjust the width for desktop view */\r\n      height: auto;\r\n    }\r\n  }\r\n  .responsive-text {\r\n    width: 100%;\r\n    padding-left: 30px;\r\n    padding-right: 30px;\r\n    /* margin: 0; */\r\n    /* background-color: red; */\r\n    /* gap: 50px; */\r\n  }\r\n  \r\n  /* Media Query for Desktop View */\r\n  @media (min-width: 768px) {\r\n    .responsive-container {\r\n      flex-direction: row; /* Row for desktop view */\r\n    }\r\n    \r\n    .responsive-text {\r\n        /* background-color: red; */\r\n      margin-left: 16px; /* Optional: Add some space on desktop */\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
