import { configureStore } from "@reduxjs/toolkit";
import Userdetail from "./Userdetail";
import Balance from "./Balance";
import Adminuserdata from "./Adminuserdata";

const Store = configureStore({
  reducer: {
    Userdata: Userdetail,
    Balance: Balance,
    Adminuserdata:Adminuserdata
  },
});
export default Store;
