// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    gap: 50px;
    margin-top: 30px;
    padding: 10px;
  }
  
  .date-picker-row {
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .date-picker-row {
      flex-direction: column;
      
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Payoutreport.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE;MACE,sBAAsB;IACxB;;IAEA;MACE,sBAAsB;;IAExB;EACF","sourcesContent":[".container {\r\n    display: flex;\r\n    gap: 50px;\r\n    margin-top: 30px;\r\n    padding: 10px;\r\n  }\r\n  \r\n  .date-picker-row {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  \r\n  @media (max-width: 768px) {\r\n    .container {\r\n      flex-direction: column;\r\n    }\r\n  \r\n    .date-picker-row {\r\n      flex-direction: column;\r\n      \r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
