import React, { useEffect } from "react";
import Commontransition from "../commoncomponents/Commontransition";
import Addmoney from "./Addmoney";

function Addfundrequest() {
  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#F8F8FB";

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Revert back to default background color
    };
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Commontransition
      onClick={() => window.location.reload()}
        text={"Send Fund Add Request Panel"}
        transition={"Send Fund Add"}
      />
      <div style={{ marginTop: "50px" }}>
        <Addmoney />
      </div>
    </div>
  );
}

export default Addfundrequest;
