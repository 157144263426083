// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles.css or inline styles */
.date-picker-container .react-datepicker {
    z-index: 1600; 
  }
  `, "",{"version":3,"sources":["webpack://./src/commoncomponents/Commoncalender.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,aAAa;EACf","sourcesContent":["/* Styles.css or inline styles */\n.date-picker-container .react-datepicker {\n    z-index: 1600; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
