import React, { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import Commontransition from "../commoncomponents/Commontransition";

function Newpayout() {
  const [formData, setFormData] = useState({
    accountNumber: "",
    ifsc: "",
    beneficiaryName: "",
    amount: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.body.style.backgroundColor = "#F8F8FB";
    return () => {
      document.body.style.backgroundColor = ""; // Revert background color
    };
  }, []);

  const validate = () => {
    const newErrors = {};
    if (!formData.accountNumber) {
      newErrors.accountNumber = "Enter Account Number";
    }
    if (!formData.ifsc) {
      newErrors.ifsc = "IFSC is required.";
    } else if (!/^[A-Za-z]{4}\d{7}$/.test(formData.ifsc)) {
      newErrors.ifsc = "Enter IFSC Code";
    }
    if (!formData.beneficiaryName) {
      newErrors.beneficiaryName = "Enter BenificiaryName";
    }
    if (!formData.amount) {
      newErrors.amount = "Enter Amount";
    } else if (isNaN(formData.amount) || formData.amount <= 0) {
      newErrors.amount = "Amount must be a positive number.";
    }
    return newErrors;
  };

  const handleSubmit = () => {
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // If no errors, handle form submission
      console.log("Form submitted successfully:", formData);
    }
  };

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    setErrors({ ...errors, [field]: "" }); // Clear error when user starts typing
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}
    >
      <Commontransition
        text={"New Payout Panel"}
        transition={"Xpress DMT"}
        onClick={() => window.location.reload()}
      />
      <div
        style={{
          marginTop: "60px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          gap: 40,
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
          padding: "20px",
          paddingLeft: "70px",
          paddingRight: "70px",
          paddingTop: "40px",
        }}
      >
        {/* Account Number */}
        <Grid
          container
          spacing={1}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Grid item xs={12} md={6}>
            <span
              style={{
                fontSize: "20px",
                color: "black",
                fontFamily: "sans-serif",
              }}
            >
              Account Number
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                paddingLeft: "10px",
                width: "100%",
                height: "60px",
                borderColor: "lightgray",
              }}
              type="text"
              value={formData.accountNumber}
              onChange={(e) => handleChange("accountNumber", e.target.value)}
            />
            {errors.accountNumber && (
              <span style={{ color: "red" }}>{errors.accountNumber}</span>
            )}
          </Grid>
        </Grid>

        {/* IFSC */}
        <Grid
          container
          spacing={1}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Grid item xs={12} md={6}>
            <span
              style={{
                fontSize: "20px",
                color: "black",
                fontFamily: "sans-serif",
              }}
            >
              IFSC
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                paddingLeft: "10px",
                width: "100%",
                height: "60px",
                borderColor: "lightgray",
              }}
              type="text"
              value={formData.ifsc}
              onChange={(e) => handleChange("ifsc", e.target.value)}
            />
            {errors.ifsc && <span style={{ color: "red" }}>{errors.ifsc}</span>}
          </Grid>
        </Grid>

        {/* Beneficiary Name */}
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Beneficiary Name */}
          <Grid item xs={12} md={6}>
            <span
              style={{
                fontSize: "20px",
                color: "black",
                fontFamily: "sans-serif",
              }}
            >
              Beneficiary Name
            </span>
          </Grid>

          {/* Input Field, Text, and Button */}
          <Grid item xs={12} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column", // Stack input and text vertically
                width: "100%",
              }}
            >
              {/* Input and Button in the Same Row */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px", // Space between input and button
                }}
              >
                <input
                  style={{
                    paddingLeft: "10px",
                    width: "100%",
                    height: "60px",
                    borderColor: "lightgray",
                  }}
                  type="text"
                  value={formData.beneficiaryName}
                  onChange={(e) =>
                    handleChange("beneficiaryName", e.target.value)
                  }
                />

                <Button
                  onClick={() => alert("Validate successfully.")}
                  variant="contained"
                  style={{
                    backgroundColor: "#083090",
                    fontWeight: "500",
                    padding: "30px",
                    height: "60px", // Match the height of the input
                  }}
                >
                  VALIDATE
                </Button>
              </div>

              {/* Text Below the Input Field */}
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  // textAlign:"center",
                  color: "red",
                  fontFamily: "sans-serif",
                  marginTop: "5px", // Add some spacing between input and text
                }}
              >
                Account Verification Charge RS 3
              </span>
              {errors.beneficiaryName && (
                <span style={{ color: "red" }}>{errors.beneficiaryName}</span>
              )}
            </div>
          </Grid>
        </Grid>

        {/* Amount */}
        <Grid
          container
          spacing={1}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Grid item xs={12} md={6}>
            <span
              style={{
                fontSize: "20px",
                color: "black",
                fontFamily: "sans-serif",
              }}
            >
              Amount
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                paddingLeft: "10px",
                width: "100%",
                height: "60px",
                borderColor: "lightgray",
              }}
              type="text"
              value={formData.amount}
              onChange={(e) => handleChange("amount", e.target.value)}
            />
            {errors.amount && (
              <span style={{ color: "red" }}>{errors.amount}</span>
            )}
          </Grid>
        </Grid>

        {/* Submit and Reset Buttons */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 30,
          }}
        >
          <button
            style={{
              backgroundColor: "#0B8405",
              padding: "12px 20px",
              borderRadius: "25px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "transparent",
            }}
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            style={{
              backgroundColor: "#DE2A2A",
              padding: "12px 20px",
              borderRadius: "25px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "transparent",
            }}
            onClick={() =>
              setFormData({
                accountNumber: "",
                ifsc: "",
                beneficiaryName: "",
                amount: "",
              })
            }
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default Newpayout;
