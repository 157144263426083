import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import P1 from "../assets/p1.png";
import p2 from "../assets/p2.png";
import p3 from "../assets/p3.png";
import p4 from "../assets/p4.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import './styles.css';

// import required modules
import { Pagination } from "swiper/modules";

function Bankcarousel() {
  return (
    <div style={{ marginTop: "40px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "300px",
          backgroundColor: "#EBEFFD",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <span
          style={{
            fontSize: "30px",
            marginTop: "30px",
            paddingLeft: "30px",
            color: "#642C8B",
            fontWeight: "bold",
          }}
        >
          With us
        </span>

        <Swiper
          style={{ marginTop: "-20px" }}
          slidesPerView={1}
          spaceBetween={3}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide style={{ backgroundColor: "transparent" }}>
            <div style={{ width: "70%", height: "70px", borderRadius: "10px" }}>
              <img style={{ width: "100%", borderRadius: "10px" }} src={P1} />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundColor: "transparent" }}>
            {" "}
            <div style={{ width: "70%", height: "70px", borderRadius: "10px" }}>
              <img style={{ width: "100%", borderRadius: "10px" }} src={p2} />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundColor: "transparent" }}>
            {" "}
            <div style={{ width: "70%", height: "70px", borderRadius: "10px" }}>
              <img style={{ width: "100%", borderRadius: "10px" }} src={p3} />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundColor: "transparent" }}>
            {" "}
            <div style={{ width: "70%", height: "70px", borderRadius: "10px" }}>
              <img style={{ width: "100%", borderRadius: "10px" }} src={p4} />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundColor: "transparent" }}>
            {" "}
            <div style={{ width: "70%", height: "70px", borderRadius: "10px" }}>
              <img style={{ width: "100%", borderRadius: "10px" }} src={P1} />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundColor: "transparent" }}>
            {" "}
            <div style={{ width: "70%", height: "70px", borderRadius: "10px" }}>
              <img style={{ width: "100%", borderRadius: "10px" }} src={p2} />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundColor: "transparent" }}>
            {" "}
            <div style={{ width: "70%", height: "70px", borderRadius: "10px" }}>
              <img style={{ width: "100%", borderRadius: "10px" }} src={p3} />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundColor: "transparent" }}>
            {" "}
            <div style={{ width: "70%", height: "70px", borderRadius: "10px" }}>
              <img style={{ width: "100%", borderRadius: "10px" }} src={p4} />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Bankcarousel;
