import React, { useEffect } from "react";
import "./Useraccountdetail.css";
import { useSelector, useDispatch } from "react-redux";
import { getAllData } from "../redux/Userdetail";

function Useraccountdetail() {
  const dispatch = useDispatch();
  
  const { users, loading, error } = useSelector((state) => state.Userdata);
  localStorage.setItem("Accountnumber", users.virtual_account)



  localStorage.setItem("Phone", users.phone )
  localStorage.setItem("email", users.email)

  const Name = users.name;

  localStorage.setItem('Name',Name);
  

  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-danger">Error: {error}</div>;
  }

  if (!users || users.length === 0) {
    return <div className="text-center">No data available.</div>;
  }



  return (
    <div className="child-divtwo">
      <div className="card-body">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th colspan="4">Your Account Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colspan="2" scope="row">
                Bank Name
              </th>
              <td>IDBI Bank Ltd.</td>
            </tr>
            <tr>
              <th colspan="2" scope="row">
                Account Name
              </th>
              <td>{users.name}</td>
            </tr>
            <tr>
              <th colspan="2" scope="row">
                Account Number
              </th>
              <td>{users.virtual_account}</td>
            </tr>
            <tr>
              <th colspan="2" scope="row">
                IFSC
              </th>
              <td>IBKL0002031</td>
            </tr>
            <tr>
              <th colspan="2" scope="row">
                Account Type
              </th>
              <td>Current Account</td>
            </tr>
            <tr>
              <th colspan="2" scope="row">
                Email
              </th>
              <td>{users.email}</td>
            </tr>
            <tr>
              <th colspan="2" scope="row">
                Phone
              </th>
              <td>{users.phone}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Useraccountdetail;
