// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
@media (max-width: 768px) {
    div[style*="flex-direction: row"] {
      flex-direction: column;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/admin/adminsidemenupages/Surcharge.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;IACI;MACE,sBAAsB;IACxB;EACF","sourcesContent":["/* App.css */\n@media (max-width: 768px) {\n    div[style*=\"flex-direction: row\"] {\n      flex-direction: column;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
