
import React, { useState, useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Back from "../assets/back.png";
import { format } from "date-fns";

const commonstyle = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  Datepickercontainer: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
    padding: "10px",
    justifyContent: "center",
    gap: 30,
  },
  Searchbutton: {
    marginLeft: "10px",
    height: "56px",
  },
  Exportbutton: {
    marginLeft: "10px",
    height: "56px",
  },
  Searchcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "60px",
  },
  Searchfield: {
    width: "70%",
    height: "45px",
    borderRadius: "15px",
    borderColor: "lightGray",
    paddingLeft: "20px",
  },
  DialogcontentText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: 30,
  },
  Excelbutton: {
    padding: "10px 40px",
    borderRadius: "10px",
  },
  Pdfbutton: {
    padding: "10px 50px",
    borderRadius: "10px",
  },
  Gridcontainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "210px",
    padding: "40px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
  },
};

const columns = [
  { id: "sno", label: "S.No", minWidth: 50 },
  { id: "memberId", label: "Member ID", minWidth: 100 },
  { id: "date", label: "Date", minWidth: 200 },
  { id: "narration", label: "Narration", minWidth: 100 },
  { id: "credit", label: "Credit", minWidth: 100 },
  { id: "debit", label: "Debit", minWidth: 100 },
  { id: "balance", label: "Balance", minWidth: 100 },
];

function createData(sno, memberId, date, narration, credit, debit, balance) {
  return { sno, memberId, date, narration, credit, debit, balance };
}

const Ewalletbyuser = ({ user_id, handleBackClick }) => {
  const userId = user_id.user_id;
  const { balance: initialBalance, memberId } = user_id;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [originalRows, setOriginalRows] = useState([]);


  const token = localStorage.getItem("adminAuthToken");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchInitialrow = () => {
    // Show the "Registration" row if no date range is selected or if the registration date falls within the selected date range
    const registrationDate = new Date(user_id.created_at);

    if (!fromDate || !toDate || (registrationDate >= fromDate && registrationDate <= toDate)) {
      return createData(
        0, // S.No (to be updated later)
        user_id.memberId, // Member ID
        user_id.created_at, // Date
        "Registration", // Narration
        0, // Credit
        0, // Debit
        0 // Balance
      );
    }
    return null; // Return null if conditions are not met
  };

  const fetchData = async () => {
    try {
      const allData = [];
      let currentPage = 1;

      // Format the date range
      const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : null;
      const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : null;

      // Fetch paginated data from the API
      do {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/transaction/${userId}`,
          {
            params: {
              page: currentPage,
              perPage: 100000,
              start_date: formattedStartDate,
              end_date: formattedEndDate,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { data: apiData, meta } = response.data;
        allData.push(...apiData);
        setTotalPages(meta.lastPage);  // Set total pages based on meta information
        currentPage = meta.next || currentPage + 1; // Move to the next page if available
      } while (currentPage <= totalPages); // Ensure loop stops when all pages are fetched

      // Process API data
      const transformedData = allData.map((item, index) => {
        const amount = parseFloat(item.amount) || 0;  // Convert to float, default to 0 if NaN
        const surchargeAmount = parseFloat(item.surchargeAmount) || 0; // Convert to float, default to 0 if NaN

        // const isCredit = ["CASH CREDIT", "UPI", "IMPS", "NEFT", "RTGS"].includes(item.mode);
        // Handle credit cases including refunded payouts and other credit modes
        const isCredit =
          (item.mode === "CASH CREDIT" ||
            ["UPI", "IMPS", "NEFT", "RTGS"].includes(item.mode) ||
            (item.type === "payout" && item.status === "REFUNDED")) && // Handle refunded payouts as credit
          (amount > 0 || surchargeAmount > 0); // Ensure there's a valid amount

        // const isDebit = item.mode === "CASH DEBIT" || item.type === "payout"; // Debit conditions
        const isDebit =
          (item.mode === "CASH DEBIT" || (item.type === "payout" && item.status !== "REFUNDED")); // Exclude refunded payouts


        // Debit amount calculation logic
        // const debitAmount = item.type === "payout"
        //   ? parseFloat(item.amount || 0) + parseFloat(item.surchargeAmount || 0) // Adding surchargeAmount if payout
        //   : isDebit
        //     ? parseFloat(item.tranAmt || 0)
        //     : 0;




        // Debit amount calculation logic
        const debitAmount =
          item.type === "payout" && item.status !== "REFUNDED" // Debit for non-refunded payouts
            ? amount + surchargeAmount // Adding surchargeAmount if payout
            : isDebit
              ? parseFloat(item.tranAmt || 0) // Default to 0 if tranAmt is NaN
              : 0;

        // Credit amount for refunded payouts or other credit modes like CASH CREDIT
        const creditAmount =
          (item.mode === "CASH CREDIT" || // Handle CASH CREDIT mode
            ["UPI", "IMPS", "NEFT", "RTGS"].includes(item.mode)) // Handle UPI, IMPS, NEFT, RTGS modes
            ? parseFloat(item.tranAmt || 0)  // Use sum of amount and surchargeAmount for credit
            : item.type === "payout" && item.status === "REFUNDED"  // Only for refunded payouts
              ? amount + surchargeAmount  // Use sum of amount and surchargeAmount for credit
              : isCredit
                ? parseFloat(item.tranAmt || 0) // For other types of credits
                : 0;


        const narration =
          item.type === "payout" && item.status !== "REFUNDED"  // Only for 'payout' type and status not 'REFUNDED'
            ? item.transactionReferenceNo
              ? `Payout First / ${item.transactionReferenceNo} / ${item.id}`  // If transactionReferenceNo exists
              : item.errorMessage.includes(":")
                ? `Payout First / ${item.errorMessage.split(":")[1]?.trim()} / ${item.id}`  // If errorMessage has a colon
                : `Payout First / ${item.errorMessage} / ${item.id}`  // If no colon in errorMessage
            : item.type === "payout" && item.status === "REFUNDED" // If type is 'payout' and status is 'REFUNDED'
              ? `Payout Refunded / ${item.paymentDescription} / ${item.transactionID}` // Add paymentDescription in the narration for refunded payouts
              : item.mode === "UPI"
                ? `Add Amt By UPI / ${item.utr}`
                : item.mode === "IMPS"
                  ? `Add Amt By virtual A/C / IMPS / ${item.Sender_receiver_info?.split("/")[1]}`
                  : item.mode === "NEFT"
                    ? `Add Amt By virtual A/C / NEFT / ${item.utr}`
                    : item.mode === "RTGS"
                      ? `Add Amt By virtual A/C / RTGS / ${item.utr}`
                      : item.mode === "CASH CREDIT"
                        ? `Admin - Add Fund - ${item.Sender_receiver_info}`
                        : item.mode === "CASH DEBIT"
                          ? `Admin - Deduct Fund - ${item.Sender_receiver_info}`
                          : item.Sender_receiver_info;



        return createData(
          index + 1,
          memberId.toUpperCase(),
          item.updated_at,
          narration,
          creditAmount,  // Correct credit amount logic
          debitAmount,   // Correct debit amount logic
          0 // Balance to be calculated
        );
      });

      const reversedData = transformedData.reverse();

      // Fetch the "Registration" row
      const balanceRow = fetchInitialrow();

      // Add the "Registration" row if there are no transactions or if it matches the date range
      const combinedData = balanceRow ? [balanceRow, ...reversedData] : reversedData;

      let runningBalance = 0;
      const updatedData = combinedData.map((row, index) => {
        if (index === 0) {
          row.balance = row.credit;
          runningBalance = parseFloat(row.balance);
        } else {
          runningBalance += parseFloat(row.credit) - parseFloat(row.debit);
          row.balance = runningBalance.toFixed(2);
        }
        return row;
      });

      const dataWithCorrectSerials = updatedData.map((row, index) => ({
        ...row,
        sno: index + 1,
      }));
      setOriginalRows(dataWithCorrectSerials);
      setData(dataWithCorrectSerials);
      setFilteredRows(dataWithCorrectSerials);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  useEffect(() => {
    const filtered = data.filter((row) => {
      if (!fromDate || !toDate) {
        return true; // Include all rows if no date range is selected
      }
      const rowDate = new Date(row.date);
      return rowDate >= fromDate && rowDate <= toDate;
    });

    const reversedAndRenumberedRows = filtered.reverse().map((row, index) => ({
      ...row,
      sno: index + 1, // Renumbering S.No from 1, 2, 3, etc.
    }));

    setFilteredRows(reversedAndRenumberedRows);
  }, [data, fromDate, toDate]);



  useEffect(() => {
    let filteredData = [...originalRows];
  
    if (searchQuery) {
      filteredData = filteredData
        .filter(
          (row) =>
            row.narration.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          // Prioritize matching rows
          if (
            a.narration.toLowerCase().includes(searchQuery.toLowerCase())
          )
            return -1;
          if (
            b.narration.toLowerCase().includes(searchQuery.toLowerCase())
          )
            return 1;
          return 0; // Maintain original order for non-matching rows
        });
    }
  
    // Reverse the filtered data
    filteredData = filteredData.reverse();
  
    // Now, reset the serial number (S.No) correctly in ascending order
    const updatedDataWithSerialNumbers = filteredData.map((row, index) => ({
      ...row,
      sno: index + 1, // Reset serial numbers in ascending order from 1
    }));
  
    setFilteredRows(updatedDataWithSerialNumbers);
  }, [searchQuery, originalRows]); // Run whenever searchQuery or originalRows changes
  
  


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
      <img
        onClick={() => handleBackClick()}
        style={{
          width: "60px",
          height: "60px",
          marginLeft: "10px",
          marginTop: "10px",
        }}
        alt=""
        src={Back}
      />
      <h4
        style={{
          fontWeight: "500",
          marginTop: "30px",
          paddingLeft: "20px",
          cursor: "pointer",
        }}
      >
        Ewallet  Balance
      </h4>
      <div
        style={{
          display: "flex",
          marginTop: "30px",
          alignItems: "center",
          padding: "10px",
          justifyContent: "center",
          gap: 30,
        }}
      >
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="From date"
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          selected={toDate}
          onChange={(date) => setToDate(date)}
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="To date"
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: "10px",
            height: "56px",
            backgroundColor: "red",
            borderRadius: "10px",
          }}
          onClick={fetchData}
        >
          Search
        </Button>
      </div>

      <div style={commonstyle.Searchcontainer}>
        <input
          type="text"
          style={commonstyle.Searchfield}
          placeholder="Search"
          value={searchQuery} // Bind the search query to the input value
          onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
        />
      </div>

      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "230px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                      {columns.map((column) => {
                        const value =
                          column.id === "date"
                            ? new Intl.DateTimeFormat("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true, // 24-hour format
                            }).format(new Date(row.date))  // Format date here
                            : row[column.id];
                        return (
                          <TableCell
                            style={{
                              color:
                                column.id === "credit"
                                  ? "green"
                                  : column.id === "debit"
                                    ? "red"
                                    : "black",
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </div>
  );
};

export default Ewalletbyuser;




