import React, { useEffect } from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CommonGroupaddicon from "../../commoncomponents/CommonGroupaddicon";
import { useSelector, useDispatch } from "react-redux";
import { getAllData } from "../../redux/Adminuserdata";
// import { getAllData } from "../redux/Adminuserdata";

// console.log(getAllData)
console.log(getAllData, "getdata");

const Adminhome = () => {
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector((state) => state.Adminuserdata);

  console.log(users.totalMembers, "users");

  const Totalmembers = users.totalMembers;
  const TotalBalance = users.totalBalance;
  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "darkred",
      ...theme.applyStyles("dark", {
        backgroundColor: "#308fe8",
      }),
    },
  }));

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap", // Enable wrapping for items
          gap: "10px",
          backgroundColor: "#fff",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          padding: 20,
        }}
      >
        {/* Card 1 */}
        <CommonGroupaddicon text={Totalmembers} />

        {/* Card 2 */}
        <CommonGroupaddicon
          text={TotalBalance}
          progressColor="#90EE90"
          styles={{ color: "#90EE90", fontWeight: "bold" }}
          stylesIcon={{
            width: "100px",
            height: "100px",
            backgroundColor: "#90EE90",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          footertext={"E Wallet Balance"}
        />

        {/* Card 3 */}
        <CommonGroupaddicon
          progressColor="#FFA07A"
          styles={{ color: "#FFA07A", fontWeight: "bold" }}
          stylesIcon={{
            width: "100px",
            height: "100px",
            backgroundColor: "#FFA07A",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          footertext={"Payout Wallet"}
        />

        {/* Card 4 */}
        <CommonGroupaddicon
          progressColor="#87CEFA"
          styles={{ color: "#87CEFA", fontWeight: "bold", marginTop: "40px" }}
          stylesIcon={{
            width: "100px",
            height: "100px",
            backgroundColor: "#87CEFA",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          footertext={"Pending Fundrequest"}
        />
      </div>
    </div>
  );
};

export default Adminhome;
