import React, { useState, useEffect } from "react";

function Qrcodepage() {
  // const adminToken = localStorage.getItem("adminAuthTokenstored");
  // const adminToken = localStorage.getItem("adminAuthTokenstored");
  const [qrCodeData, setQrCodeData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchQrCode = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/2fa/generate`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.ADMIN_TOKEN}`, // Replace with your token
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      setQrCodeData(data.qrCodeDataUrl);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQrCode();
  }, []);

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>QR Code Page</h1>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: "red" }}>Error: {error}</p>}
      {qrCodeData ? (
        <img
          src={qrCodeData}
          alt="QR Code"
          style={{ marginTop: "20px", width: "20%", height: "400px" }}
        />
      ) : (
        !loading && <p>No QR code available</p>
      )}
    </div>
  );
}

export default Qrcodepage;
