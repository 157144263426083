
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from '@mui/material/TextField';
import axios from "axios";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { TableVirtuoso } from "react-virtuoso";
import Button from "@mui/material/Button";
import { addSurcharge, getSurchargeDate } from "../payout/Payoutapi";

const style = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  Childrencontainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    backgroundColor: "#fff",
    boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
    padding: "20px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  DateRangecontainer: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    marginTop: "20px",
    flexWrap: "wrap",
  },
  StartRangecontainer: {
    flex: "1 1 20%",
    minWidth: "200px",
  },
  StartrangeLabel: {
    display: "block",
    marginBottom: "8px",
    fontWeight: "500",
  },
  Asterick: {
    color: "red",
    fontWeight: "bold",
    fontSize: "18px",
  },
  FieldContainer: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "10px",
  },
  Submitbuttoncontainer: {
    flex: "1 1 20%",
    minWidth: "200px",
    display: "flex",
    alignItems: "flex-end",
  },
  Buttonstyle: {
    padding: "10px 20px",
    backgroundColor: "orangered",
    color: "white",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
    width: "70%",
  },
  TablePaperstyle: {
    height: 200,
    width: "100%",
  },
  updateField: {
    padding: "3px",
    marginTop: "10px",
    borderRadius: "3px"
  },
  surchargeText: {
    fontSize: "18px"
  }
};
// const API_URL = "https://zevopay.online/api/v1/admin/surcharge";

const API_URL = `${process.env.REACT_APP_API_URL}/admin/surcharge`;
const BEARER_TOKEN = localStorage.getItem("adminAuthToken");
; // Replace with actual token


const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const columns = [
  { width: 200, label: "StartRange", dataKey: "startRange" },
  { width: 200, label: "EndRange", dataKey: "endRange" },
  { width: 200, label: "Surcharge", dataKey: "surcharge" },
  { width: 200, label: "Edit", dataKey: "edit" },
];

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          style={{ width: column.width }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

const VirtuosoTableComponents = {
  Scroller: React.forwardRef(({ style, ...props }, ref) => (
    <TableContainer
      component={Paper}
      {...props}
      ref={ref}
      style={{ ...style, maxHeight: 200, overflow: "auto" }} // Ensure max height for scrolling
    />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: React.forwardRef((props, ref) => (
    <TableHead
      {...props}
      ref={ref}
      style={{ position: "sticky", top: 0, background: "#fff", zIndex: 10 }} // Fix header position
    />
  )),
  TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function Payoutsurcharge() {
  const [formData, setFormData] = useState({ minBalance: "", maxBalance: "", commission: "" });
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({ minBalance: "", maxBalance: "", commission: "" });

  useEffect(() => {
    async function fetchSurchargeData() {
      try {
        const response = await getSurchargeDate();
        const data = Object.values(response.data).map((item) => ({
          id: item.id, // Ensure ID is present
          startRange: item.minBalance,
          endRange: item.maxBalance,
          surcharge: item.commission,
          edit: "",
        }));
        setRows(data.reverse());
      } catch (error) {
        console.error("Error fetching surcharge data:", error);
      }
    }
    fetchSurchargeData();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const payload = {
      minBalance: parseFloat(formData.minBalance),
      maxBalance: parseFloat(formData.maxBalance),
      commission: parseFloat(formData.commission),
    };

    try {
      await addSurcharge(payload);
      alert("Surcharge added successfully!");

      const newData = {
        startRange: formData.minBalance,
        endRange: formData.maxBalance,
        surcharge: formData.commission,
        edit: "",
      };

      setRows([newData, ...rows]);
      // Add new entry at the top
      setFormData({ minBalance: "", maxBalance: "", commission: "" });
    } catch (error) {
      console.error("Error adding surcharge:", error);
      alert("Failed to add surcharge");
    }
  };

  //  const handleChange = (e) => {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   };

  const handleEditChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };


  // Delete the entry of surcharge
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/${id}`, {
        headers: { Authorization: `Bearer ${BEARER_TOKEN}` },
      });
      alert("Surcharge deleted successfully!");
      setRows(rows.filter(row => row.id !== id));
    } catch (error) {
      console.error("Error deleting surcharge:", error);
      alert("Failed to delete surcharge");
    }
  };

  const handleOpen = (row) => {
    setEditData({
      id: row.id, // Ensure id is included
      minBalance: row.startRange,
      maxBalance: row.endRange,
      commission: row.surcharge,
    });
    setOpen(true);
  };


  console.log(editData.id, "idofuser")

  // update of entry of surcharge

  const handleUpdate = async () => {
    const payload = {
      id: editData.id,
      minBalance: parseFloat(editData.minBalance),
      maxBalance: parseFloat(editData.maxBalance),
      commission: parseFloat(editData.commission),
    };

    try {
      await axios.put(API_URL, payload, {
        headers: { Authorization: `Bearer ${BEARER_TOKEN}` },
      });
      alert("Surcharge updated successfully!");

      setRows(rows.map(row => row.id === payload.id ? {
        id: payload.id,
        startRange: payload.minBalance,
        endRange: payload.maxBalance,
        surcharge: payload.commission
      } : row));

      handleClose();
    } catch (error) {
      console.error("Error updating surcharge:", error);
      alert("Failed to update surcharge");
    }
  };


  const handleClose = () => setOpen(false);

  return (
    <div style={style.container}>
      <div style={style.Childrencontainer}>
        <h4>Manage Payout Surcharge</h4>
        <div style={style.DateRangecontainer}>
          <div style={style.StartRangecontainer}>
            <label style={style.StartrangeLabel}>Start Range <span style={style.Asterick}>*</span></label>
            <input type="text" name="minBalance" value={formData.minBalance} onChange={handleChange} placeholder="Start Range" style={style.FieldContainer} />
          </div>
          <div style={style.StartRangecontainer}>
            <label style={style.StartrangeLabel}>End Range <span style={style.Asterick}>*</span></label>
            <input type="text" name="maxBalance" value={formData.maxBalance} onChange={handleChange} placeholder="End Range" style={style.FieldContainer} />
          </div>
          <div style={style.StartRangecontainer}>
            <label style={style.StartrangeLabel}>Surcharge <span style={style.Asterick}>*</span></label>
            <input type="text" name="commission" value={formData.commission} onChange={handleChange} placeholder="Surcharge" style={style.FieldContainer} />
          </div>
          <div style={style.Submitbuttoncontainer}>
            <button style={style.Buttonstyle} onClick={handleSubmit}>Add / Update</button>
          </div>
        </div>
        <Paper style={{ height: 200, width: "100%", marginTop: '50px' }}>
          <TableVirtuoso data={rows} components={VirtuosoTableComponents} fixedHeaderContent={fixedHeaderContent} itemContent={(index, row) => (
            <>
              <TableCell>{row.startRange}</TableCell>
              <TableCell>{row.endRange}</TableCell>
              <TableCell>{row.surcharge}</TableCell>
              <TableCell>
                <Button onClick={() => handleOpen(row)} variant="contained" color="primary" size="small">Edit</Button>
                <Button onClick={() => handleDelete(row.id)} variant="contained" color="secondary" size="small" style={{ marginLeft: "10px" }}>Delete</Button>
              </TableCell>
            </>
          )} />
        </Paper>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title">
          <Box sx={styleModal}>
            <h2>Edit Surcharge</h2>
            <TextField fullWidth label="Start Range" variant="outlined" name="minBalance" value={editData.minBalance} onChange={handleEditChange} margin="normal" />
            <TextField fullWidth label="End Range" variant="outlined" name="maxBalance" value={editData.maxBalance} onChange={handleEditChange} margin="normal" />
            <TextField fullWidth label="Surcharge" variant="outlined" name="commission" value={editData.commission} onChange={handleEditChange} margin="normal" />

            <Button variant="contained" onClick={handleUpdate} color="primary" style={{ marginTop: "20px" }}>
              Update
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default Payoutsurcharge;
































































