import React, { useEffect } from "react";
import Commontransition from "../commoncomponents/Commontransition";
import Dummy from "../assets/dummy.png";
import { useSelector, useDispatch } from "react-redux";
import { getAllData } from "../redux/Userdetail";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Backdrop,
  Button,
  Typography,
  FormControl,
  CircularProgress,
  InputLabel,
  MenuItem,
} from "@mui/material";

function Profile() {
  const { users, loading, error } = useSelector((state) => state.Userdata);
  console.log(loading, "loading");
  console.log(users, "usersalldata")
  console.log(users.imageUrl, "usersdata");
  const dispatch = useDispatch();

  const [age, setAge] = React.useState("India");
  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#F8F8FB";

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Revert back to default background color
    };
  }, []);

  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const Name = localStorage.getItem("Name") || "";
  const nameParts = Name.split(" "); // Split based on the space between first and last name
  const FirstName = nameParts[0] || ""; // First part of the name (FirstName)
  const LastName = nameParts[1] || ""; // Second part of the name (LastName)
  const Phonenumber = localStorage.getItem("Phone") || "";
  const Email = localStorage.getItem("email") || "";
  const Aadhar = users?.aadharNumber || "";
  const Pan = users?.panNumber || "";
  const BussinessPannumber = users?.businessPanNo || "";
  const ShopName = users?.shopName || "";
  const ShopAddress = users?.shopAddress || "";
  const GstNumber = users?.gstNumber || "";
  const Address = users?.address || "";
  const Country = users?.country || "";
  const State = users?.state || "";
  const LandlineStDCode = users?.landlineSTDCode || "";
  console.log(LandlineStDCode, "LandlineStDCode")
  const LandlineNumber = users?.landlineNumber || "";

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Commontransition
        text={"Manage Member Master"}
        transition={"Manage Member Master"}
        onClick={() => window.location.reload()}
      />
      {/* Loader */}
      <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Responsive Grid with Cards */}
      <Grid
        container
        spacing={6}
        sx={{
          marginTop: "20px",
          padding: "20px",
        }}
      >
        {/* Left Card */}
        <Grid item xs={12} sm={6}>
          <Card
            sx={{
              backgroundColor: "#fff",
              boxShadow: "2px 2px 5px rgba(0,0,0,0.2)",
              height: "300px",
            }}
          >
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <div
                style={{
                  padding: "80px",
                  background:
                    "linear-gradient(90deg, #ff8300 0%, #FF6000 100%)",
                  boxSizing: "border-box", // Include padding in the total width
                }}
              >
                {/* Content can go here */}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    position: "absolute", // Position absolute to overlap the gradient
                    top: "140px",
                    overflow: "hidden", // Ensure the image does not overflow the circle,
                    width: "150px", // Set the width of the circle
                    height: "150px", // Set the height of the circle
                    backgroundColor: "white", // Circle background color
                    borderRadius: "50%", // Make the div circular
                    display: "flex", // Center the image within the circle
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)", // Optional shadow
                  }}
                >
                  <img
                    src={users.imageUrl ? users.imageUrl : Dummy} // Replace with your image URL
                    alt="Circular"
                    style={{
                      width: "100%", // Make image take the full width
                      height: "100%", // Make image take the full height
                      objectFit: "cover", // Maintain aspect ratio while covering the div
                      borderRadius: "50%", // Make the image circular
                    }}
                  />
                </div>
                <h3 style={{ marginLeft: "190px", marginTop: "40px" }}>
                  {Name}
                </h3>
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* Right Card */}
        <Grid item xs={12} sm={6}>
          <Card

            sx={{
              backgroundColor: "#fff",
              boxShadow: "2px 2px 5px rgba(0,0,0,0.2)",
            }}
          >
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#F3F2F3",
                  padding: "30px",
                }}
              >
                <span
                  style={{
                    color: "#353535",
                    fontSize: "25px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Member Type * Retailer{" "}
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                    marginTop: "10px",
                  }}
                >
                  <span
                    style={{
                      color: "#353535",
                      fontSize: "25px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Package
                  </span>
                  <span>*</span>
                  <FormControl style={{ width: "30%" }} variant="outlined">
                    <InputLabel id="special-package-label">
                      SPECIAL PACKAGE
                    </InputLabel>
                    <Select
                      labelId="special-package-label"
                      id="special-package-select"
                      label="SPECIAL PACKAGE"
                      defaultValue=""
                    >
                      {/* No options inside the dropdown */}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Personal Info Grid 1 */}
      <Grid
        container
        sx={{
          marginLeft: "20px",
          display: "flex",
          flexDirection: "column",
          width: "98%",
          marginTop: "20px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h3 style={{ fontSize: "35px" }}>Personal Info</h3>
        <Grid container spacing={7} style={{ marginTop: "1px" }}>
          {/* Row 1 */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-1"
              label={
                <span style={{ color: "black" }}>
                  First Name <span style={{ color: "red", fontSize: "20px", fontWeight: "bolder" }}>*</span>
                </span>
              }
              defaultValue={FirstName}
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />

          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-2"
              label={
                <span style={{ color: "black" }}>
                  Last Name <span style={{ color: "red", fontSize: "20px", fontWeight: "bolder" }}>*</span>
                </span>
              }
              defaultValue={LastName}
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-2"
              label={
                <span style={{ color: "black" }}>
                  Email ID <span style={{ color: "red", fontSize: "20px", fontWeight: "bolder" }}>*</span>
                </span>
              }
              defaultValue={Email}
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-3"
              label={
                <span style={{ color: "black" }}>
                  Aadhar <span style={{ color: "red", fontSize: "20px", fontWeight: "bolder" }}>*</span>
                </span>
              }
              value={Aadhar} // Ensure empty string instead of undefined
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>

          {/* Row 2 */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-4"
              label="Pan"
              value={Pan}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-5"
              label="Business PAN Number"
              value={BussinessPannumber}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-6"
              label="Shop Name"
              value={ShopName}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={10} style={{ marginTop: "20px" }}>
          {/* Row 1 */}
          <Grid item xs={12} sm={6}>
            <TextField
              id="additional-info-input-7"
              label="Shop Address"
              value={ShopAddress}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="additional-info-input-8"
              label="GST Number"
              value={GstNumber}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
              fullWidth
            />
          </Grid>

          {/* Row 2 */}
          <Grid item xs={12} sm={6}>
            <TextField
              id="additional-info-input-9"
              label={
                <span style={{ color: "black" }}>
                  Address <span style={{ color: "red", fontSize: "20px", fontWeight: "bolder" }}>*</span>
                </span>
              }
              variant="standard"
              value={Address}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="additional-info-input-10"
              label="Country"
              variant="standard"
              value={Country}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          {/* Row 3 */}
          <Grid item xs={12} sm={6}>
            <TextField
              id="additional-info-input-11"
              label="State"
              variant="standard"
              value={State}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

        </Grid>

        <Grid container spacing={7} style={{ marginTop: "1px" }}>
          {/* Row 1 */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-12"
              label="Phone"
              defaultValue={`${Phonenumber}`}
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-13"
              label="Landline STDCode"
              value={LandlineStDCode}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="standard-read-only-input-14"
              label="Landline Number"
              InputProps={{
                readOnly: true,
              }}
              value={LandlineNumber}
              variant="standard"
              fullWidth
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          style={{
            padding: "20px",
            width: "140px",
            marginTop: "30px",
            backgroundColor: "#083090",
          }}
        >
          Submit
        </Button>
      </Grid>
    </div>
  );
}

export default Profile;
