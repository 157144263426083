// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  color: white; /* Change the color to suit your background */
  right: 10px; /* Adjust position */
  left: auto;
}

.swiper-button-prev {
  left: 10px; /* Adjust position */
  right: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Clientswiper.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;;EAEE,YAAY,EAAE,6CAA6C;EAC3D,WAAW,EAAE,oBAAoB;EACjC,UAAU;AACZ;;AAEA;EACE,UAAU,EAAE,oBAAoB;EAChC,WAAW;AACb","sourcesContent":["/* Swiper navigation buttons */\r\n.swiper-button-next,\r\n.swiper-button-prev {\r\n  color: white; /* Change the color to suit your background */\r\n  right: 10px; /* Adjust position */\r\n  left: auto;\r\n}\r\n\r\n.swiper-button-prev {\r\n  left: 10px; /* Adjust position */\r\n  right: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
