import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import Aeps from "../assets/aeps.png";
import Ruppee from "../assets/ruppee.png";
import Xpress from "../assets/xpress-payout.png";
import Recharge from "../assets/recharge.png";
import Token from "../assets/token.png";
import Banner from "../assets/banner.jpg";
import Dmr from "../assets/dmr.png";
import Mapview from "../assets/map.jpg";
// import "./Homefootersection.css";
import Switch from "@mui/material/Switch";

function Homefootersection() {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <div style={{ padding: "20px" }}>
      <Grid container spacing={4}>
        {/* First Grid containing 6 small cards */}
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent style={{ borderRadius: "10px" }}>
              <Typography
                variant="h5"
                component="div"
                style={{ color: "#495057" }}
              >
                Services
              </Typography>

              {/* Container Grid for manually added small cards */}
              <Grid container spacing={2} style={{ marginTop: "16px" }}>
                {/* First row */}
                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardContent
                      style={{
                        display: "flex",
                        padding: "0px, 70px",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottomRightRadius: "5px",
                        borderTopRightRadius: "5px",
                        // justifyContent:'center',
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40px", height: "40px" }}>
                          <img style={{ width: "100%" }} src={Aeps} />
                        </div>
                        <h5
                          style={{
                            fontWeight: "600",
                            color: "2A3036",
                            fontSize: "16px",
                          }}
                        >
                          AEPS
                        </h5>
                      </div>

                      <Switch
                        sx={{
                          "& .Mui-checked + .MuiSwitch-track": {
                            backgroundColor: "red",
                            width: "100px",
                            // Track color when switch is checked
                          },
                        }}
                        style={{ color: "#FF4A00" }}
                        {...label}
                        defaultChecked
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardContent
                      style={{
                        display: "flex",
                        padding: "0px, 70px",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottomRightRadius: "5px",
                        borderTopRightRadius: "5px",
                        // justifyContent:'center',
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40px", height: "40px" }}>
                          <img style={{ width: "100%" }} src={Ruppee} />
                        </div>
                        <h5
                          style={{
                            fontWeight: "600",
                            color: "2A3036",
                            fontSize: "16px",
                          }}
                        >
                          Payout
                        </h5>
                      </div>

                      <Switch
                        sx={{
                          "& .Mui-checked + .MuiSwitch-track": {
                            backgroundColor: "red",
                            width: "100px",
                            // Track color when switch is checked
                          },
                        }}
                        style={{ color: "#FF4A00" }}
                        {...label}
                        defaultChecked
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* Second row */}
                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardContent
                      style={{
                        display: "flex",
                        padding: "0px, 70px",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottomRightRadius: "5px",
                        borderTopRightRadius: "5px",
                        // justifyContent:'center',
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40px", height: "40px" }}>
                          <img style={{ width: "100%" }} src={Xpress} />
                        </div>
                        <h5
                          style={{
                            fontWeight: "600",
                            color: "2A3036",
                            fontSize: "16px",
                          }}
                        >
                          Xpress Payout
                        </h5>
                      </div>

                      <Switch
                        sx={{
                          "& .Mui-checked + .MuiSwitch-track": {
                            backgroundColor: "red",
                            width: "100px",
                            // Track color when switch is checked
                          },
                        }}
                        style={{ color: "#FF4A00" }}
                        {...label}
                        defaultChecked
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardContent
                      style={{
                        display: "flex",
                        padding: "0px, 70px",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottomRightRadius: "5px",
                        borderTopRightRadius: "5px",
                        // justifyContent:'center',
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40px", height: "40px" }}>
                          <img style={{ width: "100%" }} src={Recharge} />
                        </div>
                        <h5
                          style={{
                            fontWeight: "600",
                            color: "2A3036",
                            fontSize: "16px",
                          }}
                        >
                          Recharge
                        </h5>
                      </div>

                      <Switch
                        sx={{
                          "& .Mui-checked + .MuiSwitch-track": {
                            backgroundColor: "red",
                            width: "100px",
                            // Track color when switch is checked
                          },
                        }}
                        style={{ color: "#FF4A00" }}
                        {...label}
                        defaultChecked
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* Third row */}
                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardContent
                      style={{
                        display: "flex",
                        padding: "0px, 70px",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottomRightRadius: "5px",
                        borderTopRightRadius: "5px",
                        // justifyContent:'center',
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40px", height: "40px" }}>
                          <img style={{ width: "100%" }} src={Token} />
                        </div>
                        <h5
                          style={{
                            fontWeight: "600",
                            color: "2A3036",
                            fontSize: "16px",
                          }}
                        >
                          Bill Payment
                        </h5>
                      </div>

                      <Switch
                        sx={{
                          "& .Mui-checked + .MuiSwitch-track": {
                            backgroundColor: "red",
                            width: "100px",
                            // Track color when switch is checked
                          },
                        }}
                        style={{ color: "#FF4A00" }}
                        {...label}
                        defaultChecked
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardContent
                      style={{
                        display: "flex",
                        padding: "0px, 70px",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottomRightRadius: "5px",
                        borderTopRightRadius: "5px",
                        // justifyContent:'center',
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40px", height: "40px" }}>
                          <img style={{ width: "100%" }} src={Dmr} />
                        </div>
                        <h5
                          style={{
                            fontWeight: "600",
                            color: "2A3036",
                            fontSize: "16px",
                          }}
                        >
                          Money transfer
                        </h5>
                      </div>

                      <Switch
                        sx={{
                          "& .Mui-checked + .MuiSwitch-track": {
                            backgroundColor: "red",
                            width: "100px",
                            // Track color when switch is checked
                          },
                        }}
                        style={{ color: "#FF4A00" }}
                        {...label}
                        defaultChecked
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Second Card */}
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent style={{ height: "430px" }}>
              <div
                id="carouselExampleAutoplaying"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={Mapview} className="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={Banner} className="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={Mapview} className="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={Banner} className="d-block w-100" alt="..." />
                  </div>
                </div>
                <button
                  className="carousel-control-prev  custom-carousel-button"
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying"
                  data-bs-slide="prev"
                >
                  <span>Prev</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying"
                  data-bs-slide="next"
                >
                  <span>Next</span>
                </button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default Homefootersection;
