import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { getSubadminData } from '../api/Api';
import Subadminspecificmembers from './Subadminspecificmembers';

const columns = [
    { id: 'sno', label: 'S.No', minWidth: 50 },
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'phone', label: 'Phone', minWidth: 150 },
    { id: 'email', label: 'Email', minWidth: 200 },
    { id: 'permissions', label: 'Permissions', minWidth: 250 },
    { id: 'date', label: 'Date', minWidth: 170 },
    { id: 'view', label: 'View', minWidth: 100 },
];

function Subadminmembers() {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showTable, setShowTable] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50000);

    useEffect(() => {
        getSubadminData()
            .then((response) => {
                const dataArray = Object.values(response.data);
                const data = dataArray.map((item) => ({
                    id: item.id,
                    name: item.name || 'N/A',
                    phone: item.phone || 'N/A',
                    email: item.email || 'N/A',
                    permissions: formatPermissions(item.permissions),
                    date: formatDate(item.updatedAt),
                }));

                // Reverse the data to ensure the latest entries are at the top
                const reversedData = data.reverse();

                // Assign correct serial number (sno) after reversing
                const updatedRows = reversedData.map((item, index) => ({
                    ...item,
                    sno: index + 1, // Correct serial number after reversing
                }));

                setRows(updatedRows);
                setFilteredRows(updatedRows); // Reverse the filtered rows as well
            })
            .catch((error) => {
                console.error('Error fetching subadmin data', error);
            });
    }, []);

    const formatPermissions = (permissions) => {
        if (!permissions || typeof permissions !== 'object') {
            return 'No Permissions';
        }

        return Object.entries(permissions)
            .map(([key, values]) => {
                const formattedValues = Array.isArray(values) ? values.join(', ') : 'Invalid Permissions';
                return `${key}: ${formattedValues}`;
            })
            .join('; ');
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = rows.filter(
            (row) =>
                row.name.toLowerCase().includes(term) ||
                row.phone.toLowerCase().includes(term) ||
                row.email.toLowerCase().includes(term)
        );
        setFilteredRows(filtered);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleViewClick = (id) => {
        setSelectedUserId(id); // Store the selected user's ID
        setShowTable(false);
    };

    const handleBackClick = () => {
        setShowTable(true);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.5)' }}>
            {showTable ? (
                <>
                    <span
                        style={{
                            paddingLeft: '20px',
                            color: 'gray',
                            fontWeight: 'bold',
                            marginTop: '20px',
                            fontSize: '25px',
                        }}
                    >
                        Subadmin List
                    </span>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            marginTop: "90px",
                            width: "90%",
                            margin: "0 auto",
                        }}
                    >
                        <label
                            style={{
                                marginBottom: "5px",
                                fontSize: "18px",
                                color: "black",
                            }}
                        >
                            Search *
                        </label>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearch}
                            style={{
                                width: "100%",
                                height: "45px",
                                borderRadius: "15px",
                                borderColor: "lightGray",
                                paddingLeft: "20px",
                            }}
                            placeholder="Search subadmins by Name, Phone, or Email"
                        />
                    </div>

                    <Paper style={{ width: '100%', overflow: 'hidden', marginTop: "90px" }}>
                        <TableContainer style={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.id === 'view' ? 'center' : 'left'}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredRows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.id === 'view' ? 'center' : 'left'}>
                                                            {column.id === 'view' ? (
                                                                <Button
                                                                    onClick={() => handleViewClick(row.id)}
                                                                    style={{
                                                                        borderColor: "red",
                                                                        backgroundColor: "#fff",
                                                                        color: "green",
                                                                        borderWidth: "10px",
                                                                    }}
                                                                    variant="contained"
                                                                    color="primary"
                                                                >
                                                                    View
                                                                </Button>
                                                            ) : (
                                                                value
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100, 1000 , 50000]}
                            component="div"
                            count={filteredRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </>
            ) : (
                <Subadminspecificmembers
                    handleBackClick={handleBackClick}
                    userId={selectedUserId} // Pass selected user's ID to the Subadminspecificmembers component
                />
            )}
        </div>
    );
}

export default Subadminmembers;
