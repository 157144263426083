// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qrcode-img {
  width: 100%;
  height: auto;
  max-height: 700px;
  object-fit: contain;
}

.child-div, .child-divone {
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}



.child-divone {
  background-color: #fff;
}

@media (max-width: 600px) {
  .child-div, .child-divone {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Menutabs/Addmoney.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,0CAA0C;EAC1C,kBAAkB;AACpB;;;;AAIA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".qrcode-img {\n  width: 100%;\n  height: auto;\n  max-height: 700px;\n  object-fit: contain;\n}\n\n.child-div, .child-divone {\n  padding: 10px;\n  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);\n  border-radius: 5px;\n}\n\n\n\n.child-divone {\n  background-color: #fff;\n}\n\n@media (max-width: 600px) {\n  .child-div, .child-divone {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
