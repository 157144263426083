import React, { useEffect, useState } from "react";
import Commontransition from "../commoncomponents/Commontransition";
import {
  Grid,
  Button,
  TableBody,
  Table,
  Paper,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
} from "@mui/material";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";

const columns = [
  { id: "sn", label: "S.N.", minWidth: 50 },
  { id: "memberId", label: "MemberID", minWidth: 100 },
  { id: "date", label: "Date", minWidth: 100 },
  { id: "narration", label: "Narration", minWidth: 100 },
  { id: "credit", label: "Credit", minWidth: 100, },
  { id: "debit", label: "Debit", minWidth: 100, },
  { id: "balance", label: "Balance", minWidth: 100 },
];

// Table with no data, single row
const rows = [
  {
    sn: "No data available",
    memberId: "",
    date: "",
    narration: "",
    credit: "",
    debit: "",
    balance: "",
  },
];

function Rwalletreport() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#F8F8FB";

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Revert back to default background color
    };
  }, []);

  // Function to handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Function to handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Commontransition text={"Rwallet Report"} transition={"Rwallet"}  onClick={() => window.location.reload()} />
      <Grid
        container
        sx={{
          marginTop: "20px",
          gap: "20px",
          padding: "40px",
          justifyContent: "center",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div
          style={{
            display: "flex",
            marginTop: "30px",
            alignItems: "center",
            padding: "10px",
            justifyContent: "center",
            gap: 30,
          }}
        >
          <DatePicker
            // selected={fromDate}
            // onChange={(date) => setFromDate(date)}
            dateFormat="yyyy/MM/dd"
            isClearable
            placeholderText="From date"
            customInput={<TextField label="From date" variant="outlined" />}
          />
          <DatePicker
            // selected={toDate}
            // onChange={(date) => setToDate(date)}
            dateFormat="yyyy/MM/dd"
            isClearable
            placeholderText="To date"
            customInput={<TextField label="To date" variant="outlined" />}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px", height: "56px" }}
            // onClick={fetchData}
          >
            Search
          </Button>
        </div>
        <Grid
          container
          sx={{
            marginTop: "20px",
            //   gap: "20px",
            padding: "25px",
            //   justifyContent:"center",
            borderRadius: "5px",
            backgroundColor: "#F8F8FB",
            //   boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
          }}
        >
          <input
            style={{
              width: "98%",
              padding: "15px",
              borderColor: "#fff",
            }}
            placeholder="Search"
          />
        </Grid>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color:"black", fontSize:"20px", fontWeight:"500" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rowIndex}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </div>
  );
}

export default Rwalletreport;
