import React from "react";
import "./Card.css";

function Earningcontainer() {
  return (
    <div className="child-divone">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            paddingLeft: "20px",
            paddingTop: "20px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <header
            style={{
              color: "#495057",
              fontSize: "27px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            Total Earning
          </header>
          <span style={{ color: "#495057", paddingRight: "10px", fontWeight:"bold" }}>0.00</span>
        </div>
        <div className="line"></div>
        <div
          style={{
            display: "flex",
            paddingLeft: "20px",
            paddingTop: "20px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <header
            style={{
              color: "#495057",
              fontSize: "27px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            Total Earning
          </header>
          <span style={{ color: "#495057", paddingRight: "10px", fontWeight:"bold" }}>0.00</span>
        </div>
        <div className="line"></div>
        <div
          style={{
            display: "flex",
            paddingLeft: "20px",
            paddingTop: "20px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <header
            style={{
              color: "#495057",
              fontSize: "27px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            WeeklyEarning
          </header>
          <span style={{ color: "#495057", paddingRight: "10px", fontWeight:"bold" }}>0.00</span>
        </div>
        <div className="line"></div>
        <div
          style={{
            display: "flex",
            paddingLeft: "20px",
            paddingTop: "20px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <header
            style={{
              color: "#495057",
              fontSize: "27px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            MonthlyEarning
          </header>
          <span style={{ color: "#495057", paddingRight: "10px", fontWeight:"bold" }}>0.00</span>
        </div>
        <div className="line"></div>
      </div>
    </div>
  );
}

export default Earningcontainer;
