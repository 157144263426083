import React from 'react';
import Commonpage from '../components/Commonpage';

function Career() {
  return (
    < >
    <Commonpage/>
   </>
  )
}

export default Career