import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getAllData } from "../redux/Userdetail";
import Rwalletreport from "./Rwalletreport";

function Aepswalletsummary() {
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector((state) => state.Userdata);
  const [viewDetail, setViewDetail] = useState(false); // State to toggle view

  useEffect(() => {
    document.body.style.backgroundColor = "#F8F8FB";
    return () => {
      document.body.style.backgroundColor = ""; // Revert to default background color on unmount
    };
  }, []);

  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

  const handleViewClick = () => {
    setViewDetail(true); // Toggle view to detail page
  };

  if (viewDetail) {
    // Render detail page
    return (
     <Rwalletreport/>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3 style={{ color: "#666666", marginTop: "30px" }}>
        AEPS Wallet Balance Ledger
      </h3>
      <Grid
        container
        sx={{
          marginTop: "20px",
          gap: "20px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            borderRadius: "5px",
            paddingBottom: "40px",
            backgroundColor: "#fff",
            boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
          }}
        >
          <span style={{ color: "#666666", fontSize: "20px" }}>Search</span>
          <input
            style={{
              width: "98%",
              padding: "10px",
            }}
            placeholder="Search"
          />
        </Grid>

        {/* Table section */}
        <TableContainer component={Paper} sx={{ marginTop: "30px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell style={{ fontSize: "20px" }}>MemberID</TableCell>
                <TableCell style={{ fontSize: "20px" }}>MemberName</TableCell>
                <TableCell style={{ fontSize: "20px" }}>Debit</TableCell>
                <TableCell style={{ fontSize: "20px" }}>Credit</TableCell>
                <TableCell style={{ fontSize: "20px" }}>Balance</TableCell>
                <TableCell style={{ fontSize: "20px" }}>Detail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ fontSize: "20px" }}>1</TableCell>
                <TableCell style={{ fontSize: "20px", color: "black" }}>
                  {users.userId ? users.userId : "undefined"}
                </TableCell>
                <TableCell style={{ fontSize: "20px", color: "black" }}>
                  {users.name ? users.name : "undefined"}
                </TableCell>
                <TableCell style={{ color: "red", fontSize: "20px" }}>0.00</TableCell>
                <TableCell style={{ color: "green", fontSize: "20px" }}>0.00</TableCell>
                <TableCell style={{ fontSize: "20px" }}>0.00</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#083090", padding: "15px" }}
                    onClick={handleViewClick}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
}

export default Aepswalletsummary;
