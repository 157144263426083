// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  }
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Carousel.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd;EACA;;IAEE,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,gBAAgB;IAChB,yDAAyD;IACzD,eAAe;IACf,WAAW;IACX,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,gBAAgB;;IAEhB,iCAAiC;IACjC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB","sourcesContent":["#app {\n    height: 100%;\n  }\n  html,\n  body {\n    position: relative;\n    height: 100%;\n  }\n  \n  body {\n    background: #eee;\n    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;\n    font-size: 14px;\n    color: #000;\n    margin: 0;\n    padding: 0;\n  }\n  \n  .swiper {\n    width: 100%;\n    height: 100%;\n  }\n  \n  .swiper-slide {\n    text-align: center;\n    font-size: 18px;\n    background: #fff;\n  \n    /* Center slide text vertically */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .swiper-slide img {\n    display: block;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
