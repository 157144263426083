import React from "react";
import Backgroundseen from "../assets/backgroundseen.jpg";
import Men from "../assets/men.png";
import Bank from "../assets/bank.png";
import Fogg from "../assets/fogg.png";
import Post from "../assets/post.png";
import "./Homeuserdetail.css";

function Homeuserdetail() {
  return (
    <div
      className="background-container"
      style={{
        backgroundImage: `url(${Backgroundseen})`,
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginTop: "-100px" }}>Our Achieved</h1>

        {/* Container for all the divs */}
        <div
          style={{
            display: "flex",
            gap: 170,
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "80px",
          }}
        >
          <div className="item-container">
            <img src={Men} style={{ width: "80px", height: "80px" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  position: "relative",
                  color: "#fff",
                  fontSize: "30px",
                  fontWeight: "bold",
                }}
              >
                3000
                <span
                  style={{
                    position: "absolute",
                    top: "-9px",
                    fontSize: "18px",
                    left: "73px", // Adjust to position the `+` above the number
                  }}
                >
                  +
                </span>
              </span>
              <div
                style={{
                  backgroundColor: "#fff",
                  width: "80px",
                  height: "4px",
                }}
              ></div>
              <span style={{ color: "#fff", fontSize: "22px" }}>Retailers</span>
            </div>
          </div>
          <div className="item-container">
            <img src={Bank} style={{ width: "80px", height: "80px" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  position: "relative",
                  color: "#fff",
                  fontSize: "30px",
                  fontWeight: "bold",
                }}
              >
                160
                <span
                  style={{
                    position: "absolute",
                    top: "-9px",
                    fontSize: "18px",
                    left: "60px", // Adjust to position the `+` above the number
                  }}
                >
                  +
                </span>
              </span>
              <div
                style={{
                  backgroundColor: "#fff",
                  width: "80px",
                  height: "4px",
                }}
              ></div>
              <span style={{ color: "#fff", fontSize: "22px" }}>Retailers</span>
            </div>
          </div>
          <div className="item-container">
            <img src={Fogg} style={{ width: "80px", height: "80px" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  position: "relative",
                  color: "#fff",
                  fontSize: "30px",
                  fontWeight: "bold",
                }}
              >
                50
                <span
                  style={{
                    position: "absolute",
                    top: "-9px",
                    fontSize: "18px",
                    left: "40px", // Adjust to position the `+` above the number
                  }}
                >
                  +
                </span>
              </span>
              <div
                style={{
                  backgroundColor: "#fff",
                  width: "80px",
                  height: "4px",
                }}
              ></div>
              <span style={{ color: "#fff", fontSize: "22px" }}>Retailers</span>
            </div>
          </div>
          <div className="item-container">
            <img src={Post} style={{ width: "80px", height: "80px" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  position: "relative",
                  color: "#fff",
                  fontSize: "30px",
                  fontWeight: "bold",
                }}
              >
                200
                <span
                  style={{
                    position: "absolute",
                    top: "-9px",
                    fontSize: "18px",
                    left: "50px", // Adjust to position the `+` above the number
                  }}
                >
                  +
                </span>
              </span>
              <div
                style={{
                  backgroundColor: "#fff",
                  width: "80px",
                  height: "4px",
                }}
              ></div>
              <span style={{ color: "#fff", fontSize: "22px" }}>Retailers</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homeuserdetail;
