
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import * as XLSX from "xlsx";
import Commontransition from "../commoncomponents/Commontransition";
import "../commoncomponents/Commoncalender.css";
import Api from "../apiconfig/Api";

const columns = [
  { id: "sno", label: "S.No", minWidth: 50 },
  { id: "memberId", label: "Member ID", minWidth: 100 },
  { id: "date", label: "Date", minWidth: 200 },
  { id: "narration", label: "Narration", minWidth: 100 },
  { id: "credit", label: "Credit", minWidth: 100 },
  { id: "debit", label: "Debit", minWidth: 100 },
  { id: "balance", label: "Balance", minWidth: 100 },
];

function createData(sno, memberId, date, narration, credit, debit, balance) {
  return { sno, memberId, date, narration, credit, debit, balance };
}

const Transactiontable = ({ user_id }) => {
  const token = localStorage.getItem("authToken");
  const UserId = localStorage.getItem("UserId");
  const balance = localStorage.getItem("Balance");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const url = Api.GET_BALANCE_URL;

  const fetchBalance = async () => {
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const balanceData = await response.json();

      const balanceRow = createData(
        0,
        UserId.toUpperCase(),
        balanceData.created_at,
        "Registration",
        0,
        0,
        0
      );

      return balanceRow;
    } catch (error) {
      console.error("Error fetching balance data:", error);
      return null;
    }
  };

  const fetchData = async () => {
    try {
      const formattedStartDate = startDate
        ? format(startDate, "yyyy-MM-dd")
        : null;
      const formattedEndDate = endDate ? format(endDate, "yyyy-MM-dd") : null;

      const [transactionResponse, balanceRow] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/wallet/transactions`, {
          params: {
            page: 1,
            perPage: 10,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        fetchBalance(),
      ]);

      const { data: apiData } = transactionResponse.data;

      const transformedData = apiData.map((item) => {
        const isCredit =
          item.mode === "CASH CREDIT" ||
          ["UPI", "IMPS", "NEFT", "RTGS"].includes(item.mode);
        const isDebit = item.mode === "CASH DEBIT";

        const narration =
          item.mode === "UPI"
            ? `Add Amt By UPI / ${item.utr}`
            : item.mode === "IMPS"
              ? `Add Amt By virtual A/C / IMPS / ${item.Sender_receiver_info.split("/")[1]
              }`
              : item.mode === "NEFT"
                ? `Add Amt By virtual A/C / NEFT / ${item.utr}`
                : item.mode === "RTGS"
                  ? `Add Amt By virtual A/C / RTGS / ${item.utr}`
                  : item.Sender_receiver_info;

        return createData(
          0,
          UserId.toUpperCase(),
          item.updated_at,
          narration,
          isCredit ? item.tranAmt : 0,
          isDebit ? item.tranAmt : 0,
          0
        );
      });

      const reversedData = transformedData.reverse();

      let runningBalance = parseFloat(balance || 0);

      const updatedData = reversedData.map((row, index) => {
        row.balance = runningBalance.toFixed(2);
        runningBalance -= parseFloat(row.credit);
        runningBalance += parseFloat(row.debit);
        row.sno = index + 1;
        return row;
      });

      if (balanceRow) {
        const balanceDate = new Date(balanceRow.date);
        const isWithinRange =
          (!startDate || balanceDate >= startDate) &&
          (!endDate || balanceDate <= endDate);

        if (isWithinRange) {
          balanceRow.sno = updatedData.length + 1;
          updatedData.push(balanceRow);
        }
      }

      setData(updatedData);
      setFilteredRows(updatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const Name = localStorage.getItem('Name');
  console.log(Name, 'Name');
  const Phonenumber = localStorage.getItem('Phone');
  console.log(Phonenumber, 'Phonenumber')
  const EmailId = localStorage.getItem('email');
  console.log(EmailId, 'EmailIfD');
  const virtual_account = localStorage.getItem('virtual_account');
  console.log(virtual_account, 'VirtualAccount');

  

  const exportToExcel = () => {
    const filteredRows = data.map((row) => {
      const { trandate, ...rest } = row; // Exclude 'trandate' field
      return rest;
    });

    const worksheet = XLSX.utils.json_to_sheet([], { skipHeader: true }); // Empty sheet

    // Add 2 rows of space above userDetails
    const userDetails = [
      [`Name: ${Name}`],
      [`Email: ${EmailId}`],
      [`Phone: ${Phonenumber}`],
      [`Virtual Account: ${virtual_account}`],
    ];

    XLSX.utils.sheet_add_aoa(worksheet, userDetails, { origin: "A3" }); // Start from row 3 to leave 2 rows above

    // Calculate starting row for table headers (user details + 2 rows above + 2 rows below)
    const headerRowIndex = userDetails.length + 5; // 3 (space above) + 2 (space below userDetails)

    // Add headers in the calculated row
    const headers = [["S.No", "Member ID", "Date", "Narration", "Credit", "Debit", "Balance"]];
    XLSX.utils.sheet_add_aoa(worksheet, headers, { origin: `A${headerRowIndex}` });

    // Add data rows below the headers
    XLSX.utils.sheet_add_json(worksheet, filteredRows, {
      origin: `A${headerRowIndex + 1}`,
      skipHeader: true,
    });

    // Apply styles to the headers
    const headerRow = headerRowIndex - 1; // Adjust for 0-based index
    headers[0].forEach((_, colIndex) => {
      const cellRef = XLSX.utils.encode_cell({ r: headerRow, c: colIndex }); // Get cell reference
      if (!worksheet[cellRef]) return; // Skip if the cell doesn't exist
      worksheet[cellRef].s = {
        alignment: { horizontal: "center", vertical: "center" }, // Center alignment
        font: { bold: true }, // Bold text
      };
    });

    // Set column widths
    worksheet["!cols"] = [
      { wch: 5 },  // For S.No
      { wch: 20 }, // For Member ID
      { wch: 25 }, // For Date
      { wch: 40 }, // For Narration
      { wch: 10 }, // For Credit
      { wch: 10 }, // For Debit
      { wch: 10 }, // For Balance
    ];

    // Create workbook and append worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

    // Write workbook to Excel file
    XLSX.writeFile(workbook, "Transactionsdetail.xlsx");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        backgroundColor: "#F8F8FB",
      }}
    >
      <Commontransition
        text={"Ewallet Report"}
        transition={"Ewallet"}
        onClick={() => window.location.reload()}
      />
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          alignItems: "center",
          padding: "10px",
          justifyContent: "center",
          gap: 30,
        }}
      >
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="From date"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="To date"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: "10px", height: "56px" }}
          onClick={fetchData}
        >
          Search
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: "10px", height: "56px", width: "100px" }}
          onClick={exportToExcel}
        >
          EXPORT
        </Button>
      </div>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "230px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                      {columns.map((column) => {
                        const value =
                          column.id === "date"
                            ? new Date(row.date).toLocaleString()
                            : row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              color:
                                column.id === "credit"
                                  ? "green"
                                  : column.id === "debit"
                                    ? "red"
                                    : "black",
                            }}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </div>
  );
};

export default Transactiontable;
