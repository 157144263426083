// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    background-color: #0003cd;
    width: 100%;
  }
  
  .container-fluid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .contact-info,
  .social-icons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .contact-info div,
  .social-icons div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .social-icon-container {
    background-color: #ffffff;
    width: 35px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  
  @media (max-width: 768px) {
    .contact-info {
      justify-content: center;
    }
  
    .social-icons {
      justify-content: center;
      margin-top: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .contact-info span {
      font-size: 14px;
    }
  
    .social-icon-container {
      width: 30px;
      height: 32px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,eAAe;EACjB;;EAEA;;IAEE,aAAa;IACb,SAAS;IACT,eAAe;EACjB;;EAEA;;IAEE,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE;MACE,uBAAuB;IACzB;;IAEA;MACE,uBAAuB;MACvB,gBAAgB;IAClB;EACF;;EAEA;IACE;MACE,eAAe;IACjB;;IAEA;MACE,WAAW;MACX,YAAY;IACd;EACF","sourcesContent":[".navbar {\n    background-color: #0003cd;\n    width: 100%;\n  }\n  \n  .container-fluid {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n  }\n  \n  .contact-info,\n  .social-icons {\n    display: flex;\n    gap: 10px;\n    flex-wrap: wrap;\n  }\n  \n  .contact-info div,\n  .social-icons div {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n  }\n  \n  .social-icon-container {\n    background-color: #ffffff;\n    width: 35px;\n    height: 38px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 5px;\n  }\n  \n  @media (max-width: 768px) {\n    .contact-info {\n      justify-content: center;\n    }\n  \n    .social-icons {\n      justify-content: center;\n      margin-top: 10px;\n    }\n  }\n  \n  @media (max-width: 480px) {\n    .contact-info span {\n      font-size: 14px;\n    }\n  \n    .social-icon-container {\n      width: 30px;\n      height: 32px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
