import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from '../apiconfig/Api'


export const getAllData = createAsyncThunk(
  "Items/getAllData",
  async (args, { rejectWithValue }) => {
    try {
      const jwtToken = localStorage.getItem("authToken");
      if (!jwtToken) {
        throw new Error("No token found");
      }

      const response = await fetch(Api.GET_BALANCE_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`, // Correct format for Authorization header
        },
      });

      if (!response.ok) {
        throw new Error(`Network issue ${response.status}`);
      }

      const data = await response.json(); // Assuming the API returns JSON
      return data; // Return the data to be used in the component
    } catch (error) {
      console.error("Fetch error:", error);
      return rejectWithValue(error.message); // Provide detailed error information
    }
  }
);

export const Balance = createSlice({
  name: "Items",
  initialState: {
    users: [],
    loading: false,
    error: null,
  },
  reducers: [],
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload; // Ensure the data structure matches your API response
      })
      .addCase(getAllData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Accessing error message from action.payload
      });
  },
});

export default Balance.reducer;
