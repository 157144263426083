import React, { useEffect } from "react";
// import Navbar from "../components/Navbar";
import Commonpage from "../components/Commonpage";

function Services() {

  return (
    < >
     <Commonpage/>
    </>
  );
}

export default Services;
