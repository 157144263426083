import React, { useEffect, useState } from "react";
import Commontransition from "../commoncomponents/Commontransition";
import { Grid, Button } from "@mui/material";
import Commoninputfield from "../commoncomponents/Commoninputfield";

function Recharge() {
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [pinForm, setPinForm] = useState({
    currentPin: "",
    newPin: "",
    confirmPin: "",
  });

  const [errors, setErrors] = useState({
    passwordErrors: {},
    pinErrors: {},
  });

  // Change background color on mount
  useEffect(() => {
    document.body.style.backgroundColor = "#F8F8FB";
    return () => {
      document.body.style.backgroundColor = ""; // Revert to default background color on unmount
    };
  }, []);

  // Validation for password form
  const validatePasswordForm = () => {
    const { currentPassword, newPassword, confirmPassword } = passwordForm;
    const passwordErrors = {};

    if (!currentPassword) passwordErrors.currentPassword = "Current Password is required";
    if (!newPassword) passwordErrors.newPassword = "New Password is required";
    if (newPassword !== confirmPassword) passwordErrors.confirmPassword = "Passwords do not match";

    setErrors((prevErrors) => ({ ...prevErrors, passwordErrors }));
    return Object.keys(passwordErrors).length === 0;
  };

  // Validation for transaction pin form
  const validatePinForm = () => {
    const { currentPin, newPin, confirmPin } = pinForm;
    const pinErrors = {};

    if (!currentPin) pinErrors.currentPin = "Current Transaction Pin is required";
    if (!newPin) pinErrors.newPin = "New Transaction Pin is required";
    if (newPin !== confirmPin) pinErrors.confirmPin = "Pins do not match";

    setErrors((prevErrors) => ({ ...prevErrors, pinErrors }));
    return Object.keys(pinErrors).length === 0;
  };

  const handlePasswordSubmit = () => {
    if (validatePasswordForm()) {
      // Proceed with form submission or further actions
      alert("Password form is valid");
    }
  };

  const handlePinSubmit = () => {
    if (validatePinForm()) {
      // Proceed with form submission or further actions
      alert("Transaction Pin form is valid");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Commontransition
        text={"Change Panel"}
        transition={"Change Panel"}
        onClick={() => window.location.reload()}
      />
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "50px",
          gap: "20px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        {/* Password Change Section */}
        <span style={{ color: "red", fontSize: "21px" }}>
          Password has been expired, please change now
        </span>

        {/* Current Password */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "22px", color: "black" }}>
              Current Password
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <Commoninputfield
              value={passwordForm.currentPassword}
              onChange={(e) =>
                setPasswordForm({ ...passwordForm, currentPassword: e.target.value })
              }
            />
            {errors.passwordErrors.currentPassword && (
              <span style={{ color: "red" }}>{errors.passwordErrors.currentPassword}</span>
            )}
          </Grid>
        </Grid>

        {/* New Password */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "22px", color: "black" }}>New Password</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <Commoninputfield
              value={passwordForm.newPassword}
              onChange={(e) =>
                setPasswordForm({ ...passwordForm, newPassword: e.target.value })
              }
            />
            {errors.passwordErrors.newPassword && (
              <span style={{ color: "red" }}>{errors.passwordErrors.newPassword}</span>
            )}
          </Grid>
        </Grid>

        {/* Confirm Password */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "22px", color: "black" }}>Confirm Password</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <Commoninputfield
              value={passwordForm.confirmPassword}
              onChange={(e) =>
                setPasswordForm({ ...passwordForm, confirmPassword: e.target.value })
              }
            />
            {errors.passwordErrors.confirmPassword && (
              <span style={{ color: "red" }}>{errors.passwordErrors.confirmPassword}</span>
            )}
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            marginTop: "30px",
            gap: "5px",
            paddingLeft: "110px",
          }}
        >
          <Button
            variant="contained"
            onClick={handlePasswordSubmit}
            style={{
              backgroundColor: "#088405",
              color: "#fff",
              width: "100px",
              padding: "15px",
              fontWeight: "bold",
              borderRadius: "30px",
            }}
          >
            SUBMIT
          </Button>
        </div>

        {/* Transaction Pin Change Section */}
        <span style={{ color: "red", fontSize: "21px", marginTop: "20px" }}>
          Pin has been expired, please change now
        </span>

        {/* Current Transaction Pin */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "22px", color: "black" }}>
              Current Transaction Pin
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <Commoninputfield
              value={pinForm.currentPin}
              onChange={(e) =>
                setPinForm({ ...pinForm, currentPin: e.target.value })
              }
            />
            {errors.pinErrors.currentPin && (
              <span style={{ color: "red" }}>{errors.pinErrors.currentPin}</span>
            )}
          </Grid>
        </Grid>

        {/* New Transaction Pin */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "22px", color: "black" }}>New Transaction Pin</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <Commoninputfield
              value={pinForm.newPin}
              onChange={(e) => setPinForm({ ...pinForm, newPin: e.target.value })}
            />
            {errors.pinErrors.newPin && (
              <span style={{ color: "red" }}>{errors.pinErrors.newPin}</span>
            )}
          </Grid>
        </Grid>

        {/* Confirm Transaction Pin */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "22px", color: "black" }}>
              Confirm Transaction Pin
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <Commoninputfield
              value={pinForm.confirmPin}
              onChange={(e) => setPinForm({ ...pinForm, confirmPin: e.target.value })}
            />
            {errors.pinErrors.confirmPin && (
              <span style={{ color: "red" }}>{errors.pinErrors.confirmPin}</span>
            )}
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            marginTop: "30px",
            gap: "5px",
            paddingLeft: "110px",
          }}
        >
          <Button
            variant="contained"
            onClick={handlePinSubmit}
            style={{
              backgroundColor: "#088405",
              color: "#fff",
              width: "100px",
              padding: "15px",
              fontWeight: "bold",
              borderRadius: "30px",
            }}
          >
            SUBMIT
          </Button>
        </div>
      </Grid>
    </div>
  );
}

export default Recharge;
