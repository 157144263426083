import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Homepage from "../pages/Homepage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "../pages/Login";
import AdminLogin from "../admin/Adminlogin";
import AdminDashboard from  "../admin/admindashboard/AdminDashboard";
import Services from "../screen/Services";
import Career from "../screen/Career";
import Contact from "../screen/Contact";
import Company from "../screen/Company";

const Navigators = () => {
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("authToken"));
  const [adminloggedin, setAdminlogedin] = useState(
    !!localStorage.getItem("adminAuthToken")
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    // localStorage.setItem("authToken", token);
    setLoggedIn(!!authToken); // Converts the token to a boolean
    setLoading(false); // Set loading to false after checking authToken
  }, []);
  // for user private routing
  const PrivateRoute = ({ element, ...props }) => {
    if (loading) return null; // Show nothing or a loading spinner while checking auth
    return loggedIn ? element : <Navigate to="/Login" />;
  };

  const Adminprivateroute = ({ element, ...props }) => {
    if (loading) return null; // Show nothing or a loading spinner while checking auth
    return adminloggedin ? element : <Navigate to="/AdminLogin" />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Career" element={<Career />} />
        <Route path="/Company" element={<Company />} />
        <Route path="/Login" element={<Login setLoggedIn={setLoggedIn} />} />
        <Route
          path="/AdminLogin"
          element={<AdminLogin setAdminlogedin={setAdminlogedin} />}
        />
        <Route
          path="/AdminDashboard"
          element={<Adminprivateroute element={<AdminDashboard/>} />}
        />
        <Route
          path="/Dashboard"
          element={<PrivateRoute element={<Dashboard />} />}
        />
        <Route path="*" element={<Homepage />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default Navigators;

