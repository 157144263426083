import React, { useEffect, useState } from "react";
import Commontransition from "../commoncomponents/Commontransition";
import { Grid, Button } from "@mui/material";

function Aadharkyc() {
  // State for form fields
  const [userData, setUserData] = useState([]); // State to store API data
  const [kycFirstName, setKycFirstName] = useState("");
  const [kycLastName, setKycLastName] = useState("");
  const [kycMobile, setKycMobile] = useState("");

  // State for error messages
  const [errors, setErrors] = useState({
    kycFirstName: "",
    kycLastName: "",
    kycMobile: "",
  });

  const Name = localStorage.getItem("Name");
  const Phonenumber = localStorage.getItem("Phone");

  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#F8F8FB";

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Revert back to default background color
    };
  }, []);

  // Validate fields
  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (!kycFirstName) {
      formIsValid = false;
      errors.kycFirstName = "First Name is required";
    }

    if (!kycLastName) {
      formIsValid = false;
      errors.kycLastName = "Last Name is required";
    }

    if (!kycMobile) {
      formIsValid = false;
      errors.kycMobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(kycMobile)) {
      formIsValid = false;
      errors.kycMobile = "Enter a valid 10-digit mobile number";
    }

    setErrors(errors);
    return formIsValid;
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // Submit the form
      console.log("Form submitted successfully");
    }
  };

  const staticData = `This is some static data that will be downloaded as a file.`;
  // Function to handle file download
  const handleDownload = () => {
    // Create a Blob from the static data
    const blob = new Blob([Name, "  ", Phonenumber], { type: "text/plain" });

    // Create a link element
    const link = document.createElement("a");

    // Create a URL for the Blob and set it as the link href
    link.href = URL.createObjectURL(blob);

    // Set the download attribute with the desired file name
    link.download = "static_data.txt";

    // Append the link to the body temporarily
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Commontransition
        text={"KYC Process"}
        transition={"New Member Add"}
        onClick={() => window.location.reload()}
      />
      <h3 style={{ color: "666666", marginTop: "60px" }}>New KYC Process</h3>
      <Grid
        container
        sx={{
          padding: "10px",
          borderRadius: "5px",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          paddingBottom: "40px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Grid
          container
          sx={{
            marginTop: "20px",
            paddingBottom: "20px",
            borderRadius: "5px",
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
            backgroundColor: "#ff=",
            boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#28A745",
              padding: "10px",
            }}
          >
            <span style={{ color: "#fff", fontSize: "20px" }}>
              Important Points
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "20px",
              paddingTop: "30px",
            }}
          >
            <span style={{ fontSize: "19px" }}>
              1. Please enter the kyc user mobile number as per your aadhar
              record
            </span>
            <span style={{ fontSize: "19px" }}>
              2. All * Sign are mandatory fields.
            </span>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          marginTop: "50px",
          gap: "20px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
        component="form"
        onSubmit={handleSubmit}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            marginTop: "20px",
            flexWrap: "wrap",
          }}
        >
          {/* Button 1 */}
          <div style={{ flex: "1 1 20%", minWidth: "200px" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#083090", padding: "15px 30px" }}
            >
              On Board For Icici Aeps
            </Button>
          </div>

          {/* Button 2 */}
          <div style={{ flex: "1 1 20%", minWidth: "200px" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#083090", padding: "15px 30px" }}
            >
              CLICK HERE FOR ICICI EKYC
            </Button>
          </div>

          {/* Button 3 */}
          <div style={{ flex: "1 1 20%", minWidth: "200px" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#083090", padding: "15px 30px" }}
            >
              On Board New Bank KYC
            </Button>
          </div>
        </div>

        {/* KYC User First Name */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "20px" }}>
              KYC User First Name <span style={{ color: "#E83EBC" }}>*</span>
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              type="text"
              name="Kycuserfirstname"
              value={kycFirstName}
              onChange={(e) => setKycFirstName(e.target.value)}
              style={{
                padding: "10px",
                width: "100%",
                height: "47px",
                paddingRight: "70px",
              }}
            />
            <span style={{ color: "red" }}>{errors.kycFirstName}</span>
          </Grid>
        </Grid>

        {/* KYC User Last Name */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "20px" }}>
              KYC User Last Name <span style={{ color: "#E83EBC" }}>*</span>
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              type="text"
              name="lastname"
              value={kycLastName}
              onChange={(e) => setKycLastName(e.target.value)}
              style={{
                padding: "10px",
                width: "100%",
                height: "47px",
                paddingRight: "70px",
              }}
            />
            <span style={{ color: "red" }}>{errors.kycLastName}</span>
          </Grid>
        </Grid>

        {/* KYC User Mobile */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "20px" }}>
              KYC User Mobile <span style={{ color: "#E83EBC" }}>*</span>
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              type="number"
              name="kycusermobile"
              value={kycMobile}
              onChange={(e) => setKycMobile(e.target.value)}
              style={{
                padding: "10px",
                width: "100%",
                height: "47px",
                paddingRight: "70px",
              }}
            />
            <span style={{ color: "red" }}>{errors.kycMobile}</span>
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Button
          style={{
            padding: "15px 25px",
            backgroundColor: "#083090",
          }}
          variant="contained"
          type="submit"
        >
          SUBMIT
        </Button>
      </Grid>

      <div style={{ padding: "20px" }}>
        <Grid
          container
          sx={{
            padding: "20px",
            borderRadius: "5px",
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
            paddingBottom: "40px",
            backgroundColor: "#fff",
            boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
          }}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{
                    width: "25%",
                    border: "1px solid lightgray",
                    padding: "8px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    width: "25%",
                    border: "1px solid lightgray",
                    padding: "8px",
                  }}
                >
                  Mobile
                </th>
                <th
                  style={{
                    width: "25%",
                    border: "1px solid lightgray",
                    padding: "8px",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    width: "25%",
                    border: "1px solid lightgray",
                    padding: "8px",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: "1px solid lightgray", padding: "8px" }}>
                  {Name}
                </td>
                <td style={{ border: "1px solid lightgray", padding: "8px" }}>
                  {Phonenumber}
                </td>
                <td style={{ border: "1px solid lightgray", padding: "8px" }}>
                  Success
                </td>
                <td style={{ border: "1px solid lightgray", padding: "8px" }}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#083090", padding: "15px 50px" }}
                    onClick={handleDownload}
                  >
                    DOWNLOADDOC
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </div>
    </div>
  );
}

export default Aadharkyc;
