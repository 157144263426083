import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure axios is imported
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Imageone from "../assets/imagecarouselunique.png";
import Imagetwo from "../assets/imagecaouseltwo.png";
import Imagethree from "../assets/imagecarouselthree.png";
import Logo from "../assets/logo.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-toastify/dist/ReactToastify.css";
import "./Carousel.css";
import Api from "../apiconfig/Api";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();
const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .test("is-email-or-id", "Invalid email or User ID", function (value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const userId = "9694156968";
      return emailRegex.test(value) || value === userId;
    })
    .required("Email or User ID is required"),
  password: Yup.string().required("Password is required"),
});

// ...

function Login({ setLoggedIn }) {
  const navigate = useNavigate();
  const [showMPINPage, setShowMPINPage] = useState(false); // State to control which page is shown
  const [mpin, setMpin] = useState(""); // State to store the MPIN input


  // Disable right-click and keyboard shortcuts like F12
  useEffect(() => {
    // Disable right-click context menu
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    // Disable specific keyboard shortcuts
    const disableShortcuts = (e) => {
      if (
        e.key === "F12" || // F12 - Developer tools
        (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I - Developer tools
        (e.ctrlKey && e.shiftKey && e.key === "J") || // Ctrl+Shift+J - Console
        (e.ctrlKey && e.key === "U") // Ctrl+U - View Source
      ) {
        e.preventDefault();
      }
    };

    // Add event listeners
    document.addEventListener("contextmenu", disableRightClick); // Disable right-click
    document.addEventListener("keydown", disableShortcuts); // Disable specific shortcuts

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableShortcuts);
    };
  }, []);


  const handleSubmit = (values, { resetForm }) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const payload = emailRegex.test(values.email)
      ? { email: values.email, password: values.password }
      : { email: "testuser@example.com", password: values.password };

    console.log(Api.LOGIN_URL, "LOGIN_URL");
    // Make the login request
    axios
      .post(Api.LOGIN_URL, payload) // Use BASE_URL from your config
      .then((response) => {
        console.log(response, "response");
        const { token, role } = response.data;
        if (role === "USER") {
          localStorage.setItem("authToken", token); // Store token in localStorage
          toast.success("Login successful!");
          setLoggedIn(true);
          setShowMPINPage(true);
        } else {
          toast.error("Login Failed");
        }

      })
      .catch((error) => {
        toast.error("Login failed. Please try again.");
      });
  };

  const handleVerifyMPIN = (values) => {
    const payload = { mpin: mpin };
    console.log(Api.VERIFY_MPIN_URL, "VERIFY_MPIN_URL");
    const token = localStorage.getItem("authToken");
    console.log(token, "TOKEN");

    axios
      .post(Api.VERIFY_MPIN_URL, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response, "response");
        localStorage.setItem("Name", response.data.name);
        localStorage.setItem("UserId", response.data.userId);
        navigate("/Dashboard");
      })
      .catch((error) => {
        toast.error("Invalid MPIN. Please try again.");
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <ToastContainer />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={4}
          md={7}
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Swiper
            spaceBetween={10}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={false}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                style={{ width: "60%", height: "auto" }}
                src={Imageone}
                alt="Imageone"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                style={{ width: "60%", height: "auto" }}
                src={Imagetwo}
                alt="Imagetwo"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                style={{ width: "60%", height: "auto" }}
                src={Imagethree}
                alt="Imagethree"
              />
            </SwiperSlide>
          </Swiper>
        </Grid>

        {showMPINPage ? (
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#f0f2f5",
            }}
          >
            {/* MPIN Page */}
            <Box
              style={{
                backgroundColor: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                padding: "4rem",
                maxWidth: "400px",
                width: "100%",
              }}
              sx={{
                my: 4,
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Enter MPIN
              </Typography>
              <TextField
                label="MPIN"
                variant="outlined"
                fullWidth
                value={mpin}
                onChange={(e) => setMpin(e.target.value)}
                sx={{ mt: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleVerifyMPIN}
              >
                Verify
              </Button>
            </Box>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#f0f2f5",
            }}
          >
            <img src={Logo} style={{ width: "11rem" }} alt="logo" />
            <Box
              style={{
                backgroundColor: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                padding: "4rem",
                maxWidth: "400px",
                width: "100%",
              }}
              sx={{
                my: 4,
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>

              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={loginValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form noValidate sx={{ mt: 1 }}>
                    <Field
                      as={TextField}
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address or User ID"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "lightskyblue",
                          },
                          "&:hover fieldset": {
                            borderColor: "deepskyblue",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "deepskyblue",
                          },
                        },
                      }}
                    />
                    <Field
                      as={TextField}
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "lightskyblue",
                          },
                          "&:hover fieldset": {
                            borderColor: "deepskyblue",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "deepskyblue",
                          },
                        },
                      }}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Log In
                    </Button>
                  </Form>
                )}
              </Formik>

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
}

export default Login;
