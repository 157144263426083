import React from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

function CommonGroupaddicon({ text, footertext, styles, stylesIcon,  progressColor = "darkred" }) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor:progressColor ,
    //   height:"20px",
      ...theme.applyStyles("dark", {
        backgroundColor: "#308fe8",
      }),
    },
  }));
  return (
    <div
      style={{
        flex: "1 1 calc(25% - 20px)", // 25% width minus the gap on desktop
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#fff",
        padding: "10px",
        borderRadius: "8px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        minWidth: "250px",
      }}
    >
      <div
        style={
          stylesIcon
            ? stylesIcon
            : {
                width: "100px",
                height: "100px",
                backgroundColor: "darkred",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }
        }
      >
        <GroupAddIcon
          style={{
            color: "#fff",
            width: 50,
            height: 50,
          }}
        />
      </div>
      <div style={{ flex: 1, flexDirection: "column", marginLeft: "10px", }}>
        <span style={{ fontWeight: "bold", fontSize: "20px" }}>
          {text ? text : "79"}
        </span>
        <BorderLinearProgress
          variant="determinate"
          style={{ width: "100%", }}
          value={50}
        />
        <span style={styles ? styles : { fontWeight: "bold", color: "red" , marginTop:"20px"}}>
          {footertext ? footertext : "Total Member"}
        </span>
      </div>
    </div>
  );
}

export default CommonGroupaddicon;
