import React from "react";

function Commonbutton({buttontext, onClick}) {
  return (
    <>
      <button onClick={()=> onClick()} style={{backgroundColor:"#F7CAC9", padding:"10px",borderColor:"transparent", color:"lightslategrey", fontFamily:'sans-serif', fontWeight:"bold"}}>{ buttontext ?  buttontext :   "Add Money"}</button>
    </>
  );
}

export default Commonbutton;
