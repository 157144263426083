// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
    height: 130px;
    width: 230px;
    border-radius: 20px;
    box-shadow: 3px 3px 10px #001e57c0;
    background-color: #3f0097;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  .box:hover {
    transform: scale(1.08);
  }
  .topic-heading{
      color: #fff;
  }
  .topic{
      color: #fff;
  }`, "",{"version":3,"sources":["webpack://./src/commoncomponents/Dasboardcard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,kCAAkC;IAClC,yBAAyB;IACzB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;IACf,sCAAsC;EACxC;EACA;IACE,sBAAsB;EACxB;EACA;MACI,WAAW;EACf;EACA;MACI,WAAW;EACf","sourcesContent":[".box {\n    height: 130px;\n    width: 230px;\n    border-radius: 20px;\n    box-shadow: 3px 3px 10px #001e57c0;\n    background-color: #3f0097;\n    padding: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n    cursor: pointer;\n    transition: transform 0.3s ease-in-out;\n  }\n  .box:hover {\n    transform: scale(1.08);\n  }\n  .topic-heading{\n      color: #fff;\n  }\n  .topic{\n      color: #fff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
