// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-container {
  display: flex;
  gap: 15px;
}

@media (max-width: 768px) {
  .item-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  div[style*="display: flex; gap: 70px"] {
    flex-direction: column;
  }
}

/* Ensure the background container is responsive */
.background-container {
  position: relative;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 20px; /* Adjust the padding to keep content inside */
}

@media (max-width: 768px) {
  .background-container {
    flex-direction: column;
    height: 1200px; /* Let height adjust based on content */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Homeuserdetail.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE;IACE,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,sBAAsB;EACxB;AACF;;AAEA,kDAAkD;AAClD;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,sBAAsB;EACtB,2BAA2B;EAC3B,YAAY;EACZ,kBAAkB;EAClB,aAAa,EAAE,8CAA8C;AAC/D;;AAEA;EACE;IACE,sBAAsB;IACtB,cAAc,EAAE,uCAAuC;EACzD;AACF","sourcesContent":[".item-container {\n  display: flex;\n  gap: 15px;\n}\n\n@media (max-width: 768px) {\n  .item-container {\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n  }\n\n  div[style*=\"display: flex; gap: 70px\"] {\n    flex-direction: column;\n  }\n}\n\n/* Ensure the background container is responsive */\n.background-container {\n  position: relative;\n  height: 400px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 60px;\n  background-size: cover;\n  background-position: center;\n  color: white;\n  text-align: center;\n  padding: 20px; /* Adjust the padding to keep content inside */\n}\n\n@media (max-width: 768px) {\n  .background-container {\n    flex-direction: column;\n    height: 1200px; /* Let height adjust based on content */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
