import React from "react";
import Backgroundseen from "../assets/backgroundseen.jpg";
import Men from "../assets/men.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import "./Clientswiper.css";

function Clientsay() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "500px",
        backgroundImage: `url(${Backgroundseen})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          color: "white",
          fontSize: "24px",
          textAlign: "center",
          width: "100%", // Make sure the text stays centered
        }}
      >
        <h1>What our client say ?</h1>
        <Swiper
          navigation={true} // Enable navigation
          modules={[Navigation]} // Include the Navigation module
          className="mySwiper"
          style={{
            padding: "20px",
            backgroundColor: "transparent", // Transparent background
          }}
        >
          <SwiperSlide
            style={{
              display: "flex",
              // width:'70%',
              flexDirection: "column",
              alignItems: "center", // Center align the content
              backgroundColor: "transparent",
            }}
          >
            <img
              style={{ width: "61px", height: "61px", borderRadius: "50%" }} // Circular client image
              src={Men}
              alt="Client"
            />
            <p style={{ color: "#fff", maxWidth: "700px", marginTop: "15px" }}>
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur."
            </p>
          </SwiperSlide>
          <SwiperSlide
            style={{
              display: "flex",
              // width:'70%',
              flexDirection: "column",
              alignItems: "center", // Center align the content
              backgroundColor: "transparent",
            }}
          >
            <img
              style={{ width: "61px", height: "61px", borderRadius: "50%" }} // Circular client image
              src={Men}
              alt="Client"
            />
            <p style={{ color: "#fff", maxWidth: "700px", marginTop: "15px" }}>
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur."
            </p>
          </SwiperSlide>
          <SwiperSlide
            style={{
              display: "flex",
              // width:'70%',
              flexDirection: "column",
              alignItems: "center", // Center align the content
              backgroundColor: "transparent",
            }}
          >
            <img
              style={{ width: "61px", height: "61px", borderRadius: "50%" }} // Circular client image
              src={Men}
              alt="Client"
            />
            <p style={{ color: "#fff", maxWidth: "700px", marginTop: "15px" }}>
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur."
            </p>
          </SwiperSlide>
          <SwiperSlide
            style={{
              display: "flex",
              // width:'70%',
              flexDirection: "column",
              alignItems: "center", // Center align the content
              backgroundColor: "transparent",
            }}
          >
            <img
              style={{ width: "61px", height: "61px", borderRadius: "50%" }} // Circular client image
              src={Men}
              alt="Client"
            />
            <p style={{ color: "#fff", maxWidth: "700px", marginTop: "15px" }}>
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur."
            </p>
          </SwiperSlide>

          {/* Add more SwiperSlides if needed */}
        </Swiper>
      </div>
    </div>
  );
}

export default Clientsay;
