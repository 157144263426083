// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-container {
  display: flex;
  gap: 15px;
}

@media (max-width: 768px) {
  .item-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  div[style*="display: flex; gap: 70px"] {
    flex-direction: column;
  }
}

/* Ensure the background container is responsive */
.background-container {
  position: relative;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 20px; /* Adjust the padding to keep content inside */
}

@media (max-width: 768px) {
  .background-container {
    flex-direction: column;
    height: 1200px; /* Let height adjust based on content */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Homeuserdetail.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE;IACE,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,sBAAsB;EACxB;AACF;;AAEA,kDAAkD;AAClD;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,sBAAsB;EACtB,2BAA2B;EAC3B,YAAY;EACZ,kBAAkB;EAClB,aAAa,EAAE,8CAA8C;AAC/D;;AAEA;EACE;IACE,sBAAsB;IACtB,cAAc,EAAE,uCAAuC;EACzD;AACF","sourcesContent":[".item-container {\r\n  display: flex;\r\n  gap: 15px;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .item-container {\r\n    flex-direction: column;\r\n    align-items: center;\r\n    gap: 10px;\r\n  }\r\n\r\n  div[style*=\"display: flex; gap: 70px\"] {\r\n    flex-direction: column;\r\n  }\r\n}\r\n\r\n/* Ensure the background container is responsive */\r\n.background-container {\r\n  position: relative;\r\n  height: 400px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin-top: 60px;\r\n  background-size: cover;\r\n  background-position: center;\r\n  color: white;\r\n  text-align: center;\r\n  padding: 20px; /* Adjust the padding to keep content inside */\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .background-container {\r\n    flex-direction: column;\r\n    height: 1200px; /* Let height adjust based on content */\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
