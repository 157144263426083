import React, { useState, useEffect } from "react";
import axios from "axios";
import QRCode from "qrcode"; // Importing the QRCode library

const UserQrupload = () => {
  const [upiId, setUpiId] = useState(""); // New UPI ID state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [userId, setUserId] = useState("");
  const [image, setImage] = useState(null);
  const [users, setUsers] = useState([]);

  const BEARER_TOKEN = localStorage.getItem("adminAuthToken");
  const UPLOAD_API_URL = `${process.env.REACT_APP_API_URL}/upload/single-file`;
  const PROFILE_API_URL = `${process.env.REACT_APP_API_URL}/admin/user/qrImage`;
  const GET_USERS_API_URL = `${process.env.REACT_APP_API_URL}/admin/user/getMemberIds`;

  useEffect(() => {
    // Fetch user IDs on component mount
    const fetchUsers = async () => {
      try {
        const response = await axios.get(GET_USERS_API_URL, {
          headers: {
            Authorization: `Bearer ${BEARER_TOKEN}`,
          },
        });
        setUsers(response.data.data);
      } catch (err) {
        setError("An error occurred while fetching user IDs.");
        console.error(err);
      }
    };

    fetchUsers();
  }, [BEARER_TOKEN]);

  const handleUserIdChange = (e) => {
    setUserId(e.target.value);
  };

  const handleUpiIdChange = (e) => {
    setUpiId(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!upiId) {
      alert("Please enter UPI ID.");
      return;
    }

    if (!userId) {
      alert("Please select a user.");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // Generate valid UPI QR Code URL
      const upiQrCodeUrl = `upi://pay?pa=${upiId}&cu=INR`;

      // Generate QR Code from the UPI URL
      const qrCodeDataUrl = await QRCode.toDataURL(upiQrCodeUrl);

      // Convert the data URL to a Blob
      const qrCodeBlob = await fetch(qrCodeDataUrl).then((res) => res.blob());

      // Prepare form data
      const formData = new FormData();
      formData.append("file", qrCodeBlob, "qr-code.png"); // Appending QR code blob as a file

      // Upload the QR code image
      const uploadResponse = await axios.post(UPLOAD_API_URL, formData, {
        headers: {
          Authorization: `Bearer ${BEARER_TOKEN}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // Extract the image URL from the response
      const { imageUrl } = uploadResponse.data;
      setImage(imageUrl);

      // Update the profile with the image URL and user ID
      const profilePayload = {
        id: Number(userId), // Ensure user ID is a number
        imageUrl,
      };

      await axios.put(PROFILE_API_URL, profilePayload, {
        headers: {
          Authorization: `Bearer ${BEARER_TOKEN}`,
          "Content-Type": "application/json",
        },
      });

      setSuccess("QR code generated, uploaded, and updated successfully!");
    } catch (err) {
      setError(
        "An error occurred while generating or uploading the QR code."
      );
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Generate and Upload UPI QR Code</h2>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: 30 }}
      >
        {/* UPI ID input field */}
      

        <select
          style={{
            width: "60%",
            borderRadius: "10px",
            padding: "10px",
            border: "1px solid #ccc",
          }}
          value={userId}
          onChange={handleUserIdChange}
        >
          <option value="">Select User ID</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.memberId}
            </option>
          ))}
        </select>
        <input
        type="text"
        value={upiId}
        onChange={handleUpiIdChange}
        placeholder="Enter UPI ID"
        style={{
          padding: "10px",
          borderRadius: "10px",
          border: "1px solid #ccc",
          width: "60%",
        }}
      />

        {image && (
          <div style={{ width: "15%" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={image}
              alt="QR Code"
            />
          </div>
        )}

        <button
          type="submit"
          disabled={loading}
          style={{
            width: "100px",
            padding: "5px",
            backgroundColor: "red",
            color: "#fff",
            marginTop: "20px",
            borderRadius: "10px",
            borderColor: "red",
          }}
        >
          {loading ? "Uploading..." : "Upload"}
        </button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
    </div>
  );
};

export default UserQrupload;
