import React from "react";
import Wallet from "../assets/wallet.png";
function Usercards({ text, textsmall, style }) {

  const Balance = localStorage.getItem("Balance");


  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span style={{ fontSize: "16px", color: "#06264e" }}>
        {text ? text : "E-Wallet Balance"}
      </span>
      <span style={style ? style : { color: "blue", fontWeight: "bold" }}>
        {textsmall ? textsmall : `${Balance} ${"INR"}`}
      </span>
      <div style={{display:'flex', justifyContent:'center', alignItems:"center"}}>
      <img src={Wallet} alt="wallet"   style={{width:"40px", height:"40px"}}/>
      </div>
    </div>
  );
}

export default Usercards;
