import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Homepage from "../pages/Homepage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "../pages/Login";
import AdminLogin from "../admin/Adminlogin";
import AdminDashboard from "../admin/admindashboard/AdminDashboard";
import Services from "../screen/Services";
import Career from "../screen/Career";
import Forgotpassword from "../pages/Forgotpassword";
import Contact from "../screen/Contact";
import Company from "../screen/Company";
import Qrcodepage from "../pages/Qrcodepage";

const Navigators = () => {
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("authToken"));
  const [adminloggedin, setAdminlogedin] = useState(
    !!localStorage.getItem("adminAuthToken")
  );
  const [adminTokenStored, setAdminTokenStored] = useState(
    !!localStorage.getItem("adminAuthTokenstored")
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const adminAuthTokenstored = localStorage.getItem("adminAuthTokenstored");
    setLoggedIn(!!authToken);
    setAdminTokenStored(!!adminAuthTokenstored);
    setLoading(false); // Set loading to false after checking authToken
  }, []);

  // for user private routing
  const PrivateRoute = ({ element }) => {
    if (loading) return null; // Show nothing or a loading spinner while checking auth
    return loggedIn ? element : <Navigate to="/Login" />;
  };

  const Adminprivateroute = ({ element }) => {
    if (loading) return null; // Show nothing or a loading spinner while checking auth
    return adminloggedin ? element : <Navigate to="/AdminLogin" />;
  };

  const AdminTokenStoredRoute = ({ element }) => {
    if (loading) return null; // Show nothing or a loading spinner while checking auth
    return adminTokenStored ? element : <Navigate to="/AdminLogin" />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Career" element={<Career />} />
        <Route path="/Forgotpassword" element={<Forgotpassword />} />
        <Route
          path="/Qrcodepage"
          element={<AdminTokenStoredRoute element={<Qrcodepage />} />}
        />
        <Route path="/Company" element={<Company />} />
        <Route path="/Login" element={<Login setLoggedIn={setLoggedIn} />} />
        <Route
          path="/AdminLogin"
          element={<AdminLogin setAdminlogedin={setAdminlogedin} />}
        />

        <Route
          path="/AdminDashboard"
          element={<Adminprivateroute element={<AdminDashboard />} />}
        />

        <Route
          path="/Dashboard"
          element={<PrivateRoute element={<Dashboard />} />}
        />
        <Route path="*" element={<Homepage />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default Navigators;
