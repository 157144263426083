import React, { useState } from 'react';
import Back from '../assets/back.png';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
  { id: 'sno', label: 'S.NO', minWidth: 70 },
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'phone', label: 'Phone', minWidth: 100 },
  { id: 'email', label: 'Email', minWidth: 100 },
  { id: 'vc', label: 'V/C', minWidth: 100 },
  { id: 'address', label: 'Address', minWidth: 150 },
  { id: 'aadhar', label: 'Aadhar', minWidth: 100 },
  { id: 'formattedDate', label: 'Date', minWidth: 100 },
  { id: 'businessPanNo', label: 'BusinessPanNo', minWidth: 100 },
  { id: 'gstNumber', label: 'GstNumber', minWidth: 100 },
  { id: 'landlineNumber', label: 'LandlineNumber', minWidth: 100 },
  { id: 'landlineSTDCode', label: 'LandlineSTDCode', minWidth: 100 },
  { id: 'panNumber', label: 'PanNumber', minWidth: 100 },
  { id: 'shopAddress', label: 'ShopAddress', minWidth: 150 },
  { id: 'shopName', label: 'ShopName', minWidth: 100 },
  { id: 'state', label: 'State', minWidth: 100 },
];

function Showprofilesdetails({ user, handleBackClick }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const userData = [user]; // Wrapping the user object in an array to be mapped in the table

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <img
        onClick={() => handleBackClick()}
        style={{ width: '60px', height: '60px', marginLeft: '10px', marginTop: '10px' }}
        alt="Back"
        src={Back}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#fff',
          width: '100%',
          padding: '25px',
          marginTop: '20px',
        }}
      >
        <h3 style={{ color: '#666666' }}>Users Profile Details</h3>
      </div>

      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '100px',
          padding: '40px',
          borderRadius: '5px',
          backgroundColor: '#fff',
          boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} style={{ minWidth: column.minWidth, color:"black", fontSize:"15px", fontWeight:"600" }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = column.id === 'sno' ? index + 1 : row[column.id] || 'N/A';
                      return <TableCell style={{color:"black", fontSize:"16px"}} key={column.id}>{value}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={userData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </div>
  );
}

export default Showprofilesdetails;
