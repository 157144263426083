import React, { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import Commontransition from "../commoncomponents/Commontransition";

function Changempin() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({});

  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#F8F8FB";

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Revert back to default background color
    };
  }, []);

  const validateFields = () => {
    const errors = {};

    // Validation for Current Transaction Password
    if (!currentPassword) {
      errors.currentPassword = "Current transaction password is required";
    }

    // Validation for New Transaction Password (minimum 6 characters)
    if (!newPassword || newPassword.length < 6) {
      errors.newPassword = "New transaction password must be at least 6 characters long";
    }

    // Validation for Confirm Transaction Password (must match new password)
    if (confirmPassword !== newPassword) {
      errors.confirmPassword = "Confirm transaction password does not match the new password";
    }

    setError(errors);

    // If no errors, handle the next steps
    if (Object.keys(errors).length === 0) {
      alert("Validation successful! OTP sent successfully!"); // This can be replaced with actual OTP logic
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Commontransition
        text={"Change Transaction Pin Panel"}
        transition={"Change Transaction Pin"}
        onClick={() => window.location.reload()}
      />

      <Grid
        container
        sx={{
          marginTop: "50px",
          gap: "20px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        {/* Current Transaction Password */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "20px" }}>Current Transaction Password</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              type="password"
              name="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              style={{
                padding: "30px",
                width: "100%",
                height: "47px",
                paddingRight: "70px",
              }}
            />
            {error.currentPassword && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {error.currentPassword}
              </span>
            )}
          </Grid>
        </Grid>

        {/* New Transaction Password */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "20px" }}>New Transaction Password</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              type="password"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={{
                padding: "30px",
                width: "100%",
                height: "47px",
                paddingRight: "70px",
              }}
            />
            {error.newPassword && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {error.newPassword}
              </span>
            )}
          </Grid>
        </Grid>

        {/* Confirm Transaction Password */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "20px" }}>Confirm Transaction Password</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={{
                padding: "30px",
                width: "100%",
                height: "47px",
                paddingRight: "70px",
              }}
            />
            {error.confirmPassword && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {error.confirmPassword}
              </span>
            )}
          </Grid>
        </Grid>

        {/* Buttons */}
        <div
          style={{
            display: "flex",
            marginTop: "30px",
            gap: "5px",
            paddingLeft: "110px",
          }}
        >
          <Button
            variant="contained"
            onClick={validateFields}
            style={{
              backgroundColor: "#088405",
              color: "#fff",
              width: "100px",
              padding: "15px",
              fontWeight: "bold",
              borderRadius: "30px",
            }}
          >
            SUBMIT
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setCurrentPassword("");
              setNewPassword("");
              setConfirmPassword("");
              setError({});
            }}
            style={{
              backgroundColor: "#DE2A2A",
              color: "#fff",
              fontWeight: "bold",
              width: "100px",
              padding: "15px",
              borderRadius: "30px",
            }}
          >
            RESET
          </Button>
        </div>
      </Grid>
    </div>
  );
}

export default Changempin;
