import React from "react";
import { Grid, Box } from "@mui/material";
import Button from '@mui/material/Button';
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import * as Yup from "yup";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  state: Yup.string().required("State is required"),
  mobileNumber: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be 10 digits")
    .required("Mobile Number is required"),
  pinCode: Yup.string().required("Pin Code is required"),
  city: Yup.string().required("City is required"),
  enquiry: Yup.string().required("Enquiry For is required"),
});

function Contact() {
  const router = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      state: "",
      mobileNumber: "",
      pinCode: "",
      city: "",
      enquiry: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <div style={{ marginLeft: "40px", marginRight: "40px", marginTop: "40px" }}>
    <div style={{marginTop:"-10px",}}>
    <Button onClick={()=>  router("/")} variant="contained">Home</Button>
    </div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          marginTop:"10px",
          borderRadius: "10px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
        }}
      >
    
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={10}
          style={{
            padding: "25px",
            borderRadius: "10px",
            backgroundColor: "#fff",
            marginTop: "100px",
            marginBottom: "100px",
            boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <h4>Personal Information</h4>
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} md={6}>
                  <span style={{ fontSize: "15px", color: "#393939" }}>
                    First Name: <span style={{ color: "red" }}>*</span>
                  </span>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    name="firstName"
                    placeholder="First Name"
                    style={{
                      width: "100%",
                      height: "47px",
                      borderRadius: "10px",
                      borderColor: "lightgray",
                      paddingLeft: "20px",
                    }}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.firstName}
                    </div>
                  ) : null}
                </Grid>
              </Grid>

              {/* Last Name */}
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                marginTop="16px"
              >
                <Grid item xs={12} md={6}>
                  <span style={{ fontSize: "15px", color: "#393939" }}>
                    Last Name:<span style={{ color: "red" }}>*</span>
                  </span>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    name="lastName"
                    placeholder="Last Name"
                    style={{
                      width: "100%",
                      height: "47px",
                      borderRadius: "10px",
                      borderColor: "lightgray",
                      paddingLeft: "20px",
                    }}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.lastName}
                    </div>
                  ) : null}
                </Grid>
              </Grid>

              {/* Email */}
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                marginTop="16px"
              >
                <Grid item xs={12} md={6}>
                  <span style={{ fontSize: "15px", color: "#393939" }}>
                    Email:<span style={{ color: "red" }}>*</span>
                  </span>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    name="email"
                    placeholder="Email"
                    style={{
                      width: "100%",
                      height: "47px",
                      borderRadius: "10px",
                      borderColor: "lightgray",
                      paddingLeft: "20px",
                    }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.email}
                    </div>
                  ) : null}
                </Grid>
              </Grid>

              {/* State */}
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                marginTop="16px"
              >
                <Grid item xs={12} md={6}>
                  <span style={{ fontSize: "15px", color: "#393939" }}>
                    State:<span style={{ color: "red" }}>*</span>
                  </span>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    name="state"
                    placeholder="State"
                    style={{
                      width: "100%",
                      height: "47px",
                      borderRadius: "10px",
                      borderColor: "lightgray",
                      paddingLeft: "20px",
                    }}
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.state && formik.errors.state ? (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.state}
                    </div>
                  ) : null}
                </Grid>
              </Grid>

              {/* Mobile Number */}
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                marginTop="16px"
              >
                <Grid item xs={12} md={6}>
                  <span style={{ fontSize: "15px", color: "#393939" }}>
                    10 Digit Mobile Number:
                    <span style={{ color: "red" }}>*</span>
                  </span>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    name="mobileNumber"
                    placeholder="10 Digit Mobile Number"
                    style={{
                      width: "100%",
                      height: "47px",
                      borderRadius: "10px",
                      borderColor: "lightgray",
                      paddingLeft: "20px",
                    }}
                    value={formik.values.mobileNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.mobileNumber}
                    </div>
                  ) : null}
                </Grid>
              </Grid>

              {/* Pin Code */}
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                marginTop="16px"
              >
                <Grid item xs={12} md={6}>
                  <span style={{ fontSize: "15px", color: "#393939" }}>
                    Pin Code:<span style={{ color: "red" }}>*</span>
                  </span>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    name="pinCode"
                    placeholder="Pin Code"
                    style={{
                      width: "100%",
                      height: "47px",
                      borderRadius: "10px",
                      borderColor: "lightgray",
                      paddingLeft: "20px",
                    }}
                    value={formik.values.pinCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.pinCode && formik.errors.pinCode ? (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.pinCode}
                    </div>
                  ) : null}
                </Grid>
              </Grid>

              {/* City */}
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                marginTop="16px"
              >
                <Grid item xs={12} md={6}>
                  <span style={{ fontSize: "15px", color: "#393939" }}>
                    City:<span style={{ color: "red" }}>*</span>
                  </span>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    name="city"
                    placeholder="City"
                    style={{
                      width: "100%",
                      height: "47px",
                      borderRadius: "10px",
                      borderColor: "lightgray",
                      paddingLeft: "20px",
                    }}
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.city}
                    </div>
                  ) : null}
                </Grid>
              </Grid>

              {/* Enquiry For */}
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                marginTop="16px"
              >
                <Grid item xs={12} md={6}>
                  <span style={{ fontSize: "15px", color: "#393939" }}>
                    Enquiry For:<span style={{ color: "red" }}>*</span>
                  </span>
                </Grid>
                <Grid item xs={12} md={6}>
                  <select
                    name="enquiry"
                    style={{
                      width: "100%",
                      height: "47px",
                      borderRadius: "10px",
                      borderColor: "lightgray",
                      paddingLeft: "20px",
                    }}
                    value={formik.values.enquiry}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" label="Select Enquiry" />
                    <option value="general" label="General" />
                    <option value="technical" label="Technical" />
                    <option value="support" label="Support" />
                  </select>
                  {formik.touched.enquiry && formik.errors.enquiry ? (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.enquiry}
                    </div>
                  ) : null}
                </Grid>
              </Grid>

              {/* Submit Button */}
              <Grid container marginTop="16px">
                <Grid item>
                  <button
                    type="submit"
                    style={{
                      width: "80px",
                      height: "40px",
                      color: "#fff",
                      fontFamily: "sans-serif",
                      borderColor: "transparent",
                      backgroundColor: "#F93A0B",
                      borderRadius: "20px",
                    }}
                  >
                    Submit
                  </button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}

export default Contact;
