import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Transactiontable from "../screen/Transactiontable";
import Api from "../apiconfig/Api";

const columns = [
  { id: "serialNumber", label: "Sr.No.", minWidth: 50 },
  { id: "user_id", label: "MemberID", minWidth: 100 },
  { id: "Rem_name", label: "MemberName", minWidth: 100 },
  { id: "total_debit", label: "Debit", minWidth: 100 },
  { id: "total_credit", label: "Credit", minWidth: 100 },
  { id: "balance", label: "Balance", minWidth: 100 },
  { id: "viewDetails", label: "View Details", minWidth: 120 },
];

const Wallet = () => {
  const [data, setData] = useState([]);
  const [viewMode, setViewMode] = useState("table");
  const [currentRow, setCurrentRow] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const Name = localStorage.getItem("Name");
        const MemberID = localStorage.getItem("UserId");
        const Balance = localStorage.getItem("Balance");

        const response = await axios.get(Api.GET_TRANSACTIONS_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const transactions = response.data.data;

        // Calculate total debit for transactions with mode "CASH DEBIT"
        const totalDebit = transactions
          .filter((transaction) => transaction.mode === "CASH DEBIT")
          .reduce((sum, transaction) => sum + transaction.tranAmt, 0);

        // Set the data with default debit value, balance used for both total_credit and balance
        const updatedData = {
          total_debit: totalDebit, // Calculated total debit
          total_credit: Balance, // Setting total_credit as the Balance value from localStorage
          Rem_name: Name, // Updating MemberName with Name from localStorage
          user_id: MemberID, // Updating MemberID with UserId from localStorage
          balance: Balance, // Updating Balance with Balance from localStorage
        };

        setData([updatedData]); // Wrapping in an array for table format
        setCurrentRow(updatedData); // Set current row data
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchData();
  }, []);

  const handleViewDetails = () => {
    setViewMode("listing");
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {viewMode === "table" ? (
        <>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        color: "black",
                        fontWeight: "700",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentRow && (
                  <TableRow hover role="checkbox" tabIndex={-1} key={currentRow.id}>
                    {columns.map((column) => {
                      const value =
                        column.id === "serialNumber"
                          ? 1
                          : currentRow[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "viewDetails" ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleViewDetails}
                            >
                              View
                            </Button>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography variant="h6" align="center" sx={{ padding: 2 }}>
          {/* Placeholder for listing view */}
        </Typography>
      )}
      {viewMode === "listing" && (
        <div>
          <Transactiontable />
        </div>
      )}
    </Paper>
  );
};

export default Wallet;
