
import React, { useState, useEffect, forwardRef } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Modal,
  Typography,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Api from "../apiconfig/Api";
import Commontransition from "../commoncomponents/Commontransition";
import { initiatePayout } from "../payout/Payoutapi";
import useCommonBackground from "../hook/Commonhook";

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref || null} {...props} />;
});

const style = {
  Container: {
    marginTop: "60px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    gap: 40,
    backgroundColor: "#fff",
    boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
    padding: "20px 70px 40px",
  },
  Buttoncontainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 30,
  },
  SubmitButtonstyle: {
    backgroundColor: "#0B8405",
    padding: "12px 20px",
    borderRadius: "25px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    borderColor: "transparent",
    cursor: "pointer",
  },
  ResetButtonstyle: {
    backgroundColor: "#DE2A2A",
    padding: "12px 20px",
    borderRadius: "25px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    borderColor: "transparent",
    cursor: "pointer",
  },
};

function NewPayout() {
  useCommonBackground();
  const [open, setOpen] = useState(false);
  const [transactionMode, setTransactionMode] = useState("");
  const [mpinOpen, setMpinOpen] = useState(false);
  const [mpin, setMpin] = useState(""); // Store MPIN input
  const [loading, setLoading] = useState(false);

  const PayloadData = {
    remitterName: localStorage.getItem("Name"),
    debitAccountNumber: "86199819013",
    currency: "INR",
    tellerBranch: "41101",
    tellerID: "9903",
    Phone: localStorage.getItem("Phone"),
    emailId: localStorage.getItem("email"),
    // paymentDescription: "1T45541",
  }

  useEffect(() => {
    setOpen(true);
  }, []);

  const validationSchema = Yup.object().shape({
    accountNumber: Yup.string().required("Account Number is required"),
    ifsc: Yup.string().required("IFSC Code is required"),
    amount: Yup.number()
      .typeError("Amount must be a number")
      .required("Amount is required"),
    beneficiaryName: Yup.string().required("Beneficiary Name is required"),
    messageType: transactionMode === "RTGS" ? Yup.string().required("Message Type is required") : Yup.string(),
    paymentDescription: Yup.string().required("Remark is required"),
  });

  const formik = useFormik({
    initialValues: {
      accountNumber: "",
      ifsc: "",
      amount: "",
      beneficiaryName: "",
      messageType: "",
      paymentDescription: ""
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: async () => {
      setMpinOpen(true);
    },
  });

  const verifyMpinAndPayout = async () => {
    setLoading(true);
    const token = localStorage.getItem("authToken");

    const payload = { mpin: mpin.trim() };

    try {
      // Set up the timeout (1s)
      const timeout = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Invalid MPIN. Please try again.")), 1000)
      );

      // API request with timeout
      const mpinResponse = await Promise.race([
        axios.post(Api.VERIFY_MPIN_URL, payload, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        timeout,
      ]);

      console.log("MPIN API Response:", mpinResponse?.data);

      // Validate response
      if (mpinResponse?.data?.id) {
        console.log("✅ MPIN Verified Successfully!");

        console.log("Formik Values:", formik.values);


        const payoutPayload = {
          tellerBranch: PayloadData.tellerBranch,
          tellerID: PayloadData.tellerID,
          remitterName: PayloadData.remitterName,
          debitAccountNumber: PayloadData.debitAccountNumber,
          creditAccountNumber: formik.values.accountNumber,
          beneficiaryIFSC: formik.values.ifsc,
          amount: Number(formik.values.amount),
          currency: PayloadData.currency,
          beneficiaryName: formik.values.beneficiaryName,
          paymentDescription: formik.values.paymentDescription,
          beneficiaryAddress: "JODHPUR",
          emailId: PayloadData.emailId,
          mobileNo: PayloadData.Phone,
          messageType: "payment",
          transactionType: transactionMode,
        };

        const payoutResponse = await initiatePayout(payoutPayload);
        console.log("Payout API Response:", payoutResponse);

        if (payoutResponse.success) {
          alert(`✅ ${payoutResponse.message}`);
          formik.resetForm();
          window.location.reload(); // Reload the page when "OK" is clicked on the
        } else {
          console.error("❌ Payout failed:", payoutResponse.message);
          alert(`❌ ${payoutResponse.message}`);
          formik.resetForm();
          window.location.reload(); // Reload the page when "OK" is clicked on the alert
        }
      } else {
        console.error("❌ MPIN verification failed:", mpinResponse?.data);
      }
    } catch (error) {
      console.error("⚠️ Error in MPIN verification:", error);
      alert(error.message || "Error processing transaction. Please try again.");
    } finally {
      setLoading(false);
      setMpinOpen(false);
    }
  };




  const handleChange = (event) => {
    setTransactionMode(event.target.value);
    setOpen(false);
  };

  const renderFields = () => {
    let fields = [
      { name: "accountNumber", label: "Account Number" },
      { name: "ifsc", label: "IFSC Code" },
      { name: "amount", label: "Amount" },
      { name: "beneficiaryName", label: "Beneficiary Name" },
      { name: "paymentDescription", label: "Remark" },
    ];

    if (transactionMode === "RTGS") {
      fields.push({ name: "messageType", label: "Message Type" });
    }

    return fields.map((field) => (
      <Grid item xs={12} md={12} key={field.name} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "50px" }}>
        <span style={{ color: "black", fontFamily: "sans-serif", minWidth: "200px", textAlign: "right" }}>
          {field.label} <span style={{ color: "red" }}>*</span>
        </span>
        <TextField
        sx={{ width: 900 }}
        variant="outlined"
        name={field.name}
        value={formik.values[field.name]}
        onChange={(e) => {
          if (field.name === 'ifsc') {
            // Remove any special characters from the IFSC code
            const filteredValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase(); // Only alphanumeric characters
            formik.setFieldValue(field.name, filteredValue);
          } else {
            formik.handleChange(e);
          }
        }}
        onBlur={formik.handleBlur}
        error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
        helperText={formik.touched[field.name] && formik.errors[field.name]}
        autoComplete="off" // Disable autocomplete
      />
      </Grid>
    ));
  };

  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} keepMounted>
        <DialogTitle>{"Please Select Your Transaction Mode"}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Transaction Mode</InputLabel>
            <Select value={transactionMode} onChange={handleChange}>
              <MenuItem value="IMPS">IMPS</MenuItem>
              {/* <MenuItem value="NEFT">NEFT</MenuItem> */}
              <MenuItem value="IFT">IFT</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
      </Dialog>
      <Commontransition text={"New Payout Panel"} transition={"Xpress DMT"} onClick={() => window.location.reload()} />

      <div style={style.Container}>
        {transactionMode && <Grid container spacing={2}>{renderFields()}</Grid>}

        <div style={style.Buttoncontainer}>
          <button style={style.SubmitButtonstyle} onClick={formik.handleSubmit}>Submit</button>
          <button style={style.ResetButtonstyle} onClick={formik.handleReset}>Reset</button>
        </div>

        <Modal style={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={mpinOpen} onClose={() => setMpinOpen(false)}>
          <Box sx={{ p: 4, width: 400, margin: "auto", backgroundColor: "white", borderRadius: "10px", boxShadow: 24 }}>
            <Typography variant="h6">Verify MPIN *</Typography>
            <TextField label="MPIN *" autoComplete="off" variant="outlined" fullWidth type="text" style={{ marginTop: "20px" }} value={mpin}
              onChange={(e) => setMpin(e.target.value)} />
            <Button variant="contained" style={{ marginTop: "30px" }} fullWidth onClick={verifyMpinAndPayout} disabled={loading}>{loading ? 'Verifying...' : 'VERIFY'}</Button>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default NewPayout;


// NEW CODE 

























