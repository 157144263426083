import { useEffect } from "react";
import Footer from "../footer/Footer";
import Commoncard from "../commoncomponents/Commoncard";
import Navbar from "../components/Navbar";
import Carouselhomepage from "../components/Carouselhomepage";
import Homemiddlesection from "../components/Homemiddlesection";
import Homeuserdetail from "../components/Homeuserdetail";
import Bankcarousel from "../components/Bankcarousel";
// import Homedetailcontainet from "../components/Homedetailcontainet";
import Clientsay from "../components/Clientsay";
import Packages from "../components/Packages";
// import Frequentlyasked from "../components/Frequentlyasked";
import Whatsappgroup from "../screen/Whatsappgroup";
import Timeline from "../components/Timeline";


function Homepage() {

  useEffect(() => {
    // Disable right-click context menu
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    // Disable specific keyboard shortcuts
    const disableShortcuts = (e) => {
      if (
        e.key === "F12" || // F12 - Developer tools
        (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I - Developer tools
        (e.ctrlKey && e.shiftKey && e.key === "J") || // Ctrl+Shift+J - Console
        (e.ctrlKey && e.key === "U") // Ctrl+U - View Source
      ) {
        e.preventDefault();
      }
    };

    // Add event listeners
    document.addEventListener("contextmenu", disableRightClick); // Disable right-click
    document.addEventListener("keydown", disableShortcuts); // Disable specific shortcuts

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableShortcuts);
    };
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
      }}
    >
      <Navbar />
      <Carouselhomepage />
      <Homemiddlesection />

      <Homeuserdetail />
      <Timeline/>
      <Bankcarousel />
      <div
        style={{
          display: "flex",
          marginTop: "-40px",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f0f0f0",
        }}
      >
        <Commoncard />
      </div>
      <Clientsay />
      <Packages />
      <Whatsappgroup />
      <Footer />
    </div>
  );
}

export default Homepage;
