// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    font-family: sans-serif;
    margin-top: 70px;
    text-align: center;
}

.vertical-timeline::before,
.vertical-timeline::after {
    background: transparent !important;
    border: 2px dotted #28a4ba !important; 
    height: 78% !important; /* Keep the height */
    width: 0 !important; /* Set width to 0 to remove the line */
    top: auto !important;
}

.vertical-timeline-element-icon {
    border-radius: 50%; 
    border: 2px dotted #28a4ba;
    box-shadow: none !important; /* Removes any shadow */
    -webkit-box-shadow: none !important; /* Removes webkit-specific shadow */
     background-color: white;
}
.vertical-timeline-element-content-arrow {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 20px solid transparent;
    /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); */
    border-right: 7px solid #ffff;
}`, "",{"version":3,"sources":["webpack://./src/components/Timeline.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;;IAEI,kCAAkC;IAClC,qCAAqC;IACrC,sBAAsB,EAAE,oBAAoB;IAC5C,mBAAmB,EAAE,sCAAsC;IAC3D,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;IAC1B,2BAA2B,EAAE,uBAAuB;IACpD,mCAAmC,EAAE,mCAAmC;KACvE,uBAAuB;AAC5B;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,SAAS;IACT,QAAQ;IACR,8BAA8B;IAC9B,iDAAiD;IACjD,6BAA6B;AACjC","sourcesContent":[".App {\r\n    font-family: sans-serif;\r\n    margin-top: 70px;\r\n    text-align: center;\r\n}\r\n\r\n.vertical-timeline::before,\r\n.vertical-timeline::after {\r\n    background: transparent !important;\r\n    border: 2px dotted #28a4ba !important; \r\n    height: 78% !important; /* Keep the height */\r\n    width: 0 !important; /* Set width to 0 to remove the line */\r\n    top: auto !important;\r\n}\r\n\r\n.vertical-timeline-element-icon {\r\n    border-radius: 50%; \r\n    border: 2px dotted #28a4ba;\r\n    box-shadow: none !important; /* Removes any shadow */\r\n    -webkit-box-shadow: none !important; /* Removes webkit-specific shadow */\r\n     background-color: white;\r\n}\r\n.vertical-timeline-element-content-arrow {\r\n    content: '';\r\n    position: absolute;\r\n    top: 16px;\r\n    right: 100%;\r\n    height: 0;\r\n    width: 0;\r\n    border: 20px solid transparent;\r\n    /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); */\r\n    border-right: 7px solid #ffff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
