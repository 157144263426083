
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import useCommonBackground from '../../hook/Commonhook';

const style = {
    container: {
        display: "flex",
        flexDirection: "column"
    },
    childrenContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        width: "100%",
        padding: "25px",
        marginTop: "20px"
    },
    Imagecontainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    Buttoncontainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "20vh"
    }



}

function Qrcoderesetadmin() {
    useCommonBackground();

    const [isLoading, setIsLoading] = useState(false);
    const [qrCodeImage, setQrCodeImage] = useState(null);

    const generateQrCode = async () => {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/auth/2fa/generate`;
        const token = localStorage.getItem("adminAuthToken");

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data); // Log to check response structure

                const base64Image = data.qrCodeDataUrl; // Corrected key from API response
                console.log(base64Image); // Log to check the base64 string

                if (base64Image) {
                    setQrCodeImage(base64Image);
                } else {
                    console.error("No QR code image returned from the API");
                }
            } else {
                throw new Error('Failed to generate QR code');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false); // Re-enable the button after the request is complete
        }
    };

    return (
        <div style={style.container}>
            <div style={style.childrenContainer}>
                <h3 style={{ color: "black" }}>{"Qr Code generate"}</h3>
            </div>

            {qrCodeImage ? (
                <div style={style.Imagecontainer}>
                    <img
                        src={qrCodeImage}
                        alt="Generated QR Code"
                        style={{ width: "15%" }}
                        // style={{ maxWidth: '500px', maxHeight: '500px' }}
                        onError={() => console.error('Failed to load image')}
                    />
                </div>
            ) : (
                <p>No QR code available</p>
            )}

            <div style={style.Buttoncontainer}>
                <Button
                    variant="contained"
                    sx={{ padding: "20px 100px", borderRadius: "30px" }}
                    onClick={generateQrCode}
                    disabled={isLoading}
                >
                    {isLoading ? 'Generating...' : 'Generate Qr Code'}
                </Button>
            </div>


        </div>
    );
}

export default Qrcoderesetadmin;










