import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import Shap from "../assets/shap.png";
import Button from "@mui/material/Button";
import Back from "../assets/back.jpg";

function Packages() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: '40px',
        marginRight: '40px',
        marginTop: "40px",
      }}
    >
      <span style={{ fontSize: "30px", color: "#642c8b", fontWeight: "bold" }}>
        Pricing
      </span>
      <span style={{ color: "black", fontSize: "30px" }}>Our Package Plan</span>

      <Grid container spacing={2} style={{ marginTop: "40px" }}>
        <Grid item xs={12} sm={12} md={4}>
          <Card
            style={{
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              width: "90%",
              maxWidth: "400px", // Set a maximum width for the card
              height: "85%",
              margin: "0 auto", // Center the card in the grid item
            }}
          >
            <CardContent style={{ padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "#642C88",
                  padding: "20px 0",
                }}
              >
                <h3 style={{ color: "#fff" }}>Professional</h3>
              </div>

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <img
                  src={Shap}
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: "0px",
                    zIndex: 2,
                  }}
                  alt="Shap"
                />

                <img
                  style={{ width: "100%", marginTop: "-3px" }}
                  src={Back}
                  alt="Back"
                />

                <Typography
                  style={{
                    position: "absolute",
                    color: "#333333",
                    fontSize: "32px",
                    fontWeight: "bold",
                    zIndex: 3,
                    top: "25%",
                    left: "44%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  999 + 18% GST
                </Typography>
              </div>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  top: "-110px",
                }}
              >
                <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                  + Full Commission
                </span>
                <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                  + Free All New services
                </span>
                <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                  + Mobile App
                </span>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "70px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      width: "200px",
                      padding: "15px",
                      color: "black",
                      backgroundColor: "#F9C301",
                      fontSize: "18px",
                      borderRadius: "13px",
                      fontWeight: "bold",
                    }}
                  >
                    Select
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Card
            style={{
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              width: "90%",
              maxWidth: "400px", // Set a maximum width for the card
              height: "85%",
              margin: "0 auto", // Center the card in the grid item
            }}
          >
            <CardContent style={{ padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "#642C88",
                  padding: "20px 0",
                }}
              >
                <h3 style={{ color: "#fff" }}>Entrepreneur</h3>
              </div>

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <img
                  src={Shap}
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: "0px",
                    zIndex: 2,
                  }}
                  alt="Shap"
                />

                <img
                  style={{ width: "100%", marginTop: "-3px" }}
                  src={Back}
                  alt="Back"
                />

                <Typography
                  style={{
                    position: "absolute",
                    color: "#333333",
                    fontSize: "32px",
                    fontWeight: "bold",
                    zIndex: 3,
                    top: "25%",
                    left: "44%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  2999 + 18% GST
                </Typography>
              </div>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  top: "-110px",
                }}
              >
                <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                  + Full Commission
                </span>
                <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                  + Free All New services
                </span>
                <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                  + Mobile App
                </span>
                <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                  + Branding Kit (Banners)
                </span>
                <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                  + Free IRCTC
                </span>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      width: "200px",
                      padding: "15px",
                      color: "black",
                      backgroundColor: "#F9C301",
                      fontSize: "18px",
                      borderRadius: "13px",
                      fontWeight: "bold",
                    }}
                  >
                    Select
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Card
            style={{
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              width: "90%",
              maxWidth: "400px", // Set a maximum width for the card
              height: "85%",
              margin: "0 auto", // Center the card in the grid item
            }}
          >
            <CardContent style={{ padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "#642C88",
                  padding: "20px 0",
                }}
              >
                <h3 style={{ color: "#fff" }}>Standard</h3>
              </div>

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <img
                  src={Shap}
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: "0px",
                    zIndex: 2,
                  }}
                  alt="Shap"
                />

                <img
                  style={{ width: "100%", marginTop: "-3px" }}
                  src={Back}
                  alt="Back"
                />

                <Typography
                  style={{
                    position: "absolute",
                    color: "#333333",
                    fontSize: "32px",
                    fontWeight: "bold",
                    zIndex: 3,
                    top: "25%",
                    left: "44%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  4999 + 18% GST
                </Typography>
              </div>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  top: "-110px",
                }}
              >
                <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                  + Full Commission
                </span>
                <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                  + Free All New services
                </span>
                <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                  + Mobile App
                </span>
                <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                  + Branding Kit (Banners)
                </span>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      width: "200px",
                      padding: "15px",
                      color: "black",
                      backgroundColor: "#F9C301",
                      fontSize: "18px",
                      borderRadius: "13px",
                      fontWeight: "bold",
                    }}
                  >
                    Select
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default Packages;
