import React from "react";
import "./Footer.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Logo from "../assets/logo.png";
import Telephone from "../assets/telephone.png";
import Mail from "../assets/mail.png";
import Location from "../assets/location.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-section ">
      <div className="container">
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-3 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <div style={{ backgroundColor: "#ddd", width: "200px" }}>
                    <img src={Logo} className="img-fluid" alt="logo" />
                  </div>
                </div>
                <div className="footer-text">
                  <p
                    style={{
                      color: "white",
                      fontSize: "15px",
                      textAlign: "justify",
                    }}
                  >
                    we provide all online services like Mobile, DTH and Data
                    Card Recharges, Postpaid Bill Payment, Electricity Bill
                    Payment, Landline Bill Payment, Hotel Booking services
                    System, Uti Pan Card, Payout Services Provider and Many
                    More.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3 style={{ fontSize: "30px" }}>Useful Links</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/Company">Company</Link>
                  </li>
                  <li>
                    <Link to="/Services">Services</Link>
                  </li>
                  <li>
                    <Link to="/Careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/Career">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Downloads</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Term & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3 style={{ fontSize: "30px" }}>Our Services</h3>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3 style={{ fontSize: "30px" }}>Locate Us</h3>
                </div>
                <ul>
                  <li>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 5 }}
                    >
                      <img src={Telephone} alt="telephone" />
                      <Link style={{ fontSize: "20px" }}>:8619981901</Link>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 5 }}
                    >
                      <img src={Mail} alt="mail" />
                      <Link style={{ fontSize: "20px" }}>
                        :info@zevopay.com
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 5 }}
                    >
                      <img
                        style={{ width: "30px", height: "30px" }}
                        src={Location}
                        alt="Location"
                      />
                      <Link style={{ fontSize: "15px" }}>
                        :PLOT NO. 226, Z S A, BJS COLONY, JODHPUR RAJASTHAN-
                        342001
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright-area">
        <div className="container">
          <p style={{ color: "#fff" }}>
            Copyright &copy; 2018, All Right Reserved <a to="#">Zevopay</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
