import React from "react";
import Button from "@mui/material/Button";

function Whatsappgroup() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "97px 0",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundColor: "#f1f6fe",
      }}
    >
      <h4
        style={{
          fontSize: "33px",
          fontWeight: "600",
          color: "#333333",
          margin: "0 0 24px 0",
          padding: "0",
          lineHeight: "1",
        }}
      >
        Join Our WhatsApp Group
      </h4>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width:"100%"
        }}
      >
     
        <input 
        style={{padding:"23px", width:"400px", border:"transparent"}}
        placeholder="Enter Mobile Number" />

        <Button variant="contained" style={{padding:"20px 30px", background:"#062241" }}>Join</Button>
      </div>
    </div>
  );
}

export default Whatsappgroup;
