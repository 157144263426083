// import React, { useEffect } from "react";
// import Navbar from "../components/Navbar";
// import Footer from "../footer/Footer";
// function Commonpage() {
//   useEffect(() => {
//     // Apply background color to the body element
//     document.body.style.backgroundColor = "#fff";

//     // Clean up the effect when the component unmounts
//     return () => {
//       document.body.style.backgroundColor = ""; // Revert back to default background color
//     };
//   }, []);
//   return (
//     <div style={{ display: "flex", flexDirection: "column" }}>
//       <Navbar />
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           minHeight: "50vh",
//         }}
//       >
//         <h2
//           style={{
//             fontSize: "30px",
//             fontWeight: "500",
//             color: "inherit",
//             lineHeight: "1.1",
//           }}
//         >
//           All Servcies's
//         </h2>
//       </div>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           marginTop: "-110px",
//           paddingLeft: "100px",
//           paddingRight: "100px",
//         }}
//       >
//         <h3>
//           {" "}
//           <strong>
//             {" "}

//             <u>A</u>
//             <u>adhar </u>
//             <u>E</u>
//             <u>nable </u>
//             <u>P</u>
//             <u>ayment </u>
//             <u>S</u>
//             <u>ystem</u>
//           </strong>
//         </h3>
//         <p style={{ fontSize: "22px", color: "#333333" }}>
//           If a customer wants to make&nbsp;Cash Withdrawal, he/she must be an
//           Aadhaar linked bank account holder in the first place. He/she simply
//           has to provide the Retailer their 12-digit Aadhaar Number along with
//           the Aadhaar linked Bank name. The retailer will help you avail of the
//           cash withdrawal service after your Fingerprint authorization using a
//           biometric device.
//           <br></br>
//           The maximum amount is limited to Rs 10,000/- per transaction. For cash
//           withdrawal, the monthly limit is Rs 50,000 or 10 transactions,
//           whichever is reached earlier.
//         </p>

//         <p style={{ fontSize: "20px", marginTop: "30px" }}>
//           <strong style={{ fontWeight: "700" }}>
//             The process of using the service is easy. You have to follow these
//             steps:
//           </strong>
//         </p>
//         <p style={{ fontSize: "20px", color: "#333333", marginTop: "30px" }}>
//           Visit an our business correspondent in your area.
//         </p>
//         <div style={{ paddingLeft: "100px" }}>
//           <p style={{ fontSize: "20px", color: "#333333" }}>
//             Provide the 12-digit Aadhaar number and the Bank name to the agent.
//             (Doesn’t matter you remember your bank account number while making
//             transactions using AEPS).
//           </p>
//           <p style={{ fontSize: "20px", color: "#333333" }}>
//             Select transaction type i.e. Cash Withdrawal.
//           </p>
//           <p style={{ fontSize: "20px", color: "#333333" }}>
//             Enter the transaction amount (as applicable)
//           </p>
//           <p style={{ fontSize: "20px", color: "#333333" }}>
//             Verify the transaction process using biometrics (fingerprint
//             impression)
//           </p>
//           <p style={{ fontSize: "20px", color: "#333333" }}>
//             The transaction gets completed within seconds.
//           </p>
//         </div>
//         <p
//           style={{
//             fontSize: "20px",
//             color: "#333333",
//             fontWeight: "500",
//             marginTop: "40px",
//           }}
//         >
//           The bank correspondent receives a transaction receipt while the
//           customer (you) receive an SMS confirmation from your bank.
//         </p>
//         <h3 style={{ marginTop: "30px" }}>
//           <strong>
//             <u>Micro ATM</u>
//           </strong>
//         </h3>
//         <p
//           style={{
//             fontSize: "20px",
//             color: "#333333",
//             fontWeight: "500",
//             marginTop: "40px",
//           }}
//         >
//           Micro ATM is an NPCI leadership model that allows customers to perform
//           basic banking transactions using a Point of Sale (POS) device
//           (micro-ATM) through a representative.
//         </p>
//         <p
//           style={{
//             fontSize: "20px",
//             color: "#333333",
//             fontWeight: "500",
//             marginTop: "1px",
//           }}
//         >
//           A micro ATM is a small machine that looks like a card swiping machine
//           but is capable of providing basic banking facilities. Such ATMs are
//           very beneficial where they facilitate people who cannot install normal
//           ATMs. A micro ATM is a portable device and can be easily carried in
//           one hand. Officials can take these machines to remote areas and
//           provide basic banking facilities to the people there.
//         </p>
//         <p
//           style={{
//             fontSize: "20px",
//             color: "#333333",
//             fontWeight: "500",
//             marginTop: "1px",
//           }}
//         >
//           <strong>Micro ATM Commonpage provided:-</strong>
//         </p>
//         <ul>
//           <div style={{ marginLeft: "170px", marginTop: "20px" }}>
//             <p style={{ fontSize: "20px", color: "#333333" }}>
//               Balance inquiry
//             </p>

//             <p style={{ fontSize: "20px", color: "#333333" }}>
//               Cash Withdrawal
//             </p>

//             <p style={{ fontSize: "20px", color: "#333333" }}>Cash Deposit</p>
//           </div>
//         </ul>

//         <h3 style={{ marginTop: "30px" }}>
//           <strong>
//             <u>Mini Statement</u>
//           </strong>
//         </h3>
//         <span style={{ fontSize: "20px", color: "#333333", marginTop: "40px" }}>
//           “Mini Statement” is the service where the customers are offered a mini
//           statement for their transactions i.e. cash withdrawals and deposits.
//           In brief, the account holder can now check all their transactions
//           using the Aadhaar Card. So this is a great service which can help the
//           customers and merchants both in their special way.
//         </span>
//         <h3 style={{ marginTop: "30px" }}>
//           <strong>
//             <u>Aadhaar Pay</u>
//           </strong>
//         </h3>
//         <span style={{ fontSize: "20px", color: "#333333", marginTop: "40px" }}>
//           Aadhaar Pay is one of the latest Commonpage that’s quite trending these
//           days in the AEPS industry. It’s quite similar to AEPS however, a
//           little more innovative. By using Aadhaar pay, a bank account holder
//           can make a withdrawal of any amount i.e. there is no limit. Certainly,
//           now the customer can withdraw any sum regardless of Rs 50,000 in a
//           day. That’s the primary reason the service is quite preferred by a lot
//           of people.
//         </span>
//         <h3 style={{ marginTop: "60px" }}>
//           <strong>
//             <u>BBPS</u>
//           </strong>
//         </h3>
//         <span
//           style={{
//             marginTop: "65px",
//             fontSize: "18px",
//             fontFamily: "GilmerRegular, sans-serif",
//             color: "rgb(18, 18, 18)",
//             textAlign: "center",
//           }}
//         >
//           Introducing Bharat Bill Payment System (BBPS), an RBI-mandated system
//           that provides reliable, secure and integrated bill-paying Commonpage
//           across geographies. ZevoPay offers BBPS Commonpage in its revolutionary
//           platform that provides unparalleled convenience with a simple
//           interface and seamless integration to manage your bill payments.Track
//           and manage your payments swiftly and securely, whether it is to
//           recharge your mobile prepaid/postpaid plan, book LPG cylinder, pay
//           utility bills, invest in RD, pay taxes or credit card bills and loans,
//           or pay hospital bills, club subscriptions and education fees. Receive
//           timely reminders, optimise your budget and track payment history
//           effortlessly with ZevoPay.
//         </span>
//       </div>
//       <br></br>
//       <br></br>

//     <Footer/>
//     </div>
//   );
// }

// export default Commonpage;




import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../footer/Footer";
function Commonpage() {
  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#fff";

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Revert back to default background color
    };
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
        }}
      >
        <h2
          style={{
            fontSize: "30px",
            fontWeight: "500",
            color: "inherit",
            lineHeight: "1.1",
          }}
        >
          All Servcies's
        </h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "-110px",
          paddingLeft: "100px",
          paddingRight: "100px",
        }}
      >
        <h3>
          {" "}
          <strong>
            {" "}

            <u>Rajasthan</u>
            {" "}
            <u>State </u>
            <u>Road</u>
            <u>Transport </u>
            <u>Corporation</u>

          </strong>
        </h3>
        <p style={{ fontSize: "22px", color: "#333333" }}>
          Rajasthan State Road Transport Corporation (RSRTC) is the official state-owned transport service of Rajasthan, India. Established on October 1, 1964, RSRTC operates under the Government of Rajasthan with the primary objective of providing efficient, safe, and affordable bus transportation to the residents and visitors of the state.
        </p>

        <p style={{ fontSize: "20px", marginTop: "30px" }}>
          <strong style={{ fontWeight: "700" }}>
          Online Ticket Booking:
          </strong>
        </p>
        <p style={{ fontSize: "20px", color: "#333333", marginTop: "30px" }}>
        RSRTC has embraced modern technology by offering an online ticket booking platform. Travelers can book tickets conveniently through its official website or mobile app, where they can view bus schedules, seat availability, and fares. The platform also supports cancellations and refunds, making it user-friendly and accessible.
        </p>
        <h3 style={{ marginTop: "30px" }}>
          <strong>
            <u>Network and Connectivity:</u>
          </strong>
        </h3>
        <p
          style={{
            fontSize: "20px",
            color: "#333333",
            fontWeight: "500",
            marginTop: "40px",
          }}
        >
        RSRTC boasts an extensive network of routes, connecting Rajasthan's major tourist attractions like Jaipur, Udaipur, Jodhpur, Jaisalmer, and Mount Abu. It also provides transportation to pilgrimage destinations such as Ajmer Sharif, Pushkar, and Nathdwara. For interstate travelers, RSRTC buses are a reliable means of travel to cities like Delhi, Ahmedabad, Chandigarh, and Agra.
        </p>
     
        <h3 style={{ marginTop: "30px" }}>
          <strong>
            <u>Environmental Initiatives:</u>
          </strong>
        </h3>
        <span style={{ fontSize: "20px", color: "#333333", marginTop: "40px" }}>
        RSRTC has taken steps toward sustainability by introducing eco-friendly buses, adopting modern emission norms, and improving fuel efficiency. These efforts reflect its commitment to reducing the environmental impact of its operations.
        </span>
        <h3 style={{ marginTop: "60px" }}>
          <strong>
            <u>Hotel Booking Service</u>
          </strong>
        </h3>
        <span
          style={{
            marginTop: "65px",
            fontSize: "18px",
            fontFamily: "GilmerRegular, sans-serif",
            color: "rgb(18, 18, 18)",
            textAlign: "center",
          }}
        >
        Our hotel booking service offers a seamless and reliable platform for travelers to find and reserve accommodations that suit their needs and budget. Whether you're planning a leisure trip, a business journey, or a family vacation, we provide access to a wide range of hotels, from luxurious five-star properties to budget-friendly options.
        </span>
      </div>
      <br></br>
      <br></br>

      <Footer />
    </div>
  );
}

export default Commonpage;














