import React from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

function Commontransition({ text, transition, onClick }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        width: "100%",
        padding: "25px",
        marginTop: "20px",
      }}
    >
      <h3 style={{ color: "#666666" }}>{text ? text : "Dashboard"}</h3>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          padding: "0  30px",
          height: "60px",
          backgroundColor: "#F8F8FB",
          borderRadius: "30px",
        }}
      >
        <HomeOutlinedIcon style={{ color: "gray" }} />
        <span
          onClick={onClick}
          className="hover-home"
          style={{ color: "gray", cursor: "pointer" }}
        >
          Home
        </span>
        <span style={{ color: "gray" }}>{`>`}</span>
        <span>{transition ? transition : "Dashboard"}</span>
      </div>
    </div>
  );
}

export default Commontransition;
