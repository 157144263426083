import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const columns = [
    { id: 'sno', label: 'S.No', minWidth: 30 },
    { id: 'memberId', label: 'MemberId', minWidth: 100 },
    { id: 'username', label: 'User Name', minWidth: 100 },
    { id: 'email', label: 'Email', minWidth: 100 },
    { id: 'activity', label: 'Activity', minWidth: 100 },
    { id: 'iplog', label: 'I/P Add.', minWidth: 100 },
    { id: 'device', label: 'Device', minWidth: 100 },
    { id: 'status', label: 'Status', minWidth: 100 },
    { id: 'txnId', label: 'TxnId', minWidth: 100 },
    { id: 'date', label: 'Date', minWidth: 100 },
    // {
    //     id: 'population',
    //     label: 'Population',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toLocaleString('en-US'),
    // },

];

const index = 0;

function createData(sno, memberId, username, email, activity, iplog, device, status, txnId, date) {
    // const density = population / size;
    return { sno, memberId, username, email, activity, iplog, device, status, txnId, date };
}

const rows = [
    createData(index + 1, 'Z00000ST', 'Shyam jha', 'sjha23234@gmail.com', 'Login', '0.0.22.11.5', 'Mobile', 'Success', '12345678910', new Intl.DateTimeFormat("en-GB", {

        day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true,
    }).format(new Date(Date.now())),
    ),

    createData(index + 2, 'Z00000ST', 'Shyam jha', 'sjha23234@gmail.com', 'Payout', '0.0.22.11.5', 'Desktop', 'Failed', '123456555558', new Intl.DateTimeFormat("en-GB", {

        day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true,
    }).format(new Date(Date.now())),
    ),

    createData(index + 3, 'Z00000ST', 'Shyam jha', 'sjha23234@gmail.com', 'Collection', '0.0.22.11.5', 'Desktop', 'Success', '1234565562223', new Intl.DateTimeFormat("en-GB", {

        day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true,
    }).format(new Date(Date.now())),
    ),
    createData(index + 4, 'Z00000ST', 'Shyam jha', 'sjha23234@gmail.com', 'Login', '0.0.22.11.5', 'Tablet', 'Failed', '1234560000031', new Intl.DateTimeFormat("en-GB", {

        day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true,
    }).format(new Date(Date.now())),
    ),
    createData(index + 5, 'Z00000ST', 'Shyam jha', 'sjha23234@gmail.com', 'Collection', '0.0.22.11.5', 'Mobile', 'Success', '1234568889451', new Intl.DateTimeFormat("en-GB", {

        day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true,
    }).format(new Date(Date.now())),
    ),
    createData(index + 6, 'Z00000ST', 'Shyam jha', 'sjha23234@gmail.com', 'Payout', '0.0.22.11.5', 'Desktop', 'Failed', '1234566666780', new Intl.DateTimeFormat("en-GB", {

        day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true,
    }).format(new Date(Date.now())),
    ),

];



function Useractivitylogs() {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
                boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
            }}
        >
            <span
                style={{
                    paddingLeft: "20px",
                    color: "gray",
                    fontWeight: "bold",
                    marginTop: "20px",
                    fontSize: "25px",
                }}
            >
                User Activity Logs
            </span>
            <div
                style={{
                    display: "flex",
                    marginTop: "30px",
                    padding: "10px",
                    marginLeft: "150px",
                    gap: 30,
                }}
            >
                <DatePicker
                    // selected={fromDate}
                    // onChange={(date) => setFromDate(date)}
                    dateFormat="yyyy/MM/dd"
                    isClearable
                    placeholderText="From date"
                    customInput={<TextField label="From date" variant="outlined" />}
                />
                <DatePicker
                    // selected={toDate}
                    // onChange={(date) => setToDate(date)}
                    dateFormat="yyyy/MM/dd"
                    isClearable
                    placeholderText="To date"
                    customInput={<TextField label="To date" variant="outlined" />}
                />
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        marginLeft: "10px",
                        height: "56px",
                        backgroundColor: "red",
                        borderRadius: "10px",
                    }}
                // onClick={handleSearchClick}
                >
                    Search
                </Button>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "90px",
                }}
            >
                <input
                    type="text"
                    // value={searchTerm}
                    // onChange={(e) => setSearchTerm(e.target.value)}
                    style={{
                        width: "90%",
                        height: "45px",
                        borderRadius: "15px",
                        borderColor: "lightGray",
                        paddingLeft: "20px",
                    }}
                    placeholder="Search"
                />
            </div>
            <Grid
                container
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "110px",
                    padding: "40px",
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                    boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
                }}
            >
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                        //   style={{color:"red"}}
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, color:"black", fontWeight:"bold" }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow  hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell  style={{color:"black", fontWeight:"normal"}} key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
        </div>
    );
};

export default Useractivitylogs;
