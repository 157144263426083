import React from "react";
import Backgroundseen from "../assets/backgroundseen.jpg";
import Men from "../assets/men.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import "./Clientswiper.css";

function Clientsay() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "500px",
        backgroundImage: `url(${Backgroundseen})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          color: "white",
          fontSize: "24px",
          textAlign: "center",
          width: "100%", // Make sure the text stays centered
        }}
      >
        <h1>What our client say ?</h1>
        <Swiper
          navigation={true} // Enable navigation
          modules={[Navigation]} // Include the Navigation module
          className="mySwiper"
          style={{
            padding: "20px",
            backgroundColor: "transparent", // Transparent background
          }}
        >
          <SwiperSlide
            style={{
              display: "flex",
              // width:'70%',
              flexDirection: "column",
              alignItems: "center", // Center align the content
              backgroundColor: "transparent",
            }}
          >
            <img
              style={{ width: "61px", height: "61px", borderRadius: "50%" }} // Circular client image
              src={Men}
              alt="Client"
            />

            <span style={{ color: "#fff", maxWidth: "700px", marginTop: "15px" }}>Santosh kumar</span>
            <p style={{ color: "#fff", maxWidth: "700px", marginTop: "15px" }}>
              "We found ticket booking and Aadhaar services through EMitra to be very easy and convenient. The smooth navigation and fast processing on the website made our experience much better. Highly recommend their services for reliable and efficient solutions"
            </p>
          </SwiperSlide>
          <SwiperSlide
            style={{
              display: "flex",
              // width:'70%',
              flexDirection: "column",
              alignItems: "center", // Center align the content
              backgroundColor: "transparent",
            }}
          >
            <img
              style={{ width: "61px", height: "61px", borderRadius: "50%" }} // Circular client image
              src={Men}
              alt="Client"
            />

            <span style={{ color: "#fff", maxWidth: "700px", marginTop: "15px" }}>Rajeev kumar</span>

           <p style={{ color: "#fff", maxWidth: "700px", marginTop: "15px" }}>
              "We found ticket booking and Aadhaar services through EMitra to be very easy and convenient. The smooth navigation and fast processing on the website made our experience much better. Highly recommend their services for reliable and efficient solutions"
            </p>
          </SwiperSlide>
          <SwiperSlide
            style={{
              display: "flex",
              // width:'70%',
              flexDirection: "column",
              alignItems: "center", // Center align the content
              backgroundColor: "transparent",
            }}
          >
            <img
              style={{ width: "61px", height: "61px", borderRadius: "50%" }} // Circular client image
              src={Men}
              alt="Client"
            />
            <span style={{ color: "#fff", maxWidth: "700px", marginTop: "15px" }}>Mohan Das</span>
            <p style={{ color: "#fff", maxWidth: "700px", marginTop: "15px" }}>
            "We found ticket booking and Aadhaar services through EMitra to be very easy and convenient. The smooth navigation and fast processing on the website made our experience much better. Highly recommend their services for reliable and efficient solutions"
          </p>
          </SwiperSlide>
          <SwiperSlide
            style={{
              display: "flex",
              // width:'70%',
              flexDirection: "column",
              alignItems: "center", // Center align the content
              backgroundColor: "transparent",
            }}
          >
            <img
              style={{ width: "61px", height: "61px", borderRadius: "50%" }} // Circular client image
              src={Men}
              alt="Client"
            />
            <span style={{ color: "#fff", maxWidth: "700px", marginTop: "15px" }}>Shivam kashyap</span>
            <p style={{ color: "#fff", maxWidth: "700px", marginTop: "15px" }}>
            "We found ticket booking and Aadhaar services through EMitra to be very easy and convenient. The smooth navigation and fast processing on the website made our experience much better. Highly recommend their services for reliable and efficient solutions"
          </p>
          </SwiperSlide>

          {/* Add more SwiperSlides if needed */}
        </Swiper>
      </div>
    </div>
  );
}

export default Clientsay;
