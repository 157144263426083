import React from "react";
import "./Footer.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-section ">
      <div className="container">
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-2 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <div style={{ backgroundColor: "#ddd", width: "200px" }}>
                    <img src={Logo} className="img-fluid" alt="logo" />
                  </div>
                </div>
                <div className="footer-text">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              
                <div className="footer-contact-parent d-flex flex-column flex-md-row justify-content-around align-items-center vw-100">
                  <div className="footer-cta pt-5 pb-5">
                    <span>Contact us</span>
                    <div className="row">
                      <div className="col-xl-4 col-md-4 col-12 mb-30  d-flex align-items-center justify-content-center">
                        <div className="single-cta d-flex align-items-center justify-content-center">
                          <i className="fas fa-map-marker-alt"></i>
                          <div className="cta-text ms-3">
                            <h4>Find us</h4>
                            <span>Jodhpur</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4 col-12 mb-30">
                        <div className="single-cta d-flex align-items-center">
                          <i className="fas fa-phone"></i>
                          <div className="cta-text ms-3">
                            <h4>Call us</h4>
                            <span>+(91) 8619981901</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4 col-12 mb-30">
                        <div className="single-cta d-flex align-items-center">
                          <i className="far fa-envelope-open"></i>
                          <div className="cta-text ms-3">
                            <h4>Mail us</h4>
                            <span>zevosoft@gmail.com</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/Company">Company</Link>
                  </li>
                  <li>
                    <Link to="/Services">Services</Link>
                  </li>
                  <li>
                    <Link to="/Career">Career</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Contact us</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Subscribe</h3>
                </div>
                <div className="footer-text mb-25">
                  <p>
                    Don’t miss to subscribe to our new feeds, kindly fill the
                    form below.
                  </p>
                </div>
                <div
                  className="subscribe-form"
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <form action="#">
                    <input type="text" placeholder="Email Address" />
                    <button>
                      <i className="fab fa-telegram-plane"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
              <div className="copyright-text">
                <p>
                  Copyright &copy; 2018, All Right Reserved{" "}
                  <a to="#">Zevopay</a>
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
              <div className="footer-menu">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Terms</Link>
                  </li>
                  <li>
                    <Link to="#">Privacy</Link>
                  </li>
                  <li>
                    <Link to="#">Policy</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;