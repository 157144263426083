
import React, { useEffect } from "react";
import Dashboardmenubar from "../screen/Dashboardmenubar";

function Dashboard() {
  useEffect(() => {
    // Check if the page has been reloaded twice already in this session
    if (!sessionStorage.getItem("reloadCount")) {
      // Initialize reload counter
      sessionStorage.setItem("reloadCount", "0");
    }

    // Get the current reload count
    let reloadCount = parseInt(sessionStorage.getItem("reloadCount"), 10);

    if (reloadCount < 2) {
      // Increment the reload count
      reloadCount += 1;
      sessionStorage.setItem("reloadCount", reloadCount.toString());

      // Reload the page for the first or second time
      window.location.reload();
      return;
    }

    // Function to handle token expiration
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("authToken"); // Get JWT token from localStorage
      const expiryTime = localStorage.getItem("authTokenExpiry"); // Get expiration time from localStorage

      if (token && expiryTime) {
        const currentTime = Date.now();

        if (currentTime > parseInt(expiryTime)) {
          // Token expired, clear the token and session storage
          localStorage.removeItem("authToken");
          localStorage.removeItem("authTokenExpiry");
          sessionStorage.clear(); // Clear sessionStorage as well

          // Optionally redirect to login page
          window.location.href = "/";
        }
      }
    };

    // Check token expiration every second (1000 ms)
    const expirationCheckInterval = setInterval(checkTokenExpiration, 1000);

    // Check token and prevent back button when logged in
    const checkToken = () => {
      const token = localStorage.getItem("authToken");

      if (token) {
        // Push a new state so the back button doesn't work
        window.history.pushState(null, "", window.location.href);

        // Disable back navigation while the token is present
        window.onpopstate = function () {
          window.history.pushState(null, "", window.location.href);
        };

        // Store expiration time (1 minute from login)
        const expirationTime = Date.now() + 25 * 60 * 1000; // 1 minute from now
        localStorage.setItem("authTokenExpiry", expirationTime.toString());
      } else {
        // Allow back button if the token is cleared
        window.onpopstate = null;
      }
    };

    // Check token on page load
    checkToken();

    // Monitor changes to the JWT token in localStorage
    const tokenChangeHandler = () => {
      checkToken();
    };

    // Listen for changes to the JWT token in localStorage
    window.addEventListener("storage", tokenChangeHandler);

    // Block inspect element using right-click and keyboard
    const disableRightClick = (event) => {
      event.preventDefault(); // Prevent the context menu from opening
    };

    const disableKeyboardShortcuts = (event) => {
      // Disable common shortcuts for opening DevTools
      if (
        event.key === "F12" ||
        (event.ctrlKey && event.shiftKey && event.key === "I") ||
        (event.ctrlKey && event.shiftKey && event.key === "J")
      ) {
        event.preventDefault(); // Prevent default behavior (DevTools)
      }
    };

    // Add event listeners for right-click and keyboard shortcuts
    document.addEventListener("contextmenu", disableRightClick);
    document.addEventListener("keydown", disableKeyboardShortcuts);

    // Cleanup event listeners on component unmount
    return () => {
      clearInterval(expirationCheckInterval); // Clear interval on unmount
      window.removeEventListener("storage", tokenChangeHandler);
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableKeyboardShortcuts);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div>
      <Dashboardmenubar />
    </div>
  );
}

export default Dashboard;
