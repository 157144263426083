import React from 'react';
import BillLogo from "../assets/bill.png";
import FlexibaliLogo from "../assets/flexible.png";
import VerifyLogo from "../assets/verify.png";
import LaptopLogo from "../assets/laptop.png";
import TransferLogo from "../assets/transfer.png";
import BussinessLogo from "../assets/business.png";
import "./Card.css";


const articles = [
  {
    imgSrc: BillLogo,
    title: "HBSP",
    text: "Our website provides reliable and hassle-free hotel booking services, offering a wide range of options to ensure comfort, convenience, and the best experience for your travel needs .",
    // linkText: "Here's why",
    // linkHref: "https://codepen.io/collection/XdWJOQ/"
  },
  {
    imgSrc: FlexibaliLogo,
    title: "RSRTC",
    text: "Our website offers Rajasthan Roadways ticket booking services, providing a seamless and reliable platform for booking bus tickets across Rajasthan and neighboring states.",
    // linkText: "Here's how",
    // linkHref: "https://codepen.io/collection/XdWJOQ/"
  },
  {
    imgSrc: VerifyLogo,
    title: "Mobile Prepaid Recharge",
    text: "At ZevoPay , we understand that efficient travel management is crucial for businesses of all sizes. Our comprehensive travel booking services travel experiences, allowing you to focus on your core business objectives.",
    // linkText: "Read more",
    // linkHref: "https://codepen.io/collection/XdWJOQ/"
  },
  {
    imgSrc: LaptopLogo,
    title: "IRCTC",
    text: "On our website, you can easily book railway tickets through IRCTC. You can book train tickets for your journey, with the best options available for vacations and travel. With a safe and secure booking process, we provide seamless and reliable service to our users.",
    // linkText: "Just do it...",
    // linkHref: "https://codepen.io/collection/XdWJOQ/"
  },
  {
    imgSrc: TransferLogo,
    title: "PAN Services",
    text: "As part of our comprehensive B2B services, ZevoPay offers assistance with PAN (Permanent Account Number) card application and related services. We understand the importance of PAN cards for businesses and individuals in India.",

  },
  {
    imgSrc: BussinessLogo,
    title: "Payout",
    text: "B2B recharge service refers to a business-to-business service that allows businesses to recharge or top-up the prepaid accounts of their customers or employees. This service is commonly used by businesses in industries such as banking.",
 
  }
];

const Commoncard = () => {

  return (
    <div className="container py-4">
      <div className="row g-3">
        {articles.map((article, index) => (
          <div key={index} className="col-md-6 col-lg-4" style={{ display:'flex', justifyContent:"center", alignItems:"center", }}>
            <div className="card border-light shadow-sm card-hover"  style={{display:'flex', justifyContent:'center', alignItems:"center", paddingTop:"20px"}}>
              <div className='card-img-wrapper' >
              <img  style={{width:"50px", height:"50px"}} src={article.imgSrc} className="card-img-top" alt={article.title} />
              </div>
              <div className="card-body" style={{textAlign:"center"}}>
                
                <h5 className="card-title">{article.title}</h5>
                <p className="card-text">{article.text}</p>
             
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Commoncard;