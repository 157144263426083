
import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ReportIcon from '@mui/icons-material/Report';
import HomeIcon from "@mui/icons-material/Home";
import Divider from '@mui/material/Divider';
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Logo from "../../admin/admindashboard/adminassets/logo.png"
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"; // Import the AccountBalanceWalletIcon
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styles } from '../commoncomponents/colorhook/Stylehook';
import Subadminhome from '../subadminsidemenupages/Subadminhome';
import MemberlistUser from '../../admin/adminsidemenupages/MemberlistUser';
import Subadminmemberlist from '../subadminsidemenupages/Subadminmemberlist';
import Subadminnewregistration from '../subadminsidemenupages/Subadminnewregistration';
import SubadminUserqrupload from '../subadminsidemenupages/SubadminUserqrupload';
import Subadminprofilephotoupload from '../subadminsidemenupages/Subadminprofilephotoupload';
import Subadminewallet from '../subadminsidemenupages/Subadminewallet';
import Subadmincreditfund from '../subadminsidemenupages/Subadmincreditfund';
import Subadmindebitfund from '../subadminsidemenupages/Subadmindebitfund';
import Subadminreport from '../subadminsidemenupages/Subadminreport';
import Subadminnewupireport from '../subadminsidemenupages/Subadminnewupireport';
import Subadminvirtualaccountreport from '../subadminsidemenupages/Subadminvirtualaccountreport';
import Subadminsurpluspayout from '../subadminsidemenupages/Subadminsurpluspayout';
import Surpluscollection from '../../admin/adminsidemenupages/Surpluscollection';
import { useLocation } from "react-router-dom";

const drawerWidth = 270;

const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: "#F7CAC9",
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    // backgroundColor:"#F7CAC9",
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    // backgroundColor:"red",
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        // backgroundColor:"red",
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    // backgroundColor:"red",
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));


function Subadmindashboard() {
    const theme = useTheme();
    const Navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownhome, setDropdownhome] = useState(false);
    const [accessList, setAccessList] = useState([]);
    const [selectedtab, setSelectedtab] = useState(0);
    const [dropdownsetting, setDropdownsetting] = useState(false);
    const [dropdownOpenwallet, setDropdownOpenwallet] = useState(false);
    const [dropdownreport, setDropdownreport] = useState(false);
    const [dropdownsubadmin, setDropdownsubadmin] = useState(false);


    useEffect(() => {
        // Function to disable right-click context menu
        const disableRightClick = (e) => {
            e.preventDefault();
        };

        // Function to disable specific keyboard shortcuts
        const disableShortcuts = (e) => {
            if (
                e.key === "F12" || // F12 - Developer tools
                (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I - Developer tools
                (e.ctrlKey && e.shiftKey && e.key === "J") || // Ctrl+Shift+J - Console
                (e.ctrlKey && e.key === "U") // Ctrl+U - View Source
            ) {
                e.preventDefault();
            }
        };

        // Add event listeners
        document.addEventListener("contextmenu", disableRightClick); // Disable right-click
        document.addEventListener("keydown", disableShortcuts); // Disable specific shortcuts

        // Function to handle token expiration
        const checkTokenExpiration = () => {
            const token = localStorage.getItem("subadmin"); // Get JWT token from localStorage
            const expiryTime = localStorage.getItem("authTokenExpirySubadminToken"); // Get expiration time from localStorage

            if (token && expiryTime) {
                const currentTime = Date.now();

                if (currentTime > parseInt(expiryTime)) {
                    // Token expired, clear the token and log out the user
                    localStorage.removeItem("subadmin");
                    localStorage.removeItem("authTokenExpirySubadminToken");
                    // Optionally redirect to login page
                    window.location.href = "/Subadmin-login";
                }
            }
        };

        // Check token expiration every second (1000 ms)
        const expirationCheckInterval = setInterval(checkTokenExpiration, 2000);

        // Check token and prevent back button when logged in
        const checkToken = () => {
            const token = localStorage.getItem("subadmin");

            if (token) {
                // Push a new state so the back button doesn't work
                window.history.pushState(null, "", window.location.href);

                // Disable back navigation while the token is present
                window.onpopstate = function () {
                    window.history.pushState(null, "", window.location.href);
                };

                // Store expiration time (1 second from login)
                // const expirationTime = Date.now() + 10000; // 1 second from now

                const expirationTime = Date.now() + 5 * 60 * 1000; // 30 minutes from now


                // const expirationTime = Date.now() + 1 * 60 * 1000; // 1 minute from now

                localStorage.setItem("authTokenExpirySubadminToken", expirationTime.toString());
            } else {
                // Allow back button if the token is cleared
                window.onpopstate = null;
            }
        };

        // Check token on page load
        checkToken();

        // Monitor changes to the JWT token in localStorage
        const tokenChangeHandler = () => {
            checkToken();
        };

        // Listen for changes to the JWT token in localStorage
        window.addEventListener("storage", tokenChangeHandler);

        // Cleanup event listeners on component unmount
        return () => {
            clearInterval(expirationCheckInterval); // Clear interval on unmount
            document.removeEventListener("contextmenu", disableRightClick);
            document.removeEventListener("keydown", disableShortcuts);
            window.removeEventListener("storage", tokenChangeHandler);
        };
    }, []);


    const openicon = Boolean(anchorEl);
    const location = useLocation();

    // Retrieve permissions
    const permissions = location.state?.permissions || JSON.parse(localStorage.getItem('permissions'));

    // Store permissions in localStorage if available in location.state
    useEffect(() => {
        if (location.state?.permissions) {
            localStorage.setItem('permissions', JSON.stringify(location.state.permissions));
        }
    }, [location.state?.permissions]);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);
    const toggleDropdown = () => setDropdownOpen((prev) => !prev);
    const toggleDropdownhome = () => setDropdownhome((prev) => !prev);

    console.log(permissions, "permissions");


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const toggleDropdownwallet = () => {
        setDropdownOpenwallet((prev) => !prev);
    };

    const toggleDropdownreport = () => {
        setDropdownreport((prev) => !prev);
    }

    const toggleDropdownsetting = () => {
        setDropdownsetting((prev) => !prev);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleLogout = () => {
        localStorage.removeItem("subadmin"); // Remove adminAuthToken
        Navigate("/"); // Redirect to the homepage
        setAnchorEl(null); // Close the menu
    };



    console.log(permissions, "permissions")
    return (
        <Box sx={{ display: 'flex', }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} style={{ backgroundColor: "#fff" }}>

                <Toolbar
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}

                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon style={{ color: "black" }} />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "9px",
                            textAlign: "center",
                            marginLeft: "auto",
                        }}
                    >
                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? "account-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                            >
                                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={openicon}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                "&::before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                },
                            },
                        }}
                    >
                        <MenuItem onClick={handleClose}>
                            <Avatar /> Profile
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Avatar /> My account
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <PersonAdd fontSize="small" />
                            </ListItemIcon>
                            Add another account
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Settings
                        </MenuItem>
                        <MenuItem onClick={handleLogout} >
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent" open={open}>
                <DrawerHeader style={{ backgroundColor: "#F8F8FB" }} >

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "70px",
                        }}
                    >
                        <img src={Logo} style={{ width: "70%" }} alt="company logo" />
                    </div>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />


                {open && (
                    <>
                        <List style={{ backgroundColor: "white" }}>

                        </List>
                        <Divider />
                    </>
                )}


                <List style={{ backgroundColor: "white", height: "100vh" }}>

                    <ListItem
                        disablePadding
                        sx={{
                            display: "block",
                            "&:hover": {
                                backgroundColor: "lightgoldenrodyellow",
                            },
                        }}
                        style={{ borderRadius: "10px" }}
                    >
                        {permissions?.Home && (
                            <ListItemButton
                                onClick={() => toggleDropdownhome()}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <HomeIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
                                </ListItemIcon>
                                <ListItemText
                                    style={{ fontSize: "20px", color: "#B4B6B8" }}
                                    primary={"Home"}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        )}
                        {dropdownhome && (
                            <ul
                                className="dropdown-menu show"
                                style={{ display: "block", width: "100%" }}
                            >
                                {permissions?.Home?.includes("Homepanel") && (
                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(1);
                                                toggleDropdownhome();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                        >
                                            Home
                                        </span>
                                    </li>
                                )}


                            </ul>
                        )}
                    </ListItem>

                    <ListItem
                        disablePadding
                        sx={{
                            display: "block",
                            "&:hover": {
                                backgroundColor: "lightgoldenrodyellow",
                            },
                        }}
                        style={{ borderRadius: "10px" }}
                    >
                        {permissions?.Members && (
                            <ListItemButton
                                className="btn btn-secondary dropdown-toggle"
                                href="#"
                                role="button"
                                aria-expanded={dropdownOpen}
                                onClick={toggleDropdown}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: "initial",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 3,
                                        justifyContent: "center",
                                    }}
                                >
                                    <PersonIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
                                </ListItemIcon>

                                <ListItemText
                                    style={{ fontSize: "20px", color: "#B4B6B8" }}
                                    primary={"Members"}
                                    sx={{ opacity: 1 }}
                                />

                            </ListItemButton>
                        )}
                        {dropdownOpen && (
                            <ul
                                className="dropdown-menu show"
                                style={{ display: "block", width: "100%" }}
                            >
                                {permissions?.Members?.includes("Member List") && (
                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(7);
                                                toggleDropdown();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                        >
                                            Member List
                                        </span>
                                    </li>
                                )}


                                {permissions?.Members?.includes("New registration") && (
                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(8);
                                                toggleDropdown();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                        >
                                            New Registration
                                        </span>
                                    </li>
                                )}

                                {permissions?.Members?.includes("User Qrupload") && (
                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(10);
                                                toggleDropdown();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                            href="#"
                                        >
                                            UserQrupload
                                        </span>
                                    </li>
                                )}

                                {permissions?.Members?.includes("User Profileupload") && (
                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(11);
                                                toggleDropdown();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                            href="#"
                                        >
                                            Userprofileupload
                                        </span>
                                    </li>
                                )}


                            </ul>
                        )}
                    </ListItem>


                    <ListItem
                        disablePadding
                        sx={{
                            display: "block",
                            "&:hover": {
                                backgroundColor: "lightgoldenrodyellow",
                            },
                        }}
                        style={{ borderRadius: "10px" }}
                    >
                        {permissions?.Wallet && (
                            <ListItemButton
                                className="btn btn-secondary dropdown-toggle"
                                onClick={() => {
                                    // setSelectedtab(1);
                                    toggleDropdownwallet();
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <AccountBalanceWalletIcon
                                        sx={{ fontSize: 25, color: "#AAADB7" }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    style={{ fontSize: "20px", color: "#B4B6B8" }}
                                    primary={"wallet"}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        )}
                        {open && dropdownOpenwallet && (
                            <ul
                                className="dropdown-menu show"
                                style={{ display: "block", width: "100%" }}
                            >
                                {permissions?.Wallet?.includes("Ewallet") && (
                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(4);
                                                toggleDropdownwallet();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                            href="#"
                                        >
                                            E Wallet
                                        </span>
                                    </li>
                                )}

                                {permissions?.Wallet?.includes("Credit Fund") && (
                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(5);
                                                toggleDropdownwallet();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                            href="#"
                                        >
                                            Credit Fund
                                        </span>
                                    </li>
                                )}

                                {permissions?.Wallet?.includes("Debit Fund") && (
                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(6);
                                                toggleDropdownwallet();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                            href="#"
                                        >
                                            Debit Fund
                                        </span>
                                    </li>
                                )}


                            </ul>
                        )}

                    </ListItem>


                    <ListItem
                        disablePadding
                        sx={{
                            display: "block",
                            "&:hover": {
                                backgroundColor: "lightgoldenrodyellow",
                            },
                        }}
                        style={{ borderRadius: "10px" }}
                    >
                        {permissions?.Report && (
                            <ListItemButton
                                className="btn btn-secondary dropdown-toggle"
                                onClick={() => {
                                    // setSelectedtab(1);
                                    toggleDropdownreport();
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <ReportIcon
                                        sx={{ fontSize: 25, color: "#AAADB7" }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    style={{ fontSize: "20px", color: "#B4B6B8" }}
                                    primary={"Report"}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        )}
                        {open && dropdownreport && (
                            <ul
                                className="dropdown-menu show"
                                style={{ display: "block", width: "100%" }}
                            >
                                {permissions?.Report?.includes("Member Report") && (
                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(9);
                                                toggleDropdownreport();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                            href="#"
                                        >
                                            Member Report
                                        </span>
                                    </li>
                                )}
                                {permissions?.Report?.includes("New Upi report") && (
                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(14);
                                                toggleDropdownreport();
                                                // toggleDropdownsetting();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                            href="#"
                                        >
                                            New Upi Report
                                        </span>
                                    </li>
                                )}
                                {permissions?.Report?.includes("Virtual Account Report") && (

                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(15);
                                                toggleDropdownreport();
                                                // toggleDropdownsetting();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                            href="#"
                                        >
                                            Virtual Account Report
                                        </span>
                                    </li>
                                )}
                            </ul>
                        )}
                    </ListItem>

                    <ListItem
                        disablePadding
                        sx={{
                            display: "block",
                            "&:hover": {
                                backgroundColor: "lightgoldenrodyellow",
                            },
                        }}
                        style={{ borderRadius: "10px" }}
                    >
                        {permissions?.Setting && (
                            <ListItemButton
                                className="btn btn-secondary dropdown-toggle"
                                onClick={() => {
                                    // setSelectedtab(1);
                                    toggleDropdownsetting();
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <SettingsIcon
                                        sx={{ fontSize: 25, color: "#AAADB7" }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    style={{ fontSize: "20px", color: "#B4B6B8" }}
                                    primary={"Setting"}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        )}
                        {open && dropdownsetting && (
                            <ul
                                className="dropdown-menu show"
                                style={{ display: "block", width: "100%" }}
                            >
                                {permissions?.Setting?.includes("Surplus Report") && (
                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(12);
                                                toggleDropdownsetting();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                            href="#"
                                        >
                                            Surplus payout
                                        </span>
                                    </li>

                                )}
                                {permissions?.Setting?.includes("Surplus Collection") && (
                                    <li>
                                        <span
                                            onClick={() => {
                                                setSelectedtab(13);
                                                toggleDropdownsetting();
                                            }}
                                            style={{ fontSize: "15px", color: "black" }}
                                            className="dropdown-item"
                                            href="#"
                                        >
                                            Surplus Collection
                                        </span>
                                    </li>
                                )}
                            </ul>
                        )}
                    </ListItem>

                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {selectedtab === 1 && <Subadminhome />}
                {selectedtab === 7 && <Subadminmemberlist />}
                {selectedtab === 8 && <Subadminnewregistration />}
                {selectedtab === 10 && <SubadminUserqrupload />}
                {selectedtab === 11 && <Subadminprofilephotoupload />}
                {selectedtab === 4 && <Subadminewallet />}
                {selectedtab === 5 && <Subadmincreditfund />}
                {selectedtab === 6 && <Subadmindebitfund />}
                {selectedtab === 9 && <Subadminreport />}
                {selectedtab === 14 && <Subadminnewupireport />}
                {selectedtab === 15 && <Subadminvirtualaccountreport />}
                {selectedtab === 12 && <Subadminsurpluspayout />}
                {selectedtab === 13 && <Surpluscollection />}

            </Box>
        </Box>
    );
}
export default Subadmindashboard;



// old code 

