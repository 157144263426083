import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "./Payoutreport.css";
import Commontransition from "../commoncomponents/Commontransition";
const columns = [
  { id: "sno", label: "S.No", minWidth: 50 },
  { id: "memberID", label: "MemberID", minWidth: 100 },
  { id: "account", label: "Account", minWidth: 150 },
  { id: "ifsc", label: "IFSC", minWidth: 100 },
  { id: "amount", label: "Amount", minWidth: 100, },
  { id: "rrnNo", label: "RRN No", minWidth: 100 },
  { id: "agentOrderId", label: "Agent OrderId", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "topUpdate", label: "Topupdate", minWidth: 150 },
];

function createData(
  sno,
  memberID,
  account,
  ifsc,
  amount,
  rrnNo,
  agentOrderId,
  status,
  topUpdate
) {
  return {
    sno,
    memberID,
    account,
    ifsc,
    amount,
    rrnNo,
    agentOrderId,
    status,
    topUpdate,
  };
}

const rows = [
  createData(
    1,
    "MEM123",
    "ACC001",
    "SBIN0001234",
    5000,
    "RRN001",
    "ORD001",
    "SUCCESS",
    "2024-09-18 10:30 AM"
  ),
  createData(
    2,
    "MEM124",
    "ACC002",
    "HDFC0005678",
    15000,
    "RRN002",
    "ORD002",
    "SUCCESS",
    "2024-09-18 11:00 AM"
  ),
  createData(
    3,
    "MEM125",
    "ACC003",
    "ICIC0003456",
    2000,
    "RRN003",
    "ORD003",
    "SUCCESS",
    "2024-09-18 12:00 PM"
  ),
  createData(
    4,
    "MEM126",
    "ACC004",
    "BOB0009876",
    10000,
    "RRN004",
    "ORD004",
    "SUCCESS",
    "2024-09-18 01:00 PM"
  ),
  createData(
    5,
    "MEM127",
    "ACC005",
    "AXIS0006543",
    7500,
    "RRN005",
    "ORD005",
    "SUCCESS",
    "2024-09-18 02:00 PM"
  ),
];

function Payoutreport() {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);


  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#F8F8FB";

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Revert back to default background color
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}
    >
    <div style={{marginTop:'-30px'}}>
    <Commontransition text={"Newpayout"} transition={'Newpayout'}  onClick={() => window.location.reload()}/>
    </div>
      <h3 style={{ color: "black", fontWeight: "lighter", marginTop:'20px' }}>Payout Report</h3>
      <div
        style={{
          marginTop: "60px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          gap: 70,
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
          padding: "20px",
          paddingLeft: "70px",
          paddingRight: "70px",
          paddingTop: "40px",
        }}
      >
        <div className="container">
          <div className="date-picker-row" style={{ gap: 30 }}>
            <label style={{ fontSize: "18px", color: "black" }}>
              From Date <span style={{ color: "red" }}>*</span>
            </label>
            <DatePicker
              dateFormat="yyyy/MM/dd"
              isClearable
              customInput={<TextField variant="outlined" />}
            />
          </div>
          <div className="date-picker-row" style={{ gap: 30 }}>
            <label style={{ fontSize: "18px", color: "black" }}>
              To Date <span style={{ color: "red" }}>*</span>
            </label>
            <DatePicker
              dateFormat="yyyy/MM/dd"
              isClearable
              customInput={<TextField variant="outlined" />}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{
              marginLeft: "10px",
              height: "56px",
              fontSize: "17px",
              fontWeight: "bold",
              border: "1px solid #083090 !important",
              backgroundColor: "#083090",
              borderRadius: "10px",
            }}
          >
            {`${"Search >>"}`}
          </Button>
        </div>

        <div
          style={{
            marginTop: "50px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "20px",
            gap: 70,
            backgroundColor: "#fff",
            boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
            padding: "20px",
          }}
        >
          <div
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              marginTop: "30px",
            }}
          >
            <span
              style={{ paddingLeft: "36px", color: "gray", fontWeight: "bold" }}
            >
              Search
            </span>
            <div style={{ display: "flex", marginLeft: "30px" }}>
              <input
                style={{
                  width: "100%",
                  marginTop:'10px',
                  height: "45px",
                  borderRadius: "5px",
                  borderColor: "lightGray",
                  paddingLeft: "20px",
                }}
                placeholder="Search"
              />
            </div>
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          color: "black",
                          fontSize: "17px",
                          fontWeight: "700",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.sno}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default Payoutreport;
