import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Bannerone from "../assets/ctc_resized.webp";
// import Bannerone from "../assets/banner1.webp";
import Bannertwo from "../assets/banner2.webp";
import Bannerthree from "../assets/banner3.webp";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Carouselhomepage() {
  return (
    <div>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            src={Bannerone}
            // style={{ width: "100%", maxHeight: "2rem" }}
            alt="Banner"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Bannertwo} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Bannerthree} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Carouselhomepage;
