import React, { useState, useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Back from "../assets/back.png";
import { format } from "date-fns";

const columns = [
  { id: "sno", label: "S.No", minWidth: 50 },
  { id: "memberId", label: "Member ID", minWidth: 100 },
  { id: "date", label: "Date", minWidth: 200 },
  { id: "narration", label: "Narration", minWidth: 100 },
  { id: "credit", label: "Credit", minWidth: 100 },
  { id: "debit", label: "Debit", minWidth: 100 },
  { id: "balance", label: "Balance", minWidth: 100 },
];

function createData(sno, memberId, date, narration, credit, debit, balance) {
  return { sno, memberId, date, narration, credit, debit, balance };
}

const Reportspecificuser = ({ user_id, handleBackClick }) => {
  const userId = user_id.user_id;
  const { balance: initialBalance, memberId } = user_id;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [filteredRows, setFilteredRows] = useState([]);
  const [data, setData] = useState([]);

  const token = localStorage.getItem("adminAuthToken");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/admin/transaction/${userId}`,
  //       {
  //         params: {
  //           page: 1,
  //           perPage: 10,
  //           start_date: format(fromDate, "yyyy-MM-dd"),
  //           end_date: format(toDate, "yyyy-MM-dd"),
  //         },
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     const { data: apiData } = response.data;
  //     const transformedData = apiData.map((item, index) => {

  //         //  condition add money in credit column of table and added mode for different conditions CASH CREDIT & UPI & IMPS than  added tranAmt value from response in Credit column of table 
  //         let isCredit;

  //         if (item.mode === "CASH CREDIT") {
  //             isCredit = "CASH CREDIT";
  //         } else if (item.mode === "UPI") {
  //             isCredit = "UPI";
  //         } else if (item.mode === "IMPS") {
  //             isCredit = "IMPS";
  //         }
          

  //       const isDebit = item.mode === "CASH DEBIT";

  //       return createData(
  //         index + 1, // Serial number
  //         memberId,
  //         item.updated_at,
  //         // item.trandate,
  //         item.Sender_receiver_info,
  //         isCredit ? item.tranAmt : 0,
  //         isDebit ? item.tranAmt : 0,
  //         0 // Use the initial balance
  //       );
  //     });

  //     // Reverse the data so that the latest transactions appear at the top
  //     const reversedData = transformedData.reverse();

  //     // Calculate updated balances
  //     let runningBalance = 0;
  //     const updatedData = reversedData.map((row, index) => {
  //       if (index === 0) {
  //         row.balance = row.credit;
  //         runningBalance = parseFloat(row.balance);
  //       } else {
  //         runningBalance += parseFloat(row.credit) - parseFloat(row.debit);
  //         row.balance = runningBalance.toFixed(2);
  //       }
  //       return row;
  //     });

  //     // Now assign correct increasing serial numbers after reversing the data
  //     const dataWithCorrectSerials = updatedData.map((row, index) => ({
  //       ...row,
  //       sno: index + 1, // Assign correct serial number after reversing
  //     }));

  //     setData(dataWithCorrectSerials);
  //     setFilteredRows(dataWithCorrectSerials);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };



  const fetchData = async () => {
    try {
      const allData = [];
      let currentPage = 1;
      let lastPage = 1;
  
      do {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/transaction/${userId}`,
          {
            params: {
              page: currentPage,
              perPage: 10, // You can adjust this as per your requirements
              start_date: format(fromDate, "yyyy-MM-dd"),
              end_date: format(toDate, "yyyy-MM-dd"),
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        const { data: apiData, meta } = response.data;
  
        // Add data from the current page to the allData array
        allData.push(...apiData);
  
        // Update pagination information
        currentPage = meta.next; // Set to next page if available
        lastPage = meta.lastPage; // Total number of pages
      } while (currentPage);
  
      // Process the combined data
      const transformedData = allData.map((item, index) => {
        const isCredit =
          item.mode === "CASH CREDIT" || item.mode === "UPI" || item.mode === "IMPS";
        const isDebit = item.mode === "CASH DEBIT";
  
        return createData(
          index + 1, // Serial number
          memberId,
          item.updated_at,
          item.Sender_receiver_info,
          isCredit ? item.tranAmt : 0,
          isDebit ? item.tranAmt : 0,
          0 // Use the initial balance
        );
      });
  
      // Reverse the data so that the latest transactions appear at the top
      const reversedData = transformedData.reverse();
  
      // Calculate updated balances
      let runningBalance = 0;
      const updatedData = reversedData.map((row, index) => {
        if (index === 0) {
          row.balance = row.credit;
          runningBalance = parseFloat(row.balance);
        } else {
          runningBalance += parseFloat(row.credit) - parseFloat(row.debit);
          row.balance = runningBalance.toFixed(2);
        }
        return row;
      });
  
      // Assign correct serial numbers after reversing the data
      const dataWithCorrectSerials = updatedData.map((row, index) => ({
        ...row,
        sno: index + 1,
      }));
  
      setData(dataWithCorrectSerials);
      setFilteredRows(dataWithCorrectSerials);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  useEffect(() => {
    const filtered = data.filter((row) => {
      const rowDate = new Date(row.date);
      return rowDate >= fromDate && rowDate <= toDate;
    });
    setFilteredRows(filtered);
  }, [data, fromDate, toDate]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
      <img
        onClick={() => handleBackClick()}
        style={{
          width: "60px",
          height: "60px",
          marginLeft: "10px",
          marginTop: "10px",
        }}
        src={Back}
      />
      <h4
        style={{
          fontWeight: "500",
          marginTop: "30px",
          paddingLeft: "20px",
          cursor: "pointer",
        }}
      >
        Report specific user
      </h4>
      <div
        style={{
          display: "flex",
          marginTop: "30px",
          alignItems: "center",
          padding: "10px",
          justifyContent: "center",
          gap: 30,
        }}
      >
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="From date"
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          selected={toDate}
          onChange={(date) => setToDate(date)}
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="To date"
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: "10px",
            height: "56px",
            backgroundColor: "red",
            borderRadius: "10px",
          }}
          onClick={fetchData}
        >
          Search
        </Button>
      </div>

      <Paper sx={{ width: "100%", overflow: "hidden",marginTop: "150px", }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                    {columns.map((column) => {
                      const value =
                        column.id === "date"
                          ? new Date(row.date).toLocaleString()
                          : row[column.id];
                      return (
                        <TableCell
                          style={{
                            color:
                              column.id === "credit"
                                ? "green"
                                : "black" && column.id === "debit"
                                ? "red"
                                : "black",
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default Reportspecificuser;
