// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.child-divtwo{
    flex: 1 1 45%;
    height: 35%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    margin: 10px;
    border-radius: 5px;
    flex-direction: column;
  }`, "",{"version":3,"sources":["webpack://./src/components/Useraccountdetail.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,0CAA0C;IAC1C,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;EACxB","sourcesContent":[".child-divtwo{\n    flex: 1 1 45%;\n    height: 35%;\n    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);\n    margin: 10px;\n    border-radius: 5px;\n    flex-direction: column;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
