import React, { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";

//  styli

const styles = {

    mainContainer: {
        display: "flex",
        flexDirection: "column",
        marginTop: "30px",
    },
    
    transition: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        width: "100%",
        padding: "20px",
    },

    Gridcontainermain: {
        marginTop: "60px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        gap: 40,
        backgroundColor: "#fff",
        boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
        padding: "20px",
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "40px",
    },

    childrenGrid: {
        justifyContent: "center",
        alignItems: "center"
    },

    accountNumberText: {
        fontSize: "20px",
        color: "black",
        fontFamily: "sans-serif",
    },

    accountNumberInput: {
        paddingLeft: "10px",
        width: "100%",
        height: "60px",
        borderColor: "lightgray",
    },

    Ifscgrid: {
        justifyContent: "center",
        alignItems: "center"
    },

    IfscText: {
        fontSize: "20px",
        color: "black",
        fontFamily: "sans-serif",
    },

    IfscInput: {
        paddingLeft: "10px",
        width: "100%",
        height: "60px",
        borderColor: "lightgray",
    },

    Beneficiarygrid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    BeneficiaryText: {
        fontSize: "20px",
        color: "black",
        fontFamily: "sans-serif",
    },

    Beneficiarychildrencontainer: {
        display: "flex",
        flexDirection: "column", // Stack input and text vertically
        width: "100%",
    },

    Beneficiaryfieldcontainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px", // Space between input and button
    },

    BeneficiaryInputfield: {
        paddingLeft: "10px",
        width: "100%",
        height: "60px",
        borderColor: "lightgray",
    },

    ValidateButton: {
        backgroundColor: "#083090",
        fontWeight: "500",
        padding: "30px",
        height: "60px", // Match the height of the input
    },

    AmountverificationText: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "red",
        fontFamily: "sans-serif",
        marginTop: "5px", // Add some spacing between input and text
    },

    AmountGrid: {
        justifyContent: "center",
        alignItems: "center"
    },

    AmountText: {
        fontSize: "20px",
        color: "black",
        fontFamily: "sans-serif",
    },

    AmountInputfield: {
        paddingLeft: "10px",
        width: "100%",
        height: "60px",
        borderColor: "lightgray",
    },

    Submitbuttoncontainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: 30,
    },

    Submitbutton: {
        backgroundColor: "#0B8405",
        padding: "12px 20px",
        borderRadius: "25px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "bold",
        borderColor: "transparent",
    },

    Resetbutton: {
        backgroundColor: "#DE2A2A",
        padding: "12px 20px",
        borderRadius: "25px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "bold",
        borderColor: "transparent",
    }

};

function Payout() {
    const [formData, setFormData] = useState({
        accountNumber: "",
        ifsc: "",
        beneficiaryName: "",
        amount: "",
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        document.body.style.backgroundColor = "#F8F8FB";
        return () => {
            document.body.style.backgroundColor = ""; // Revert background color
        };
    }, []);

    const validate = () => {
        const newErrors = {};
        if (!formData.accountNumber) {
            newErrors.accountNumber = "Enter Account Number";
        }
        if (!formData.ifsc) {
            newErrors.ifsc = "IFSC is required.";
        } else if (!/^[A-Za-z]{4}\d{7}$/.test(formData.ifsc)) {
            newErrors.ifsc = "Enter IFSC Code";
        }
        if (!formData.beneficiaryName) {
            newErrors.beneficiaryName = "Enter BenificiaryName";
        }
        if (!formData.amount) {
            newErrors.amount = "Enter Amount";
        } else if (isNaN(formData.amount) || formData.amount <= 0) {
            newErrors.amount = "Amount must be a positive number.";
        }
        return newErrors;
    };

    const handleSubmit = () => {
        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            // If no errors, handle form submission
            console.log("Form submitted successfully:", formData);
        }
    };

    const handleChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
        setErrors({ ...errors, [field]: "" }); // Clear error when user starts typing
    };

    return (
        <div
            style={styles.mainContainer}
        >
            <div
                style={styles.transition}
            >
                <h3 style={{ color: "#666666" }}>Payout</h3>
            </div>

            <div
                style={styles.Gridcontainermain}

            >
                {/* Account Number */}
                <Grid
                    container
                    spacing={1}
                    style={styles.childrenGrid}

                >
                    <Grid item xs={12} md={6}>
                        <span
                            style={styles.accountNumberText}

                        >
                            Account Number
                        </span>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <input

                            style={styles.accountNumberInput}
                            type="text"
                            value={formData.accountNumber}
                            onChange={(e) => handleChange("accountNumber", e.target.value)}
                        />
                        {errors.accountNumber && (
                            <span style={{ color: "red" }}>{errors.accountNumber}</span>
                        )}
                    </Grid>
                </Grid>

                {/* IFSC */}
                <Grid
                    container
                    spacing={1}
                    style={styles.Ifscgrid}
                >
                    <Grid item xs={12} md={6}>
                        <span

                            style={styles.IfscText}

                        >
                            IFSC
                        </span>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <input
                            style={styles.IfscInput}

                            type="text"
                            value={formData.ifsc}
                            onChange={(e) => handleChange("ifsc", e.target.value)}
                        />
                        {errors.ifsc && <span style={{ color: "red" }}>{errors.ifsc}</span>}
                    </Grid>
                </Grid>

                {/* Beneficiary Name */}
                <Grid
                    container
                    spacing={2}
                    style={styles.Beneficiarygrid}

                >
                    {/* Beneficiary Name */}
                    <Grid item xs={12} md={6}>
                        <span
                            style={styles.BeneficiaryText}

                        >
                            Beneficiary Name
                        </span>
                    </Grid>

                    {/* Input Field, Text, and Button */}
                    <Grid item xs={12} md={6}>
                        <div
                            style={styles.Beneficiarychildrencontainer}
                        >
                            {/* Input and Button in the Same Row */}
                            <div
                                style={styles.Beneficiaryfieldcontainer}

                            >
                                <input
                                    style={styles.BeneficiaryInputfield}

                                    type="text"
                                    value={formData.beneficiaryName}
                                    onChange={(e) =>
                                        handleChange("beneficiaryName", e.target.value)
                                    }
                                />

                                <Button
                                    onClick={() => alert("Validate successfully.")}
                                    variant="contained"
                                    style={styles.ValidateButton}

                                >
                                    VALIDATE
                                </Button>
                            </div>

                            {/* Text Below the Input Field */}
                            <span
                                style={styles.AmountverificationText}

                            >
                                Account Verification Charge RS 3
                            </span>
                            {errors.beneficiaryName && (
                                <span style={{ color: "red" }}>{errors.beneficiaryName}</span>
                            )}
                        </div>
                    </Grid>
                </Grid>

                {/* Amount */}
                <Grid
                    container
                    spacing={1}
                    style={styles.AmountGrid}

                >
                    <Grid item xs={12} md={6}>
                        <span
                            style={styles.AmountText}

                        >
                            Amount
                        </span>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <input
                            style={styles.AmountInputfield}

                            type="text"
                            value={formData.amount}
                            onChange={(e) => handleChange("amount", e.target.value)}
                        />
                        {errors.amount && (
                            <span style={{ color: "red" }}>{errors.amount}</span>
                        )}
                    </Grid>
                </Grid>

                {/* Submit and Reset Buttons */}
                <div

                    style={styles.Submitbuttoncontainer}

                >
                    <button
                        style={styles.Submitbutton}

                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                    <button

                        style={styles.Resetbutton}

                        onClick={() =>
                            setFormData({
                                accountNumber: "",
                                ifsc: "",
                                beneficiaryName: "",
                                amount: "",
                            })
                        }
                    >
                        Reset
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Payout;
