import React, { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import Commontransition from "../commoncomponents/Commontransition";

function Aepswallet() {
  const [totalaepslimit, setTotalaepslimit] = useState("Rs.0.00");

  const [fundrequestbalance, setFundrequestbalance] = useState("Rs.0.00");

  const [withdrawlaepslimit, setWithdrawalaepslimit] = useState("Rs.0.00");

  useEffect(() => {
    // Apply background color to the body element
    document.body.style.backgroundColor = "#F8F8FB";

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Revert back to default background color
    };
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Commontransition
        text={"AEPS Wallet Panel"}
        transition={"AEPS Wallet"}
        onClick={() => window.location.reload()}
      />
      <Grid
        container
        sx={{
          marginTop: "50px",
          gap: "20px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        {/* Current Password */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "21px", color: "black" }}>
              Total AEPS Limit
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              contentEditable={false}
              //   type="password"
              name="Total AEPS Limit"
              value={totalaepslimit}
              style={{
                padding: "20px",
                width: "100%",
                height: "47px",
                paddingRight: "70px",
              }}
            />
          </Grid>
        </Grid>
        {/* New Password */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "21px", color: "black" }}>
              Fund Request Balance
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              contentEditable={false}
              value={fundrequestbalance}
              name="Fund Request Balance"
              style={{
                padding: "20px",
                width: "100%",
                height: "47px",
                paddingRight: "70px",
              }}
            />
          </Grid>
        </Grid>
        {/* Confirm Password */}
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "21px", color: "black" }}>
              Transfer Type
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              type="text"
              name=" Transfer Type"
              style={{
                padding: "20px",
                width: "100%",
                height: "47px",
                paddingRight: "70px",
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "21px", color: "black" }}>
              Withdrwal AEPS Limit
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              contentEditable={false}
              value={withdrawlaepslimit}
              name="Withdrwal AEPS Limit"
              style={{
                padding: "20px",
                width: "100%",
                height: "47px",
                paddingRight: "70px",
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: "21px", color: "black" }}>
              Enter Withdrwal Amount
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              contentEditable={false}
              type="text"
              name="Enter Withdrwal Amount"
              style={{
                padding: "20px",
                width: "100%",
                height: "47px",
                paddingRight: "70px",
              }}
            />
          </Grid>
        </Grid>
        {/* Buttons */}

        <Button
          variant="contained"
          disableElevation
          style={{
            backgroundColor: "#0B8405",
            padding: "15px",
            borderRadius: "30px",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          TRANSFER MAIN WALLET
        </Button>
      </Grid>
    </div>
  );
}

export default Aepswallet;
