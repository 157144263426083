import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import Ewalletbyuser from "../adminsidemenupages/Ewalletbyuser";

const Ewallet = () => {
  const [wallets, setWallets] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const token = localStorage.getItem("adminAuthToken");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [showTable, setShowTable] = useState(true);

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchWallets = async () => {
    const startDate = formatDate(fromDate);
    const endDate = formatDate(toDate);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/wallet?page=${
        page + 1
      }&perPage=${rowsPerPage}&start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    setWallets(data.data || []);
  };

  const fetchUsers = async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    setUsers(data.data || []);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchWallets();
  }, [fromDate, toDate, page, rowsPerPage]);

  const handleSearchClick = () => {
    setPage(0);
    fetchWallets();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Filter wallets based on search term
  const filteredWallets = wallets.filter(
    (wallet) =>
      wallet.user?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      wallet.user?.userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
      wallet.user?.phone.includes(searchTerm)
  );

  const handleViewClick = (user_id) => {
    const selectedWallet = wallets.find((wallet) => wallet.user_id === user_id);
    if (selectedWallet) {
      setSelectedMember({
        user_id: selectedWallet.user_id,
        balance: selectedWallet.balance,
        credit: selectedWallet.total_credit,
        debit: selectedWallet.total_debit,
        created_at: selectedWallet.created_at,
        memberId: selectedWallet.user?.userId || "N/A",
      });
    }
    setShowTable(false);
  };

  const handleBackClick = () => {
    setShowTable(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
      }}
    >
      {showTable ? (
        <>
          <span
            style={{
              paddingLeft: "20px",
              color: "gray",
              fontWeight: "bold",
              marginTop: "20px",
              fontSize: "25px",
            }}
          >
         E wallet
          </span>
          <div
            style={{
              display: "flex",
              marginTop: "30px",
              padding: "10px",
              marginLeft: "150px",
              gap: 30,
            }}
          >
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              dateFormat="yyyy/MM/dd"
              isClearable
              placeholderText="From date"
              customInput={<TextField label="From date" variant="outlined" />}
            />
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              dateFormat="yyyy/MM/dd"
              isClearable
              placeholderText="To date"
              customInput={<TextField label="To date" variant="outlined" />}
            />
            <Button
              variant="contained"
              color="primary"
              style={{
                marginLeft: "10px",
                height: "56px",
                backgroundColor: "red",
                borderRadius: "10px",
              }}
              onClick={handleSearchClick}
            >
              Search
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "90px",
            }}
          >
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                width: "90%",
                height: "45px",
                borderRadius: "15px",
                borderColor: "lightGray",
                paddingLeft: "20px",
              }}
              placeholder="Search"
            />
          </div>
          <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "110px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>MemberId</TableCell>
                    <TableCell>User Name</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Debit</TableCell>
                    <TableCell>Credit</TableCell>
                    <TableCell>Balance</TableCell>
                    <TableCell>Detail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredWallets
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((wallet, index) => (
                      <TableRow hover key={wallet.user_id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{(wallet.user?.userId || "N/A").toUpperCase()}</TableCell>
                        <TableCell>{wallet.user?.name || "Unknown"}</TableCell>
                        <TableCell>{wallet.user?.phone || "N/A"}</TableCell>
                        <TableCell style={{color:"red"}}>{wallet.total_debit}</TableCell>
                        <TableCell style={{color:"green"}}>{wallet.total_credit}</TableCell>
                        <TableCell>{wallet.balance}</TableCell>
                        <TableCell>
                          <Button
                            style={{
                              borderColor: "red",
                              backgroundColor: "#fff",
                              color: "green",
                              borderWidth: "5px",
                            }}
                            onClick={() => handleViewClick(wallet.user_id)}
                            variant="contained"
                            color="primary"
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow> 
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, 500, 1000, 2000, 5000, 10000]}
              component="div"
              count={filteredWallets.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          </Grid>
        </>
      ) : (
        <Ewalletbyuser
          user_id={selectedMember}
          handleBackClick={handleBackClick}
        />
      )}
    </div>
  );
};

export default Ewallet;
