import React, { useEffect } from "react";
import Dashboardmenubar from "../screen/Dashboardmenubar";

function Dashboard() {
  useEffect(() => {
    // Disable right-click context menu
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    // Disable specific keyboard shortcuts
    const disableShortcuts = (e) => {
      if (
        e.key === "F12" || // F12 - Developer tools
        (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I - Developer tools
        (e.ctrlKey && e.shiftKey && e.key === "J") || // Ctrl+Shift+J - Console
        (e.ctrlKey && e.key === "U") // Ctrl+U - View Source
      ) {
        e.preventDefault();
      }
    };

    // Add event listeners
    document.addEventListener("contextmenu", disableRightClick); // Disable right-click
    document.addEventListener("keydown", disableShortcuts); // Disable specific shortcuts

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableShortcuts);
    };
  }, []);
  return (
    <div>
      <Dashboardmenubar />
    </div>
  );
}

export default Dashboard;
