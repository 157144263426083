import {
    VerticalTimeline,
    VerticalTimelineElement,
  } from "react-vertical-timeline-component";
  import "react-vertical-timeline-component/style.min.css";
  import Support from "../assets/support.png";
  import Technologies from "../assets/technologies.png";
  import Ideas from "../assets/Ideas.png";
  import Successful from "../assets/successful.png";
  import "./Timeline.css";
  
  // Modified WorkIcon component to include numbers
  const WorkIcon = ({ number }) => (
    <div
      style={{
        position: "relative",
        borderRadius: "50%",
        top: "30%",
        background: "#fff",
      }}
    >
      <span
        style={{
          fontWeight: "bold",
          fontSize: "16px",
          color: "#28A48A",
        }}
      >
        {number}
      </span>
    </div>
  );
  
  // Data for the timeline entries
  const timelineData = [
    {
      title: "24X7 Support",
      description:
        "Our customer service is best in class and committed to serve you 24x7 for your queries and questions.",
      imgSrc: Support,
      number: 1,
    },
    {
      title: "Safe And Secure",
      description:
        "Our Company is recognized in the Startup India program by the Govt. of India, offering safe and secure services.",
      imgSrc: Technologies,
      number: 2,
    },
    {
      title: "Easy And Fast",
      description:
        "Signup and go with easy integration features for your web and mobile applications.",
      imgSrc: Ideas,
      number: 3,
    },
    {
      title: "High Commission & Margins",
      description:
        "We provide high commission and margins for all agents across India.",
      imgSrc: Successful,
      number: 4,
    },
  ];
  
  export default function Timeline() {
    const cardStyle = {
      padding: "38px",
      height: "130px",
      backgroundColor: "#ebf5f7",
      position: "relative",
      boxShadow: "-6px 8px 0 0 #00000014",
      borderRadius: "0 0 10px 10px",
    };
  
    const textStyle = {
      color: "#28a4ba",
      fontWeight: 700,
      fontSize: "20px",
      fontFamily: '"Muli", sans-serif',
    };
  
    return (
      <div className="App">
        <VerticalTimeline>
          {timelineData.map((entry, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "#fff",
                color: "#fff",
                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5) ",
                borderRadius: "23px",
              }}
              contentArrowStyle={{ borderRight: "15px solid red",}}
              icon={<WorkIcon number={entry.number} />}
            >
              <div style={{ display: "flex" }}>
                <div style={cardStyle}>
                  <img
                    style={{ width: "74px", height: "74px" }}
                    src={entry.imgSrc}
                    alt={entry.title}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={textStyle}>{entry.title}</span>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      color: "#333333",
                    }}
                  >
                    {entry.description}
                  </p>
                </div>
              </div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    );
  }
  