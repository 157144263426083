// import React, { useState, useEffect } from "react";
// import { Grid } from "@mui/material";

// const Creditfund = () => {

//   ///admin/wallet/add-money
//   const [memberList, setMemberList] = useState([]);
//   const [selectedMember, setSelectedMember] = useState("");
//   const [amount, setAmount] = useState("");
//   const [authotp, setAuthotp] = useState("");
//   const [narration, setNarration] = useState("");
 
//   const token = localStorage.getItem("adminAuthToken");
// // "https://zevopay.online/api/v1/admin/user/getMemberIds"
//   // Fetch member IDs on component mount
//   useEffect(() => {
//     fetch(`${process.env.REACT_APP_API_URL}/admin/user/getMemberIds`, {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setMemberList(data.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching member IDs:", error);
//       });
//   }, []);

//   const handleSubmit = () => {
//     if (!selectedMember || !amount || !narration || !authotp) {
//       alert("Please fill all fields.");
//       return;
//     }
  
//     // Prepare the payload
//     const payload = {
//       userId: Number(selectedMember), // Convert the selected member ID to a number
//       amount: Number(amount), // Ensure amount is sent as a number
//       narration: narration,
//     };
  
//     // Make the POST request to debit money
//     fetch(`${process.env.REACT_APP_API_URL}/admin/wallet/add-money`, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payload),
//     })
//       .then((response) => {
//         // Check if the response status is not OK
//         if (!response.ok) {
//           return response.json().then((errorData) => {
//             throw new Error(errorData.message || "Something went wrong");
//           });
//         }
//         return response.json(); // Parse JSON if response is OK
//       })
//       .then((data) => {
//         // Check if the API response contains any error related to UTR duplication
//         if (data.status === "error" && data.message.includes("UTR")) {
//           alert("Money not credited due to a copied UTR number.");
//         } else {
//           alert("Money credited successfully!");
//           // Reset the form after successful submission
//           setSelectedMember("");
//           setAmount("");
//           setNarration("");
//         }
//       })
//       .catch((error) => {
//         // Handle any errors that occurred during the fetch or in the response
//         console.error("Error adding money:", error);
//         alert(`Money not credited due to copied utr no.`);
//       });
//   };

//   return (
//     <div style={{ display: "flex", flexDirection: "column" }}>
//       <h4>Add Fund in E Wallet</h4>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           gap: 20,
//           backgroundColor: "#fff",
//           boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
//           padding: "20px",
//           paddingLeft: "70px",
//           paddingRight: "70px",
//         }}
//       >
//         <Grid
//           container
//           spacing={1}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Grid item xs={12} md={6}>
//             <span>MemberID *</span>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <div className="input-group mb-3">
//               <select
//                 className="form-select"
//                 id="inputGroupSelect02"
//                 value={selectedMember}
//                 onChange={(e) => setSelectedMember(e.target.value)}
//               >
//                 <option value="" disabled>
//                   Choose...
//                 </option>
//                 {memberList.map((member) => (
//                   <option key={member.id} value={member.id}>
//                     {member.memberId}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </Grid>
//         </Grid>

//         <Grid
//           container
//           spacing={1}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Grid item xs={12} md={6}>
//             <span>Amount *</span>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <input
//               style={{
//                 width: "100%",
//                 height: "47px",
//                 borderRadius: "10px",
//                 borderColor: "lightgray",
//               }}
//               type="number"
//               value={amount}
//               onChange={(e) => setAmount(e.target.value)}
//             />
//           </Grid>
//         </Grid>

//         <Grid
//           container
//           spacing={1}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Grid item xs={12} md={6}>
//             <span>Narration *</span>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <input
//               style={{
//                 width: "100%",
//                 height: "120px",
//                 borderRadius: "10px",
//                 borderColor: "lightgray",
//               }}
//               value={narration}
//               onChange={(e) => setNarration(e.target.value)}
//             />
//           </Grid>
//         </Grid>


//         <Grid
//         container
//         spacing={1}
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <Grid item xs={12} md={6}>
//           <span>Auth otp *</span>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <input
//             style={{
//               width: "100%",
//               height: "47px",
//               borderRadius: "10px",
//               borderColor: "lightgray",
//             }}
//             type="number"
//             value={authotp}
//             onChange={(e) => setAuthotp(e.target.value)}
//           />
//         </Grid>
//       </Grid>

//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             marginLeft: "70px",
//             gap: 30,
//           }}
//         >
//           <button
//             style={{
//               backgroundColor: "#F08080",
//               padding: "5px",
//               borderRadius: "15px",
//               color: "#fff",
//               fontSize: "14px",
//               fontWeight: "bold",
//               borderColor: "transparent",
//               width: "70px",
//             }}
//             onClick={handleSubmit}
//           >
//             Submit
//           </button>
//           <button
//             style={{
//               backgroundColor: "#F08080",
//               padding: "5px",
//               borderRadius: "15px",
//               color: "#fff",
//               fontSize: "14px",
//               fontWeight: "bold",
//               borderColor: "transparent",
//               width: "70px",
//             }}
//             onClick={() => {
//               setSelectedMember("");
//               setAmount("");
//               setNarration("");
//             }}
//           >
//             Reset
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Creditfund;



import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";

const Debitfund = () => {
  const [memberList, setMemberList] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [amount, setAmount] = useState("");
  const [authotp, setAuthotp] = useState("");
  const [narration, setNarration] = useState("");
  const token = localStorage.getItem("adminAuthToken");

  // Fetch member IDs on component mount
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/admin/user/getMemberIds`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setMemberList(data.data);
      })
      .catch((error) => {
        console.error("Error fetching member IDs:", error);
      });
  }, [token]);


  const adminToken = localStorage.getItem("adminAuthTokenstored");

  const handleSubmit = async () => {
    if (!selectedMember || !amount || !narration || !authotp) {
      alert("Please fill all fields.");
      return;
    }

    try {
      // Step 1: Two-Factor Authentication API Call
      const twoFactorResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/2fa/authenticate`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${adminToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ twoFactorAuthenticationCode: authotp }),
        }
      );

      if (!twoFactorResponse.ok) {
        const errorData = await twoFactorResponse.json();
        throw new Error(errorData.message || "Two-factor authentication failed.");
      }

      const twoFactorData = await twoFactorResponse.json();
      const { access_token } = twoFactorData;

      if (!access_token) {
        alert("Two-factor authentication failed. Please try again.");
        return;
      }

      // Step 2: Proceed to Add Money API Call
      const payload = {
        userId: Number(selectedMember),
        amount: Number(amount),
        narration: narration,
      };

      const addMoneyResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/wallet/debit-money`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!addMoneyResponse.ok) {
        const errorData = await addMoneyResponse.json();
        throw new Error(errorData.message || "Something went wrong");
      }

      const addMoneyData = await addMoneyResponse.json();

      if (addMoneyData.status === "error" && addMoneyData.message.includes("UTR")) {
        alert("Money not credited due to a copied UTR number.");
      } else {
        alert("Money debited successfully!");
        // Reset the form after successful submission
        setSelectedMember("");
        setAmount("");
        setNarration("");
        setAuthotp("");
      }
    } catch (error) {
      console.error("Error adding money:", error);
      alert(error.message || "An error occurred while processing your request.");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
    <h4>Debit Fund in E Wallet</h4>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
          padding: "20px",
          paddingLeft: "70px",
          paddingRight: "70px",
        }}
      >
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>MemberID *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="input-group mb-3">
              <select
                className="form-select"
                id="inputGroupSelect02"
                value={selectedMember}
                onChange={(e) => setSelectedMember(e.target.value)}
              >
                <option value="" disabled>
                  Choose...
                </option>
                {memberList.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.memberId}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Amount *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                width: "100%",
                height: "47px",
                borderRadius: "10px",
                borderColor: "lightgray",
              }}
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Narration *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                width: "100%",
                height: "120px",
                borderRadius: "10px",
                borderColor: "lightgray",
              }}
              value={narration}
              onChange={(e) => setNarration(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Auth otp *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                width: "100%",
                height: "47px",
                borderRadius: "10px",
                borderColor: "lightgray",
              }}
              type="number"
              value={authotp}
              onChange={(e) => setAuthotp(e.target.value)}
            />
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "70px",
            gap: 30,
          }}
        >
          <button
            style={{
              backgroundColor: "#F08080",
              padding: "5px",
              borderRadius: "15px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "transparent",
              width: "70px",
            }}
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            style={{
              backgroundColor: "#F08080",
              padding: "5px",
              borderRadius: "15px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "transparent",
              width: "70px",
            }}
            onClick={() => {
              setSelectedMember("");
              setAmount("");
              setNarration("");
              setAuthotp("");
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default Debitfund;








