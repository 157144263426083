const AUTH_BASE_URL = process.env.REACT_APP_API_URL + "/auth";
const USER_BASE_URL = process.env.REACT_APP_API_URL + "/user";
const WALLET_BASE_URL = process.env.REACT_APP_API_URL + "/wallet";


const Api = {
    SIGN_UP_URL: `${AUTH_BASE_URL}/register`,
    LOGIN_URL: `${AUTH_BASE_URL}/login`,
    
    VERIFY_MPIN_URL: `${USER_BASE_URL}/verify-mpin`,
    PROFILE_URL: `${USER_BASE_URL}`,
    
    GET_BALANCE_URL: `${WALLET_BASE_URL}/balance`,
    GET_TRANSACTIONS_URL: `${WALLET_BASE_URL}/transactions` 
}

export default Api;