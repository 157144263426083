// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-hover:hover {
  background-color: #0003cd; /* Change background color on hover */
}

.card-img-wrapper {
  background-color: blue;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.card-hover:hover .card-img-wrapper {
  background-color: #fb130b; /* Change background color of the image wrapper on hover */
}

.card-hover:hover .card-title,
.card-hover:hover .card-text {
  color: white;
}

.child-divone {
  flex: 1 1 45%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin: 10px;
  border-radius: 5px;
  background-color: #fff;
  flex-direction: column;
}

.line {
  border: 0;
  height: 1px;
  background: #4D4D4D;
  margin: 10px 20px 0 20px;
}
`, "",{"version":3,"sources":["webpack://./src/commoncomponents/Card.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,qCAAqC;AAClE;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,sCAAsC;AACxC;AACA;EACE,yBAAyB,EAAE,0DAA0D;AACvF;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,0CAA0C;EAC1C,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,WAAW;EACX,mBAAmB;EACnB,wBAAwB;AAC1B","sourcesContent":[".card-hover:hover {\n  background-color: #0003cd; /* Change background color on hover */\n}\n\n.card-img-wrapper {\n  background-color: blue;\n  width: 60px;\n  height: 60px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 5px;\n  transition: background-color 0.3s ease;\n}\n.card-hover:hover .card-img-wrapper {\n  background-color: #fb130b; /* Change background color of the image wrapper on hover */\n}\n\n.card-hover:hover .card-title,\n.card-hover:hover .card-text {\n  color: white;\n}\n\n.child-divone {\n  flex: 1 1 45%;\n  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);\n  margin: 10px;\n  border-radius: 5px;\n  background-color: #fff;\n  flex-direction: column;\n}\n\n.line {\n  border: 0;\n  height: 1px;\n  background: #4D4D4D;\n  margin: 10px 20px 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
