import React from "react";

function Commoninputfield({ namefield, typefield, styles, value, onChange }) {
  return (
    <>
      <input
        value={value}
        onChange={onChange}
        type={typefield ? typefield : "password"}
        name={namefield ? namefield : "currentPassword"}
        style={
          styles
            ? styles
            : {
                padding: "10px",
                width: "100%",
                paddingRight: "70px",
              }
        }
      />
    </>
  );
}

export default Commoninputfield;
