
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import * as XLSX from "xlsx";
import Commontransition from "../commoncomponents/Commontransition";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import "../commoncomponents/Commoncalender.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Api from "../apiconfig/Api";
import { getAllData } from "../redux/Userdetail";
import { useSelector, useDispatch } from "react-redux";

const commonstyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    backgroundColor: "#F8F8FB",
  },
  Datepickercontainer: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
    padding: "10px",
    justifyContent: "center",
    gap: 30,
  },
  Searchbutton: {
    marginLeft: "10px",
    height: "56px",
  },
  Exportbutton: {
    marginLeft: "10px",
    height: "56px",
    width: "100px",
  },
  Searchcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "60px",
  },
  Searchfield: {
    width: "70%",
    height: "45px",
    borderRadius: "15px",
    borderColor: "lightGray",
    paddingLeft: "20px",
  },
  DialogcontentText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: 30,
  },
  Excelbutton: {
    padding: "10px 40px",
    borderRadius: "10px",
  },
  Pdfbutton: {
    padding: "10px 50px",
    borderRadius: "10px",
  },
  Gridcontainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "150px",
    padding: "40px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
  },
};

const columns = [
  { id: "sno", label: "S.No", minWidth: 50 },
  { id: "memberId", label: "Member ID", minWidth: 100 },
  { id: "date", label: "Date", minWidth: 200 },
  { id: "narration", label: "Narration", minWidth: 100 },
  { id: "credit", label: "Credit", minWidth: 100 },
  { id: "debit", label: "Debit", minWidth: 100 },
  { id: "balance", label: "Balance", minWidth: 100 },
];

function createData(sno, memberId, date, narration, credit, debit, balance) {
  return { sno, memberId, date, narration, credit, debit, balance };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Transactiontable = ({ user_id }) => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("authToken");
  const UserId = localStorage.getItem("UserId");
  const balance = localStorage.getItem("Balance");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");


  //new lind added
  const { users, loading, error } = useSelector((state) => state.Userdata);

  console.log(users, "userdataget");

  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const url = Api.GET_BALANCE_URL;

  const fetchBalance = async () => {
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const balanceData = await response.json();

      const balanceRow = createData(
        0,
        UserId.toUpperCase(),
        balanceData.created_at,
        "Registration",
        0,
        0,
        0
      );

      return balanceRow;
    } catch (error) {
      console.error("Error fetching balance data:", error);
      return null;
    }
  };

  const fetchData = async () => {
    try {
      const formattedStartDate = startDate
        ? format(startDate, "yyyy-MM-dd")
        : null;
      const formattedEndDate = endDate ? format(endDate, "yyyy-MM-dd") : null;

      const [transactionResponse, balanceRow] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/wallet/transactions`, {
          params: {
            page: 1,
            perPage: 10,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        fetchBalance(),
      ]);

      const { data: apiData } = transactionResponse.data;

      const transformedData = apiData.map((item) => {
        // Ensure amount and surchargeAmount are valid numbers
        const amount = parseFloat(item.amount) || 0; // Convert to float, default to 0 if NaN
        const surchargeAmount = parseFloat(item.surchargeAmount) || 0; // Convert to float, default to 0 if NaN

        // Handle credit cases including refunded payouts and other credit modes
        const isCredit =
          (item.mode === "CASH CREDIT" ||
            ["UPI", "IMPS", "NEFT", "RTGS"].includes(item.mode) ||
            (item.type === "payout" && item.status === "REFUNDED")) && // Handle refunded payouts as credit
          (amount > 0 || surchargeAmount > 0); // Ensure there's a valid amount

        // Handle debit cases excluding refunded payouts
        const isDebit =
          item.mode === "CASH DEBIT" ||
          (item.type === "payout" && item.status !== "REFUNDED"); // Exclude refunded payouts

        // Debit amount calculation logic
        const debitAmount =
          item.type === "payout" && item.status !== "REFUNDED" // Debit for non-refunded payouts
            ? amount + surchargeAmount // Adding surchargeAmount if payout
            : isDebit
            ? parseFloat(item.tranAmt || 0) // Default to 0 if tranAmt is NaN
            : 0;

        // Credit amount for refunded payouts or other credit modes like CASH CREDIT
        const creditAmount =
          item.mode === "CASH CREDIT" || // Handle CASH CREDIT mode
          ["UPI", "IMPS", "NEFT", "RTGS"].includes(item.mode) // Handle UPI, IMPS, NEFT, RTGS modes
            ? parseFloat(item.tranAmt || 0) // Use sum of amount and surchargeAmount for credit
            : item.type === "payout" && item.status === "REFUNDED" // Only for refunded payouts
            ? amount + surchargeAmount // Use sum of amount and surchargeAmount for credit
            : isCredit
            ? parseFloat(item.tranAmt || 0) // For other types of credits
            : 0;

        const narration =
          item.type === "payout" && item.status !== "REFUNDED" // Only for 'payout' type and status not 'REFUNDED'
            ? item.transactionReferenceNo
              ? `Payout First / ${item.transactionReferenceNo} / ${item.id}` // If transactionReferenceNo exists
              : item.errorMessage.includes(":")
              ? `Payout First / ${item.errorMessage.split(":")[1]?.trim()} / ${
                  item.id
                }` // If errorMessage has a colon
              : `Payout First / ${item.errorMessage} / ${item.id}` // If no colon in errorMessage
            : item.type === "payout" && item.status === "REFUNDED" // If type is 'payout' and status is 'REFUNDED'
            ? `Payout Refunded / ${item.paymentDescription} / ${item.transactionID}` // Add paymentDescription in the narration for refunded payouts
            : item.mode === "UPI"
            ? `Add Amt By UPI / ${item.utr}`
            : item.mode === "IMPS"
            ? `Add Amt By virtual A/C / IMPS / ${
                item.Sender_receiver_info?.split("/")[1]
              }`
            : item.mode === "NEFT"
            ? `Add Amt By virtual A/C / NEFT / ${item.utr}`
            : item.mode === "RTGS"
            ? `Add Amt By virtual A/C / RTGS / ${item.utr}`
            : item.mode === "CASH CREDIT"
            ? `Admin - Add Fund - ${item.Sender_receiver_info}`
            : item.mode === "CASH DEBIT"
            ? `Admin - Deduct Fund - ${item.Sender_receiver_info}`
            : item.Sender_receiver_info;

        return createData(
          0,
          UserId.toUpperCase(),
          item.updated_at,
          narration,
          creditAmount, // Correct credit amount logic
          debitAmount, // Correct debit amount logic
          0
        );
      });

      const reversedData = transformedData;

      let runningBalance = parseFloat(balance || 0);

      const updatedData = reversedData.map((row, index) => {
        row.balance = runningBalance.toFixed(2);
        runningBalance -= parseFloat(row.credit);
        runningBalance += parseFloat(row.debit);
        row.sno = index + 1;
        return row;
      });

      if (balanceRow) {
        const balanceDate = new Date(balanceRow.date);
        const isWithinRange =
          (!startDate || balanceDate >= startDate) &&
          (!endDate || balanceDate <= endDate);

        if (isWithinRange) {
          balanceRow.sno = updatedData.length + 1;
          updatedData.push(balanceRow);
        }
      }

      setData(updatedData);
      setFilteredRows(updatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const Name = localStorage.getItem("Name");
  console.log(Name, "Name");
  const Phonenumber = localStorage.getItem("Phone");
  console.log(Phonenumber, "Phonenumber");
  const EmailId = localStorage.getItem("email");
  console.log(EmailId, "EmailIfD");
  const virtual_account = localStorage.getItem("virtual_account");
  console.log(virtual_account, "VirtualAccount");

  //  Export to Excel

  const exportToExcel = () => {
    const filteredRows = data.map((row) => {
      const formattedDate = new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }).format(new Date(row.date));

      return {
        ...row,
        date: formattedDate,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet([], { skipHeader: true });

    const userDetails = [
      [`Name: ${Name}`],
      [`Email: ${EmailId}`],
      [`Phone: ${Phonenumber}`],
      [`Virtual Account: ${virtual_account}`],
      [`Status: ${users?.status || "null"}`],
      [`Bank Name: ${"IDBI Bank Ltd."}`],
      [`IFSC: ${"IBKL0002031"}`],
      [`Account Type: ${"Current Account"}`],
      [`Address: ${users?.address || "null"}`],
      [`AadharNumber: ${users?.aadharNumber || "null"}`],
      [`PanNumber: ${users?.panNumber || "null"}`],
      [`State: ${users?.state || "null"}`],
      [`ShopName: ${users?.shopName || "null"}`],
      [`ShopAddress: ${users?.shopAddress || "null"}`],
      [`GstNumber: ${users?.gstNumber || "null"}`],
      [`BusinessPanNo: ${users?.businessPanNo || "null"}`],
      [`LandlineNo: ${users?.landlineNumber || "null"}`],
      [`LandlineStdCode: ${users?.landlineSTDCode || "null"}`],
    ];

    XLSX.utils.sheet_add_aoa(worksheet, userDetails, { origin: "A3" });

    const headerRowIndex = userDetails.length + 5;

    const headers = [
      ["S.No", "Member ID", "Date", "Narration", "Credit", "Debit", "Balance"],
    ];
    XLSX.utils.sheet_add_aoa(worksheet, headers, {
      origin: `A${headerRowIndex}`,
    });

    XLSX.utils.sheet_add_json(worksheet, filteredRows, {
      origin: `A${headerRowIndex + 1}`,
      skipHeader: true,
    });

    const headerRow = headerRowIndex - 1;
    headers[0].forEach((_, colIndex) => {
      const cellRef = XLSX.utils.encode_cell({ r: headerRow, c: colIndex });
      if (!worksheet[cellRef]) return;
      worksheet[cellRef].s = {
        alignment: { horizontal: "center", vertical: "center" },
        font: { bold: true },
      };
    });

    worksheet["!cols"] = [
      { wch: 5 }, //0
      { wch: 20 }, // 1
      { wch: 25 }, // 2
      { wch: 70 }, // 3
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

    XLSX.writeFile(workbook, "Transactionsdetail.xlsx");
    handleClose();
  };

  // Export to pdf
  const generatePDF = () => {
    const MyDocument = (
      <Document>
        <Page size={[800, 900]}>
          <View style={styles.container}>
            <Text style={styles.header}>Transaction Report</Text>
            <Text>Name: {Name}</Text>
            <Text>Email: {EmailId}</Text>
            <Text>Phone: {Phonenumber}</Text>
            <Text>Virtual Account: {virtual_account}</Text>
            <Text>Status: {users?.status || "null"}</Text>
            <Text>Bank Name: {"IDBI Bank Ltd."}</Text>
            <Text>IFSC: {"IBKL0002031"}</Text>
            <Text>Account Type: {"Current Account"}</Text>
            <Text>Address: {users?.address || "null"}</Text>
            <Text>AadharNumber: {users?.aadharNumber || "null"}</Text>
            <Text>PanNumber: {users?.panNumber || "null"}</Text>
            <Text>State: {users?.state || "null"}</Text>
            <Text>ShopName: {users?.shopName || "null"}</Text>
            <Text>ShopAddress: {users?.shopAddress || "null"}</Text>
            <Text>GstNumber: {users?.gstNumber || "null"}</Text>
            <Text>BusinessPanNo: {users?.businessPanNo || "null"}</Text>
            <Text>LandlineNo: {users?.landlineNumber || "null"}</Text>
            <Text>LandlineStdCode: {users?.landlineSTDCode || "null"}</Text>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>S.No</Text>
                <Text style={styles.tableCell}>Member ID</Text>
                <Text style={styles.tableCell}>Date</Text>
                <Text style={styles.tableCell}>Narration</Text>
                <Text style={styles.tableCell}>Credit</Text>
                <Text style={styles.tableCell}>Debit</Text>
                <Text style={styles.tableCell}>Balance</Text>
              </View>
              {filteredRows.map((row, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.tableCell}>{row.sno}</Text>
                  <Text style={styles.tableCell}>{row.memberId}</Text>
                  {/* <Text style={styles.tableCell}>{row.date}</Text> */}
                  <Text style={styles.tableCell}>
                    {new Intl.DateTimeFormat("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    }).format(new Date(row.date))}
                  </Text>
                  <Text style={styles.narrationCell}>{row.narration}</Text>
                  <Text style={styles.tableCell}>{row.credit}</Text>
                  <Text style={styles.tableCell}>{row.debit}</Text>
                  <Text style={styles.tableCell}>{row.balance}</Text>
                </View>
              ))}
            </View>
          </View>
        </Page>
      </Document>
    );

    pdf(MyDocument)
      .toBlob()
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "TransactionsReport.pdf";
        link.click();
      });
    handleClose();
  };

  // Global function to export both Excel and PDF

  const handleExportClick = () => {
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(data, "dataofusercoming");


  // Added this useeffect for searching narration of users 

  useEffect(() => {
    // Filter data based on narration column
    const filteredData = data
      .filter((row) => 
        row.narration.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => {
        // If a row matches the search query, give it priority
        if (a.narration.toLowerCase().includes(searchQuery.toLowerCase())) return -1;
        if (b.narration.toLowerCase().includes(searchQuery.toLowerCase())) return 1;
        return 0; // Maintain original order for non-matching rows
      });

      console.log(filteredData, "filteredData");

      const reverserData = filteredData.reverse();
      console.log(reverserData, "reversedDatacome")
  
    setFilteredRows(reverserData); // Update the filtered rows
  }, [data, searchQuery]); // Run whenever data or search query changes
  


  console.log(filteredRows, "filteredrows")

  return (
    <div style={commonstyle.container}>
      <Commontransition
        text={"Ewallet Report"}
        transition={"Ewallet"}
        onClick={() => window.location.reload()}
      />
      <div style={commonstyle.Datepickercontainer}>
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="From date"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="To date"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          variant="contained"
          color="primary"
          style={commonstyle.Searchbutton}
          onClick={fetchData}
        >
          Search
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={commonstyle.Exportbutton}
          onClick={handleExportClick}
        >
          EXPORT
        </Button>
      </div>

      <div style={commonstyle.Searchcontainer}>
        <input
          type="text"
          style={commonstyle.Searchfield}
          placeholder="Search"
          value={searchQuery} // Bind the search query to the input value
          onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
        />
      </div>
      
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"How to Download Transaction History?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={commonstyle.DialogcontentText}
          >
            <Button
              style={commonstyle.Excelbutton}
              onClick={() => exportToExcel()}
              variant="contained"
            >
              EXCEL
            </Button>
            <Button
              style={commonstyle.Pdfbutton}
              onClick={() => generatePDF()}
              variant="contained"
            >
              PDF
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Grid
        container
        sx={commonstyle.Gridcontainer}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                      {columns.map((column) => {
                        const value =
                          column.id === "date"
                            ? new Intl.DateTimeFormat("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true, // Ensures 24-hour format
                              }).format(new Date(row.date))
                            : row[column.id];

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              color:
                                column.id === "credit"
                                  ? "green"
                                  : column.id === "debit"
                                  ? "red"
                                  : "black",
                            }}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  table: {
    marginTop: 20,
  },
  tableRow: {
    flexDirection: "row",
    marginBottom: 5,
  },
  tableCell: {
    marginRight: 10,
    fontSize: 10,
    border: "1px solid black",
    padding: 5,
    flex: 1,
  },
  narrationCell: {
    marginRight: 10,
    fontSize: 10,
    border: "1px solid black",
    padding: 5,
    flex: 3, // Increase flex value to give more width to the narration column
    flexWrap: "wrap", // Allow text to wrap
    wordBreak: "break-word", // Break words if they are too long
    overflow: "hidden", // Hide overflow
  },
});

export default Transactiontable;

