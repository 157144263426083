
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Commontransition from '../../commoncomponents/Commontransition.jsx';
import useCommonBackground from '../../hook/Commonhook.jsx';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import DatePicker from 'react-datepicker';
import TextField from '@mui/material/TextField';
import Showprofilesdetails from './Showprofilesdetails.jsx';

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 50 },
  { id: 'name', label: 'Name', minWidth: 150 },
  { id: 'phone', label: 'Phone', minWidth: 150 },
  { id: 'email', label: 'Email', minWidth: 200 },
  { id: 'vc', label: 'V/C', minWidth: 100 },
  { id: 'aadhar', label: 'Aadhar', minWidth: 150 },
  { id: 'address', label: 'Address', minWidth: 200 },
  { id: 'date', label: 'Date', minWidth: 150 },
  { id: 'view', label: 'View', minWidth: 100 },
];

function Registerusers() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30000);
  const [allRows, setAllRows] = useState([]); // Stores all fetched data
  const [rows, setRows] = useState([]); // Filtered data
  const [totalRows, setTotalRows] = useState(0);
  const [fromDate, setFromDate] = useState(null); // Start date
  const [toDate, setToDate] = useState(null); // End date
  const [showTable, setShowTable] = useState(true);
  const [searchTerm, setSearchTerm] = useState(''); // Search input
  const [selectedUser, setSelectedUser] = useState(null);


  const token = localStorage.getItem('adminAuthToken');

  useCommonBackground();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (page = 1, perPage = 10) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: page, // Correctly pass the page number
          perPage: perPage, // Pass the perPage parameter
        },
      });

      const data = response.data.data;
      console.log(data, "dataofuser")
      const meta = response.data.meta;

      // Format data for the table
      const formattedData = data.map((item) => ({
        user_id: item.userId || 'N/A',
        name: item.name || 'N/A',
        phone: item.phone || 'N/A',
        email: item.email || 'N/A',
        vc: item.virtual_account || 'N/A',
        aadhar: item.aadharNumber || 'N/A', // Display Aadhar number
        address: item.address || 'N/A',
        date: new Date(item.updated_at) || 'N/A', // Save Date object for filtering
        formattedDate: new Date(item.updated_at).toLocaleDateString('en-IN', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
        }),
        businessPanNo: item.businessPanNo || 'N/A',
        gstNumber: item.gstNumber || 'N/A',
        landlineNumber: item.landlineNumber || 'N/A',
        landlineSTDCode: item.landlineSTDCode || 'N/A',
        panNumber: item.panNumber || 'N/A',
        shopAddress: item.shopAddress || 'N/A',
        shopName: item.shopName || 'N/A',
        state: item.state || 'N/A',

      }));

      setAllRows(formattedData); // Save original data
      setRows(formattedData); // Show all rows initially
      setTotalRows(meta.total); // Set the total number of rows from the API response
      console.log(totalRows, 'totalrows');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearchClick = () => {
    if (!fromDate && !toDate) {
      setRows(allRows); // If no date range is selected, show all data
      return;
    }

    // Filter rows based on the selected date range
    const filteredRows = allRows.filter((row) => {
      const rowDate = new Date(row.date);
      if (fromDate && toDate) {
        return rowDate >= fromDate && rowDate <= toDate;
      } else if (fromDate) {
        return rowDate >= fromDate;
      } else if (toDate) {
        return rowDate <= toDate;
      }
      return true;
    });

    setRows(filteredRows);
    setTotalRows(filteredRows.length);
  };

  // Filter rows based on search term
  const handleSearchInput = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filteredRows = allRows.filter(
      (row) =>
        row.name.toLowerCase().includes(value) ||
        row.phone.toLowerCase().includes(value) ||
        row.email.toLowerCase().includes(value) ||
        row.vc.toLowerCase().includes(value) ||
        row.aadhar.toLowerCase().includes(value) ||
        row.address.toLowerCase().includes(value),
    );

    setRows(filteredRows);
    setTotalRows(filteredRows.length);
  };

  const handleChangePage = (event, newPage) => {
    console.log('Page changed to:', newPage + 1); // Log the page being requested
    setPage(newPage); // Update the page state
    fetchData(newPage + 1, rowsPerPage); // Fetch data for the new page
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page when rows per page changes
    fetchData(1, newRowsPerPage); // Fetch data for the first page
  };

  const handleViewClick = (user_id) => {
    const selectedUserprofile = allRows.find((row) => row.user_id === user_id);

    console.log("Selected User Profile:", selectedUserprofile); // Debugging Log

    if (selectedUserprofile) {
      setSelectedUser(selectedUserprofile); // Store only the selected user
      setShowTable(false); // Show user details page
    } else {
      console.error("User data not found for ID:", user_id);
    }
  };

  const handleBackClick = () => {
    setShowTable(true);
  };


  //   console.log(allRows, "allRowsdatastoredusers")
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {showTable ? (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#fff',
              width: '100%',
              padding: '25px',
              marginTop: '20px',
            }}
          >
            <h3 style={{ color: '#666666' }}>All Users Register Profiles</h3>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '30px',
              padding: '10px',
              gap: 30,
            }}
          >
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              dateFormat="yyyy/MM/dd"
              isClearable
              placeholderText="From date"
              customInput={<TextField label="From date" variant="outlined" />}
            />
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              dateFormat="yyyy/MM/dd"
              isClearable
              placeholderText="To date"
              customInput={<TextField label="To date" variant="outlined" />}
            />
            <Button
              variant="contained"
              color="primary"
              style={{
                marginLeft: '10px',
                height: '56px',
                backgroundColor: 'red',
                borderRadius: '10px',
              }}
              onClick={handleSearchClick}
            >
              Search
            </Button>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '90px',
            }}
          >
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchInput}
              style={{
                width: '90%',
                height: '45px',
                borderRadius: '15px',
                borderColor: 'lightGray',
                paddingLeft: '20px',
              }}
              placeholder="Search by Name, Email, Phone,V/C, Aadhar, Address...  "
            />
          </div>

          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '100px',
              padding: '40px',
              borderRadius: '5px',
              backgroundColor: '#fff',
              boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.2)',
            }}
          >
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="left" style={{ minWidth: column.minWidth, fontSize: '18px', fontWeight: '500' }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          let value =
                            column.id === 'sno' ? page * rowsPerPage + index + 1 : column.id === 'date' ? row.formattedDate : row[column.id];
                          return (
                            <TableCell key={column.id} align="left" style={{ color: 'black', fontWeight: '500', fontSize:"17px" }}>
                              {column.id === 'view' ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    borderColor: 'red',
                                    backgroundColor: '#fff',
                                    color: 'green',
                                    borderWidth: '5px',
                                  }}
                                  onClick={() => handleViewClick(row.user_id)}
                                >
                                  View
                                </Button>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100, 1000, 10000, 30000]}
                component="div"
                count={totalRows} // Use totalRows from API response
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </>
      ) : (
        <Showprofilesdetails user={selectedUser} handleBackClick={handleBackClick} />
      )}
    </div>
  );
}

export default Registerusers;

