import axios from "axios";

// Base URL configuration
const token = localStorage.getItem("adminAuthToken");

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

// API call to add/update surcharge
export const addSurcharge = (payload) => {
  return apiClient.post(`/admin/surcharge`, payload);
};

// Example API functions
export const getSurchargeDate = () => {
  return apiClient.get(`/admin/surcharges`);
};