import React from "react";
import Navigators from "./router/Navigator";

function App() {
  return (
    <>
      <Navigators />
    </>
  );
}

export default App;




