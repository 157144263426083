import React, { useState } from "react";
import Logo from "../assets/logo.png";
import "./Menubar.css";
import { useNavigate } from "react-router";

function Menubar() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav
      className="navbar"
      style={{
        backgroundColor: "#fff",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        padding: "10px 20px",
      }}
    >
      <div
        className="container-fluid"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Logo */}
        <div style={{ width: "140px", height: "60px" }}>
          <img src={Logo} alt="logo" style={{ width: "100%" }} />
        </div>

        {/* Mobile Menu Toggle Button */}
        <button
          className="d-md-none"
          onClick={toggleMenu}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            fontSize: "24px",
          }}
        >
          ☰
        </button>

        {/* Desktop Navigation Links */}
        <div className="d-none d-md-flex" style={{ gap: "20px" }}>
          <div className="Texthover" onClick={() => navigate("/")}>
            Home
          </div>
          <div className="Texthover" onClick={() => navigate("/Company")}>
            Company
          </div>
          <div className="Texthover" onClick={() => navigate("/services")}>
            Services
          </div>
          <div className="Texthover" onClick={() => navigate("/Career")}>
            Career
          </div>
          <div className="Texthover" onClick={() => navigate("/Contact")}>
            Contact us
          </div>
          <button className="hover-button" onClick={() => navigate("/Login")}>
            Login
          </button>
          <button className="hover-button">Download Apk</button>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      {isMenuOpen && (
        <div
          className="mobile-menu d-md-none"
          style={{
            position: "absolute",
            right: "20px",
            top: "100%",
            backgroundColor: "#fff",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
            padding: "10px 0",
            zIndex: 1000,
          }}
        >
          <div
            className="Texthover"
            onClick={() => {
              navigate("/");
              toggleMenu();
            }}
          >
            Home
          </div>
          <div
            className="Texthover"
            onClick={() => {
              navigate("/Company");
              toggleMenu();
            }}
          >
            Company
          </div>
          <div
            className="Texthover"
            onClick={() => {
              navigate("/services");
              toggleMenu();
            }}
          >
            Services
          </div>
          <div
            className="Texthover"
            onClick={() => {
              navigate("/Career");
              toggleMenu();
            }}
          >
            Career
          </div>
          <div
            className="Texthover"
            onClick={() => {
              navigate("/Contact");
              toggleMenu();
            }}
          >
            Contact us
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="hover-button"
              onClick={() => {
                navigate("/Login");
                toggleMenu();
              }}
            >
              Login
            </button>
            <button className="hover-button" onClick={toggleMenu}>
              Download Apk
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Menubar;
