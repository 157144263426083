import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"; // Import the AccountBalanceWalletIcon
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Logo from "../admindashboard/adminassets/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import QrCodeIcon from "@mui/icons-material/QrCode";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonIcon from "@mui/icons-material/Person";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Adminmember from "../adminpages/Adminmember";
import Adminhome from "../adminpages/Adminhome";
import Adminwebsite from "../adminpages/Adminwebsite";
import Adminwallet from "../adminpages/Adminwallet";
import Ewallet from "../adminsidemenupages/Ewallet";
import Creditfund from "../adminsidemenupages/Creditfund";
import Debitfund from "../adminsidemenupages/Debitfund";
import Memberlist from "../adminsidemenupages/Memberlist";
import Newregistration from "../adminsidemenupages/Newregistration";
import Reportscreen from "../adminsidemenupages/Reportscreen";
import UserQrupload from "../adminsidemenupages/UserQrupload";
import Profilephotouserupload from "../adminsidemenupages/Profilephotouserupload";
import Payoutsurcharge from "../adminsidemenupages/Payoutsurcharge";
import Surpluscollection from "../adminsidemenupages/Surpluscollection";
import SettingsIcon from '@mui/icons-material/Settings';
import ReportIcon from '@mui/icons-material/Report';

import Newupireport from "../adminsidemenupages/Newupireport";
import Virtualaccountreport from "../adminsidemenupages/Virtualaccountreport";
import Subadminregister from "../../subadmin/subadminregisterform/Subadminregister";
import Subadminmembers from "../../subadmin/subadminsidemenupages/Subadminmembers";
import Registerusers from "../adminsidemenupages/Registerusers";
import Payout from "../adminsidemenupages/Payout";
import Payoutreport from "../adminsidemenupages/Payoutreport";
import Qrcoderesetadmin from "../adminsidemenupages/Qrcoderesetadmin";
import Qrnewotpvalidate from "../adminsidemenupages/Qrnewotpvalidate";
import Useractivitylogs from "../adminsidemenupages/Useractivitylogs";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// style of Logo  background   contain er

const styles = {
  circle: {
    width: "70px", // Set the width of the circle
    height: "70px", // Set the height of the circle
    backgroundColor: "#007BB5", // Dark sky color
    borderRadius: "28%", // Make the div circular
    display: "flex", // Center content horizontally
    justifyContent: "center",
    alignItems: "center", // Center content vertically
    color: "white", // Set the text color inside the circle (if needed)
    fontSize: "18px", // Adjust the text size (if needed)
  },
};
function AdminDashboard() {
  const [expanded, setExpanded] = useState(false);
  const [selectedtab, setSelectedtab] = useState(0);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  // const [openwallet, setOpenwallet] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownqrreset, setDropdownQrreset] = useState(false);
  const [dropdownuseractivity, setDropdownUseractivity] = useState(false);
  const [dropdownOpenpayout, setDropdownOpenpayout] = useState(false);
  const [dropdownOpenwallet, setDropdownOpenwallet] = useState(false);
  const [dropdownsetting, setDropdownsetting] = useState(false);
  const [dropdownsubadmin, setDropdownsubadmin] = useState(false);
  const [dropdownreport, setDropdownreport] = useState(false);


  const navigate = useNavigate();
  const openicon = Boolean(anchorEl);

  // call baalnce api

  useEffect(() => {
    // Function to disable right-click context menu
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    // Function to disable specific keyboard shortcuts
    // const disableShortcuts = (e) => {
    //   if (
    //     e.key === "F12" || // F12 - Developer tools
    //     (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I - Developer tools
    //     (e.ctrlKey && e.shiftKey && e.key === "J") || // Ctrl+Shift+J - Console
    //     (e.ctrlKey && e.key === "U") // Ctrl+U - View Source
    //   ) {
    //     e.preventDefault();
    //   }
    // };

    // Add event listeners
    // document.addEventListener("contextmenu", disableRightClick); // Disable right-click
    // document.addEventListener("keydown", disableShortcuts); // Disable specific shortcuts

    // Function to handle token expiration
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("adminAuthToken"); // Get JWT token from localStorage
      const expiryTime = localStorage.getItem("authTokenExpiryAdminToken"); // Get expiration time from localStorage

      if (token && expiryTime) {
        const currentTime = Date.now();

        if (currentTime > parseInt(expiryTime)) {
          // Token expired, clear the token and log out the user
          localStorage.removeItem("adminAuthToken");
          localStorage.removeItem("authTokenExpiryAdminToken");
          // Optionally redirect to login page
          window.location.href = "/AdminLogin";
        }
      }
    };

    // Check token expiration every second (1000 ms)
    const expirationCheckInterval = setInterval(checkTokenExpiration, 2000);

    // Check token and prevent back button when logged in
    const checkToken = () => {
      const token = localStorage.getItem("adminAuthToken");

      if (token) {
        // Push a new state so the back button doesn't work
        window.history.pushState(null, "", window.location.href);

        // Disable back navigation while the token is present
        window.onpopstate = function () {
          window.history.pushState(null, "", window.location.href);
        };

        // Store expiration time (1 second from login)
        // const expirationTime = Date.now() + 10000; // 1 second from now

        const expirationTime = Date.now() + 25 * 60 * 1000; // 25 minutes from now


        // const expirationTime = Date.now() + 1 * 60 * 1000; // 1 minute from now

        localStorage.setItem("authTokenExpiryAdminToken", expirationTime.toString());
      } else {
        // Allow back button if the token is cleared
        window.onpopstate = null;
      }
    };

    // Check token on page load
    checkToken();

    // Monitor changes to the JWT token in localStorage
    const tokenChangeHandler = () => {
      checkToken();
    };

    // Listen for changes to the JWT token in localStorage
    window.addEventListener("storage", tokenChangeHandler);

    // Cleanup event listeners on component unmount
    return () => {
      clearInterval(expirationCheckInterval); // Clear interval on unmount
      // document.removeEventListener("contextmenu", disableRightClick);
      // document.removeEventListener("keydown", disableShortcuts);
      window.removeEventListener("storage", tokenChangeHandler);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // menu user icon
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //logout button

  const handleClose = () => {
    // navigate("/");
    setAnchorEl(null);
  };

  // menu button

  // logout button
  const handleLogout = () => {
    localStorage.removeItem("adminAuthToken"); // Remove adminAuthToken
    localStorage.removeItem("adminAuthTokenstored"); // Remove adminAuthTokenstored
    navigate("/AdminLogin"); // Redirect to the homepage
    setAnchorEl(null); // Close the menu
  };


  const ExpandIcon = styled(ExpandMoreIcon)(({ theme, expanded }) => ({
    transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
    transition: "transform 0.3s",
  }));


  const toggleDropdownQrcodereset = () => {
    setDropdownQrreset((prev) => !prev);
  };


  const toggleDropdownUseractivity = () => {
    setDropdownUseractivity((prev) => !prev);
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const toggleDropdownPayout = () => {
    setDropdownOpenpayout((prev) => !prev);
  };

  const toggleDropdownwallet = () => {
    setDropdownOpenwallet((prev) => !prev);
  };

  const toggleDropdownsetting = () => {
    setDropdownsetting((prev) => !prev);
  };

  const toggleDropdownsubadmin = () => {
    setDropdownsubadmin((prev) => !prev);
  }


  const toggleDropdownreport = () => {
    setDropdownreport((prev) => !prev);
  }


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="fixed" open={open} style={{ backgroundColor: "#fff", }}>
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              // marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon style={{ color: "black" }} />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "9px",
              textAlign: "center",
              marginLeft: "auto",
            }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
              </IconButton>
            </Tooltip>
          </Box>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openicon}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem onClick={handleClose}>
              <Avatar /> Profile
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Avatar /> My account
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Add another account
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>


      <Drawer variant="permanent" open={open}  >
        <DrawerHeader style={{ backgroundColor: "#fff" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70px",
            }}
          >
            <img src={Logo} style={{ width: "70%" }} alt="company logo" />
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        {open && (
          <>
            <List style={{ backgroundColor: "#3f0097" }}>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    // width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 5,
                  }}
                >
                  <div style={styles.circle}>
                    <img
                      alt="logo"
                      src={Logo}
                      style={{
                        width: "57px",
                        height: "57px",
                        borderRadius: "70%",
                        backgroundColor: "#fff",
                        //   objectFit: "cover",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "4px",
                      }}
                    >
                      <span style={{ fontSize: "12px", color: "#fff" }}>
                        ZEVOSOFT SERVICES
                      </span>
                      <span style={{ fontSize: "12px", color: "#fff" }}>
                        PRIVATE LIMITED
                      </span>
                    </div>
                  </div>
                </div>
              </ListItem>
            </List>
            <Divider />
          </>
        )}

        <List style={{ backgroundColor: "#3f0097", height: "100vh" }}>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              onClick={() => setSelectedtab(0)}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <HomeIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8", }}
                primary={"Home"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          {/* Qr code tab */}

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownOpen}
              onClick={() => {
                toggleDropdownQrcodereset();
                // setSelectedtab(1);
                // toggleDropdownQrcodereset();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <QrCodeIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Qr Code generate"}
                sx={{ opacity: open ? 1 : 0 }}

              />
              {/* Add this line to change the dropdown icon color to white */}

            </ListItemButton>
            {open && dropdownqrreset && (
              <ul
                className="dropdown-menu show"
                style={{ display: "block", width: "100%", color: "red" }}
              >
                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(21);
                    //   toggleDropdownQrcodereset();
                    // }}

                    onClick={() => {
                      if (selectedtab === 21) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(21);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(21); // Set the tab first
                      }
                      toggleDropdownQrcodereset(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Qr-code reset
                  </a>
                </li>

                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(22);
                    //   toggleDropdownQrcodereset();
                    // }}

                    onClick={() => {
                      if (selectedtab === 22) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(22);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(22); // Set the tab first
                      }
                      toggleDropdownQrcodereset(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Qr otp validate
                  </a>
                </li>

              </ul>
            )}
          </ListItem>


          {/* Api logs  */}


          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownOpen}
              onClick={() => {
                toggleDropdownUseractivity()

              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AssignmentIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"User Activity"}
                sx={{ opacity: open ? 1 : 0 }}

              />
              {/* Add this line to change the dropdown icon color to white */}

            </ListItemButton>
            {open && dropdownuseractivity && (
              <ul
                className="dropdown-menu show"
                style={{ display: "block", width: "100%", color: "red" }}
              >
                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(23);
                    //   toggleDropdownUseractivity();
                    // }}

                    
                    onClick={() => {
                      if (selectedtab === 23) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(23);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(23); // Set the tab first
                      }
                      toggleDropdownUseractivity(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    User Activity Logs
                  </a>
                </li>

              </ul>
            )}
          </ListItem>


          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownOpen}
              onClick={() => {
                // setSelectedtab(1);
                toggleDropdown();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PersonIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Members"}
                sx={{ opacity: open ? 1 : 0 }}

              />
              {/* Add this line to change the dropdown icon color to white */}

            </ListItemButton>
            {open && dropdownOpen && (
              <ul
                className="dropdown-menu show"
                style={{ display: "block", width: "100%", color: "red" }}
              >
                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(7);
                    //   toggleDropdown();
                    // }}

                    onClick={() => {
                      if (selectedtab === 7) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(7);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(7); // Set the tab first
                      }
                      toggleDropdown(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Members List
                  </a>
                </li>

                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(18);
                    //   toggleDropdown();
                    // }}

                    onClick={() => {
                      if (selectedtab === 18) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(18);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(18); // Set the tab first
                      }
                      toggleDropdown(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Register Users
                  </a>
                </li>

                <li>
                  <a
                    onClick={() => {
                      // setSelectedtab(1);
                      toggleDropdown();
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    AEPS KYC
                  </a>
                </li>

                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(8);
                    //   toggleDropdown();
                    // }}

                    onClick={() => {
                      if (selectedtab === 8) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(8);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(8); // Set the tab first
                      }
                      toggleDropdown(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    New Registration
                  </a>
                </li>

                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(10);
                    //   toggleDropdown();
                    // }}

                    onClick={() => {
                      if (selectedtab === 10) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(10);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(10); // Set the tab first
                      }
                      toggleDropdown(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    UserQrupload
                  </a>
                </li>

                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(11);
                    //   toggleDropdown();
                    // }}

                    onClick={() => {
                      if (selectedtab === 11) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(11);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(11); // Set the tab first
                      }
                      toggleDropdown(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Userprofileupload
                  </a>
                </li>

                <li>
                  <a
                    onClick={() => {
                      // setSelectedtab(1);
                      toggleDropdown();
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Front Reg List
                  </a>
                </li>

                <li>
                  <a
                    onClick={() => {
                      // setSelectedtab(1);
                      toggleDropdown();
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Upgrade Members
                  </a>
                </li>

                <li>
                  <a
                    onClick={() => {
                      // setSelectedtab(1);
                      toggleDropdown();
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Move Member
                  </a>
                </li>

                <li>
                  <a
                    onClick={() => {
                      // setSelectedtab(1);
                      toggleDropdown();
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    SendFlahMessage
                  </a>
                </li>

                <li>
                  <a
                    onClick={() => {
                      // setSelectedtab(1);
                      toggleDropdown();
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Contact Reg Request
                  </a>
                </li>
              </ul>
            )}
          </ListItem>


          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownOpenpayout}
              onClick={() => {
                // setSelectedtab(1);
                toggleDropdownPayout();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ReportIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Payout"}
                sx={{ opacity: open ? 1 : 0 }}

              />
            </ListItemButton>
            {open && dropdownOpenpayout && (
              <ul
                className="dropdown-menu show"
                style={{ display: "block", width: "100%" }}
              >
                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(19);
                    //   toggleDropdownPayout();
                    // }}

                    onClick={() => {
                      if (selectedtab === 19) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(19);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(19); // Set the tab first
                      }
                      toggleDropdownPayout(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Payout
                  </a>
                </li>
              </ul>
            )}
          </ListItem>


          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              onClick={() => setSelectedtab(2)}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LanguageIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Website"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              onClick={() => {
                // setSelectedtab(1);
                toggleDropdownwallet();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AccountBalanceWalletIcon
                  sx={{ fontSize: 25, color: "#AAADB7" }}
                />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"wallet"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownOpenwallet && (
              <ul
                className="dropdown-menu show"
                style={{ display: "block", width: "100%" }}
              >
                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(4);
                    //   toggleDropdownwallet();
                    // }}

                    
                    onClick={() => {
                      if (selectedtab === 4) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(4);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(4); // Set the tab first
                      }
                      toggleDropdownwallet(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    E Wallet
                  </a>
                </li>
                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(5);
                    //   toggleDropdownwallet();
                    // }}

                    onClick={() => {
                      if (selectedtab === 5) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(5);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(5); // Set the tab first
                      }
                      toggleDropdownwallet(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Credit Fund
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      // setSelectedtab(1);
                      toggleDropdownwallet();
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    AEPS Wallet
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      // setSelectedtab(1);
                      toggleDropdownwallet();
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Fund Request
                  </a>
                </li>
                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(6);
                    //   toggleDropdownwallet();
                    // }}


                    onClick={() => {
                      if (selectedtab === 6) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(6);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(6); // Set the tab first
                      }
                      toggleDropdownwallet(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Debit Fund
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      // setSelectedtab(1);
                      toggleDropdownwallet();
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Credit Report
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      // setSelectedtab(1);
                      toggleDropdownwallet();
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Wallet Transfer Report
                  </a>
                </li>
              </ul>
            )}
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },

            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              onClick={() => {
                // setSelectedtab(1);
                toggleDropdownreport();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ReportIcon
                  sx={{ fontSize: 25, color: "#AAADB7" }}
                />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Report"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownreport && (
              <ul
                className="dropdown-menu show"
                style={{ display: "block", width: "100%" }}
              >
                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(9);
                    //   toggleDropdownreport();
                    // }}

                    onClick={() => {
                      if (selectedtab === 9) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(9);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(9); // Set the tab first
                      }
                      toggleDropdownreport(); // Close the dropdown
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Member Report
                  </a>
                </li>

                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(14);
                    //   toggleDropdownreport();
                    //   // toggleDropdownsetting();
                    // }}

                    onClick={() => {
                      if (selectedtab === 14) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(14);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(14); // Set the tab first
                      }
                      toggleDropdownsetting(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    New Upi Report
                  </a>
                </li>

                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(15);
                    //   toggleDropdownreport();
                    //   // toggleDropdownsetting();
                    // }}

                    onClick={() => {
                      if (selectedtab === 15) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(15);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(15); // Set the tab first
                      }
                      toggleDropdownreport(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Virtual Account Report
                  </a>
                </li>

                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(20);
                    //   toggleDropdownreport();
                    //   // toggleDropdownsetting();
                    // }}

                    onClick={() => {
                      if (selectedtab === 20) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(20);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(20); // Set the tab first
                      }
                      toggleDropdownreport(); // Close the dropdown
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Payout Report
                  </a>
                </li>

              </ul>
            )}
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              onClick={() => {
                // setSelectedtab(1);
                toggleDropdownsetting();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <SettingsIcon
                  sx={{ fontSize: 25, color: "#AAADB7" }}
                />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Setting"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownsetting && (
              <ul
                className="dropdown-menu show"
                style={{ display: "block", width: "100%" }}
              >
                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(12);
                    //   toggleDropdownsetting();
                    // }}

                    onClick={() => {
                      if (selectedtab === 12) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(12);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(12); // Set the tab first
                      }
                      toggleDropdownsetting(); // Close the dropdown
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Surplus payout
                  </a>
                </li>

                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(13);
                    //   toggleDropdownsetting();
                    // }}


                    onClick={() => {
                      if (selectedtab === 13) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(13);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(13); // Set the tab first
                      }
                      toggleDropdownsetting(); // Close the dropdown
                    }}
                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Surplus Collection
                  </a>
                </li>
              </ul>
            )}
          </ListItem>
          {/*   subadmin panel tab   */}
          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              onClick={() => {
                toggleDropdownsubadmin();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PersonIcon
                  sx={{ fontSize: 25, color: "#AAADB7" }}
                />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Subadmin"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownsubadmin && (
              <ul
                className="dropdown-menu show"
                style={{ display: "block", width: "100%" }}
              >
                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(16);
                    //   toggleDropdownsubadmin();
                    // }}

                    onClick={() => {
                      if (selectedtab === 16) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(16);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(16); // Set the tab first
                      }
                      toggleDropdownsubadmin(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Register subadmin
                  </a>
                </li>

                <li>
                  <a
                    // onClick={() => {
                    //   setSelectedtab(17);
                    //   toggleDropdownsubadmin();
                    // }}


                    onClick={() => {
                      if (selectedtab === 17) {
                        // Reset the selected tab to force remount of the Profile page
                        setSelectedtab(0);  // Temporarily set to 0
                        setTimeout(() => {
                          setSelectedtab(17);  // After a small delay, set it back to Profile
                        }, 100);
                      } else {
                        setSelectedtab(17); // Set the tab first
                      }
                      toggleDropdownsubadmin(); // Close the dropdown
                    }}

                    style={{ fontSize: "15px", color: "black" }}
                    className="dropdown-item"
                    href="#"
                  >
                    Subadmin members
                  </a>
                </li>
              </ul>
            )}
          </ListItem>

        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {selectedtab === 0 && <Adminhome />}
        {selectedtab === 1 && <Adminmember />}
        {selectedtab === 2 && <Adminwebsite />}
        {selectedtab === 3 && <Adminwallet />}
        {selectedtab === 4 && <Ewallet />}
        {selectedtab === 5 && <Creditfund />}
        {selectedtab === 6 && <Debitfund />}
        {selectedtab === 7 && <Memberlist />}
        {selectedtab === 8 && <Newregistration />}
        {selectedtab === 9 && <Reportscreen />}
        {selectedtab === 10 && <UserQrupload />}
        {selectedtab === 11 && <Profilephotouserupload />}
        {selectedtab === 12 && <Payoutsurcharge />}
        {selectedtab === 13 && <Surpluscollection />}
        {selectedtab === 14 && <Newupireport />}
        {selectedtab === 15 && <Virtualaccountreport />}
        {selectedtab === 16 && <Subadminregister />}
        {selectedtab === 17 && <Subadminmembers />}
        {selectedtab === 18 && <Registerusers />}
        {selectedtab === 19 && <Payout />}
        {selectedtab === 20 && <Payoutreport />}
        {selectedtab === 21 && <Qrcoderesetadmin />}
        {selectedtab === 22 && <Qrnewotpvalidate />}
        {selectedtab === 23 && <Useractivitylogs />}
        {/* Add more tab content components as needed */}
      </Box>
    </Box>
  );
}

export default AdminDashboard;
