import React, { useEffect, useState } from "react";
import "./Homemiddle.css";
// import Button from "@mui/material/Button";
// import { Grid, Typography } from "@mui/material";
import Mapview from "../assets/map.png";
import { Button } from "react-bootstrap";

const Homemiddlesection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "70px",
        paddingLeft: "30px",
        paddingRight: "30px",
      }}
    >
      <span style={{ textAlign: "center", fontSize: "30px", color: "#642C8B" }}>
        About
      </span>
      <span style={{ textAlign: "center", fontSize: "30px", color: "#642C8B" }}>
        WHAT IS ZEVOPAY ?
      </span>
      <div className="responsive-container">
        <div>
          <img className="responsive-image" src={Mapview} alt="Description" />
        </div>
        <div className="responsive-text">
          <span
            style={{
              color: "#555555",
              fontFamily: "sans-serif",
              fontSize: "20px",
            }}
          >
            zevopay was established in 2022 as a digital payment platform. We
            are working with 150+ distributors and 3000+ retailers to promote
            Digital India. Our distributors and retailers number are increasing
            every day.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <span
            style={{
              marginTop: "30px",
              color: "#555555",
              fontFamily: "sans-serif",
              fontSize: "20px",
            }}
          >
            The main aim of our company is to make payment services easy in
            rural area as well urban area. In rural areas where banking services
            have not reached today, we want to provide financial services to the
            rural people through our retailers. It will be our endeavor to
            create new jobs as retailers and distributors at each village and
            each tehsil level.
          </span>
          <br></br>
          <br></br>
          <br></br>

          <span
            style={{
              marginTop: "30px",
              color: "#555555",
              fontFamily: "sans-serif",
              fontSize: "20px",
            }}
          >
            Zevopay provides payment services like AEPS, BBPS, Mobile recharge,
            PAN Card, Money Transfer, E Tax services, M-POS, ticket booking,
            insurance etc. Any person can get facilities like all types of bills
            and cash withdrawal at any time anywhere.
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        {isVisible && (
          <Button
            onClick={scrollToTop}
            style={{
              width: "170px",
              padding: "15px",
              backgroundColor: "#642c8b",
              borderColor: "#642c8b",
              color:"white",
            }}
            variant="contained"
          >
            LEARN MORE
          </Button>
        )}
      </div>
    </div>
  );
};

export default Homemiddlesection;
