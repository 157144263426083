import React from "react";
import { Grid } from "@mui/material";

function Upipayout() {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
          padding: "20px",
          paddingLeft: "70px",
          paddingRight: "70px",
        }}
      >
        <h2 style={{ color: "gray", fontWeight: "300" }}>New Payout Panel</h2>
      </div>
      <div
        style={{
          marginTop: "60px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          gap: 70,
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
          padding: "20px",
          paddingLeft: "70px",
          paddingRight: "70px",
          paddingTop:"40px"
          // marginTop:"20px",
        }}
      >
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span
              style={{
                fontSize: "20px",
                color: "black",
                fontFamily: "sans-serif",
              }}
            >
              VPA Holder Name{" "}
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                paddingLeft:"10px",
                width: "100%",
                height: "60px",
                borderColor: "lightgray",
              }}
              type="text"
              // value={amount}
              // onChange={(e) => setAmount(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span
              style={{
                paddingLeft:"10px",
                width: "100%",
                height: "60px",
                borderColor: "lightgray",
              }}
            >
              Enter VPA{" "}
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                paddingLeft:"10px",
                width: "100%",
                height: "60px",
                borderColor: "lightgray",
              }}
              type="text"
              // value={amount}
              // onChange={(e) => setAmount(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span
              style={{
                fontSize: "20px",
                color: "black",
                fontFamily: "sans-serif",
              }}
            >
              Amount{" "}
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                paddingLeft:"10px",
                width: "100%",
                height: "60px",
                borderColor: "lightgray",
              }}
              type="text"
              // value={amount}
              // onChange={(e) => setAmount(e.target.value)}
            />
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "70px",
            gap: 30,
          }}
        >
          <button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#0B8405",
              border: "1px solid #0b8405 !important",
              padding: "12px 20px 12px",
              borderRadius: "25px",
              color: "#fff !important",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "transparent",
              // width: "70px",
            }}
            // onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#DE2A2A",
              border: "1px solid #0b8405 !important",
              padding: "12px 20px 12px",
              borderRadius: "25px",
              color: "#fff !important",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "transparent",
              // width: "70px",
            }}
            // onClick={handleSubmit}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default Upipayout;
