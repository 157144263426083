import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

function Newregistration() {
  // Define validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
    mpin: Yup.string()
      .min(4, "MPIN must be at least 4 characters")
      .required("MPIN is required"),
  });

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      phoneNumber: "",
      mpin: "",
    },

    //https://zevopay.online/api/v1/auth/register
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });
        
        if (response.ok) {
          // If registration is successful, show an alert
          alert("User registered successfully!");
          formik.resetForm(); // Reset the form after success
        } else {
          // Handle any errors from the API
          const errorData = await response.json();
          alert(`Registration failed: ${errorData.message}`);
        }
      } catch (error) {
        alert("Something went wrong! Please try again.");
      }
    },
  });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3>Add Member Master</h3>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
          padding: "30px",
          borderRadius: "10px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
        }}
      >
        <h4 style={{ fontWeight: "500" }}>Member Information</h4>
        <span style={{ color: "gray", fontWeight: "500" }}>Personal Info</span>

        {/* Name field */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            marginTop: "20px",
            flexWrap: "wrap",
          }}
        >
          <div style={{ flex: "1 1 45%", minWidth: "200px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
              }}
            >
              Name{" "}
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            />
            {formik.touched.name && formik.errors.name ? (
              <span style={{ color: "red" }}>{formik.errors.name}</span>
            ) : null}
          </div>

          {/* Email field */}
          <div style={{ flex: "1 1 45%", minWidth: "200px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
              }}
            >
              Email{" "}
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
              >
                *
              </span>
            </label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            />
            {formik.touched.email && formik.errors.email ? (
              <span style={{ color: "red" }}>{formik.errors.email}</span>
            ) : null}
          </div>
        </div>

        {/* Password field */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            marginTop: "20px",
            flexWrap: "wrap",
          }}
        >
          <div style={{ flex: "1 1 45%", minWidth: "200px" }}>
            <label
              style={{ display: "block", marginBottom: "8px", fontWeight: "500" }}
            >
              Password{" "}
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
              >
                *
              </span>
            </label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            />
            {formik.touched.password && formik.errors.password ? (
              <span style={{ color: "red" }}>{formik.errors.password}</span>
            ) : null}
          </div>

          {/* Phone Number field */}
          <div style={{ flex: "1 1 45%", minWidth: "200px" }}>
            <label
              style={{ display: "block", marginBottom: "8px", fontWeight: "500" }}
            >
              Phone Number{" "}
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <span style={{ color: "red" }}>{formik.errors.phoneNumber}</span>
            ) : null}
          </div>
        </div>

        {/* MPIN field */}
        <div style={{ marginTop: "20px" }}>
          <label
            style={{ display: "block", marginBottom: "8px", fontWeight: "500" }}
          >
            MPIN{" "}
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
            >
              *
            </span>
          </label>
          <input
            type="text"
            name="mpin"
            placeholder="MPIN"
            value={formik.values.mpin}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "10px",
            }}
          />
          {formik.touched.mpin && formik.errors.mpin ? (
            <span style={{ color: "red" }}>{formik.errors.mpin}</span>
          ) : null}
        </div>

        <button
          type="submit"
          style={{
            width: "100px",
            padding: "5px",
            backgroundColor: "red",
            color: "#fff",
            marginTop: "20px",
            borderRadius: "10px",
            borderColor: "red",
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default Newregistration;
