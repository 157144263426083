// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    gap: 50px;
    margin-top: 30px;
    padding: 10px;
  }
  
  .date-picker-row {
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .date-picker-row {
      flex-direction: column;
      
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Payoutreport.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE;MACE,sBAAsB;IACxB;;IAEA;MACE,sBAAsB;;IAExB;EACF","sourcesContent":[".container {\n    display: flex;\n    gap: 50px;\n    margin-top: 30px;\n    padding: 10px;\n  }\n  \n  .date-picker-row {\n    display: flex;\n    align-items: center;\n  }\n  \n  @media (max-width: 768px) {\n    .container {\n      flex-direction: column;\n    }\n  \n    .date-picker-row {\n      flex-direction: column;\n      \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
