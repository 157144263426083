import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Imageone from "../assets/imagecarouselunique.png";
import Imagetwo from "../assets/imagecaouseltwo.png";
import Imagethree from "../assets/imagecarouselthree.png";
import Logo from "../assets/logo.png";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-toastify/dist/ReactToastify.css";
import "./Carousel.css";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();
function Forgotpassword() {
  
  // Disable right-click and keyboard shortcuts like F12
  useEffect(() => {
    // Disable right-click context menu
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    // Disable specific keyboard shortcuts
    const disableShortcuts = (e) => {
      if (
        e.key === "F12" || // F12 - Developer tools
        (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I - Developer tools
        (e.ctrlKey && e.shiftKey && e.key === "J") || // Ctrl+Shift+J - Console
        (e.ctrlKey && e.key === "U") // Ctrl+U - View Source
      ) {
        e.preventDefault();
      }
    };

    // Add event listeners
    document.addEventListener("contextmenu", disableRightClick); // Disable right-click
    document.addEventListener("keydown", disableShortcuts); // Disable specific shortcuts

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableShortcuts);
    };
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <ToastContainer />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={4}
          md={7}
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Swiper
            spaceBetween={10}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={false}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                style={{ width: "60%", height: "auto" }}
                src={Imageone}
                alt="Imageone"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                style={{ width: "60%", height: "auto" }}
                src={Imagetwo}
                alt="Imagetwo"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                style={{ width: "60%", height: "auto" }}
                src={Imagethree}
                alt="Imagethree"
              />
            </SwiperSlide>
          </Swiper>
        </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#f0f2f5",
            }}
          >
            <img src={Logo} style={{ width: "11rem" }} alt="logo" />
            <Box
              style={{
                backgroundColor: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                padding: "4rem",
                maxWidth: "400px",
                width: "100%",
              }}
              sx={{
                my: 4,
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>

              <Formik
              initialValues={{ email: "" }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Invalid email address")
                  .required("Email ID is required"),
              })}
              onSubmit={(values) => {
                // Handle the form submission here
                console.log("Form submitted with values:", values);
              }}
            >
              {({ errors, touched }) => (
                <Form noValidate sx={{ mt: 1 }}>
                  <Field
                    as={TextField}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Reset Your Email ID"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "lightskyblue",
                        },
                        "&:hover fieldset": {
                          borderColor: "deepskyblue",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "deepskyblue",
                        },
                      },
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{padding:"10px"}}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Recover your password
                  </Button>
                </Form>
              )}
            </Formik>
        
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Grid>    
      </Grid>
    </ThemeProvider>
  );
}

export default Forgotpassword;
